<form class="w-100" nz-form [formGroup]="formGroup" (ngSubmit)="addCard()">
    <div class="form-filter form-filter--right">
        <div class="row form-filter__container p-3">
            <div class="col-md-12">
                <h3 class="item-row__text">{{ 'CLIENT' | translate }}</h3>
            </div>
        </div>
        <div class="row px-3 pb-3">
            <!--Customer-->
            <div class="col-md-6">
                <label for="firstName" class="repertory-filter__label d-block">{{ 'FORM.PRENOM' | translate }}*</label>
                <input nz-input id="firstName" formControlName="firstName" type="text" />
                <span class="has-error" *ngIf="displayErrors && formGroup.get('firstName').errors">
                    {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                </span>
            </div>
            <div class="col-md-6">
                <label for="lastName" class="repertory-filter__label d-block">{{ 'FORM.NOM' | translate }}*</label>
                <input nz-input id="lastName" formControlName="lastName" type="text" />
                <span class="has-error" *ngIf="displayErrors && formGroup.get('lastName').errors">
                    {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                </span>
            </div>
        </div>
        <div class="row px-3 pb-3">
            <div class="col-md-4">
                <label for="company" class="repertory-filter__label d-block">{{ 'FORM.COMPAGNIE' | translate }}</label>
                <input nz-input id="company" formControlName="company" type="text" />
            </div>
            <div class="col-md-4">
                <label for="email" class="repertory-filter__label d-block">{{ 'FORM.COURRIEL' | translate }}*</label>
                <input nz-input id="email" formControlName="email" type="email" />
                <span class="has-error" *ngIf="displayErrors && formGroup.get('email').errors">
                    {{ displayEmailErrors() | translate }}
                </span>
            </div>
            <div class="col-md-4">
                <label for="mobilePhone" class="repertory-filter__label d-block">{{ 'FORM.TELEPHONE' | translate }}*</label>
                <input nz-input id="mobilePhone" formControlName="mobilePhone" type="text" />
                <span class="has-error" *ngIf="displayErrors && formGroup.get('mobilePhone').errors">
                    {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                </span>
            </div>
        </div>
        <!--Address-->
        <nz-divider></nz-divider>
        <div class="row px-3 pb-3">
            <div class="col-md-12">
                <h3 class="item-row__text">{{ 'FORM.ADRESSE' | translate }}</h3>
            </div>
        </div>
        <div class="row px-3 pb-3">
            <div class="col-md-4">
                <label for="streetAddress1" class="repertory-filter__label d-block">{{ 'FORM.ADRESSE' | translate }} 1*</label>
                <input nz-input id="streetAddress1" formControlName="streetAddress1" type="text" />
                <span class="has-error" *ngIf="displayErrors && formGroup.get('streetAddress1').errors">
                    {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                </span>
            </div>
            <div class="col-md-4">
                <label for="streetAddress2" class="repertory-filter__label d-block">{{ 'FORM.ADRESSE' | translate }} 2</label>
                <input nz-input id="streetAddress2" formControlName="streetAddress2" type="text" />
            </div>

            <div class="col-md-4">
                <label for="city" class="repertory-filter__label d-block">{{ 'FORM.VILLE' | translate }}*</label>
                <input nz-input id="city" formControlName="city" type="text" />
                <span class="has-error" *ngIf="displayErrors && formGroup.get('city').errors">
                    {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                </span>
            </div>
        </div>
        <div class="row px-3 pb-3">
            <div class="col-md-4">
                <label for="postalCode" class="repertory-filter__label d-block">{{ 'FORM.CODE-POSTAL' | translate }}*</label>
                <input nz-input id="postalCode" formControlName="postalCode" type="text" />
                <span class="has-error" *ngIf="displayErrors && formGroup.get('postalCode').errors">
                    {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                </span>
            </div>
            <div class="col-md-4">
                <label for="province">{{ 'FORM.PROVINCE' | translate }}*</label>
                <input nz-input id="province" formControlName="province" type="text" />
                <span class="has-error" *ngIf="displayErrors && formGroup.get('province').errors">
                    {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                </span>
            </div>
            <div class="col-md-4">
                <label for="country">{{ 'FORM.PAYS' | translate }}*</label>
                <select formControlName="country" class="select-country">
                    <option *ngFor="let country of countries" [ngValue]="country.alpha2">
                        {{ country.name }}
                    </option>
                </select>
                <span class="has-error" *ngIf="displayErrors && formGroup.get('country').errors">
                    {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                </span>
            </div>
        </div>
        <nz-divider></nz-divider>
        <!--Card-->
        <div class="row px-3 pb-3">
            <div class="col-md-12">
                <h3 class="item-row__text">{{ 'CARTE' | translate }}</h3>
            </div>
        </div>
        <div class="row px-3 pb-5">
            <div class="col-md-4">
                <label for="number" class="repertory-filter__label d-block">{{ 'FORM.CARTE-CREDIT-NUMBER' | translate }}*</label>
                <input nz-input id="number" formControlName="number" type="text" />
                <span class="has-error" *ngIf="formGroup.get('number').touched && formGroup.get('number').hasError('format')">
                    {{ 'ERRORS.CARD-FORMAT' | translate }}
                </span>
                <span class="has-error" *ngIf="displayErrors && formGroup.get('number').errors">
                    {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                </span>
            </div>
            <div class="col-md-4">
                <div class="row">
                    <div class="col-md-6">
                        <!--TODO: check for NG ant component mo/year -->
                        <label for="expMonth" class="repertory-filter__label d-block">{{ 'FORM.CARTE-CREDIT-EXP-MONTH' | translate }}*</label>
                        <input nz-input id="expMonth" formControlName="expMonth" placeholder="MM" (change)="checkCardValidity()" type="text" />
                    </div>
                    <div class="col-md-6">
                        <label for="expYear" class="repertory-filter__label d-block">{{ 'FORM.CARTE-CREDIT-EXP-YEAR' | translate }}*</label>
                        <input nz-input id="expYear" formControlName="expYear" placeholder="YYYY" (change)="checkCardValidity()" type="text" />
                    </div>
                </div>

                <div class="row">
                    <ng-container *ngIf="formGroup.get('expMonth').hasError('monthInvalid') || formGroup.get('expYear').hasError('yearInvalid'); else correctDateButInvalid">
                        <div class="col-6">
                            <span class="has-error" *ngIf="formGroup.get('expMonth').touched && formGroup.get('expMonth').hasError('monthInvalid')">
                                {{ 'ERRORS.INVALID-MONTH' | translate }}
                            </span>
                            <span class="has-error" *ngIf="displayErrors && formGroup.get('expMonth').hasError('required')">
                                {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                            </span>
                        </div>
                        <div class="col-6">
                            <span class="has-error" *ngIf="formGroup.get('expYear').touched && formGroup.get('expYear').hasError('yearInvalid')">
                                {{ 'ERRORS.INVALID-YEAR' | translate }}
                            </span>
                            <span class="has-error" *ngIf="displayErrors && formGroup.get('expYear').hasError('required')">
                                {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                            </span>
                        </div>
                    </ng-container>

                    <ng-template #correctDateButInvalid>
                        <div *ngIf="formGroup.invalid && invalidExpDate" class="col-12">
                            <span class="has-error">
                                {{ 'ERRORS.INVALID-DATE' | translate }}
                            </span>
                        </div>
                    </ng-template>
                </div>
            </div>

            <div class="col-md-4">
                <label for="holderName" class="repertory-filter__label d-block">{{ 'FORM.CARTE-CREDIT-DETENTEUR' | translate }}*</label>
                <input nz-input id="holderName" formControlName="holderName" type="text" />
                <span class="has-error" *ngIf="displayErrors && formGroup.get('holderName').errors">
                    {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                </span>
            </div>
        </div>
        <div class="form-filter__footer text-md-right p-3">
            <button type="button" class="btn-rect px-3 d-inline-block mr-md-3" (click)="hideForm()" style="padding-top: 5px">{{ 'ANNULER' | translate }}</button>
            <button class="btn d-inline-block" [disabled]="isSavingCard ? 'disabled' : false" style="padding: 1px 16px 1px 17px">{{ 'ENREGISTER' | translate }}</button>
        </div>
    </div>
</form>
