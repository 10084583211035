import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ListItem } from '../../model/list-item.model';
import { RowTypes } from '../../enums/row-types.enum';

let unique = 0;

interface RowSelectable {
    getTranslatedProperty(currentLang: string, property: string): string;
    toListItem(currentLang: string): ListItem;
}

@Component({
    selector: 'app-row-select',
    template: `
        <nz-form-item>
            <div class="row w-100 mx-0">
                <div class="col-12 mb-1 px-0">
                    <label [attr.for]="id">
                        <ng-content></ng-content>
                    </label>
                </div>
                <div class="col-12 px-0">
                    <app-item-row
                        [itemRowType]="rowType"
                        [item]="selectedItemRow()"
                        [enableRemoving]="!disabled"
                        hideStatus="true"
                        (removeItem)="onRemoved()"
                        *ngIf="selected"
                    ></app-item-row>

                    <nz-form-control>
                        <nz-select
                            [attr.id]="id"
                            name="selected"
                            nzAllowClear
                            nzShowSearch
                            [(ngModel)]="selected"
                            (ngModelChange)="onSelected($event)"
                            *ngIf="!selected"
                            [nzDisabled]="disabled"
                            class="select-selector"
                        >
                            <nz-option
                                *ngFor="let option of options"
                                [nzLabel]="option.getTranslatedProperty(translateService.currentLang, labelProperty)"
                                [nzValue]="option[valueProperty]"
                            ></nz-option>
                        </nz-select>

                        <ng-content select="[after]"></ng-content>
                    </nz-form-control>
                </div>
            </div>
        </nz-form-item>
    `,
    styles: [
        `
            .select-selector {
                width: 100%;

                .ant-select-selector:focus-within {
                    border: 2px solid #1702f8 !important;
                    border-radius: 5px !important;
                    box-shadow: none !important;
                    outline: none !important;
                }

                .ant-select-selection-search .ant-select-selection-search-input {
                    margin-top: 5px !important;
                }
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None
})
export class RowSelectComponent<T, U> implements ControlValueAccessor, OnChanges {
    private onChange: (value: U) => void;
    private onTouched: () => void;
    @Input() rowType: RowTypes;
    @Input() options: Array<T & RowSelectable>;
    @Input() labelProperty: string;
    @Input() valueProperty = 'id';
    @Input() disabled: boolean;
    id = `RowSelectComponent-${unique++}`;
    selected: U;
    constructor(readonly translateService: TranslateService, private readonly ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['disabled']) {
            this.setDisabledState(changes['disabled'].currentValue);
        }
    }

    onSelected(): void {
        this.onChange(this.selected);
        this.onTouched();
    }
    onRemoved(): void {
        this.onChange((this.selected = null));
        this.onTouched();
    }

    selectedItemRow(): ListItem {
        if (!this.options) {
            return;
        }
        const item = this.options.find((option) => option[this.valueProperty] === this.selected);
        if (!item) return;
        const listItem = item.toListItem(this.translateService.currentLang);
        if ('itemUrl' in item) {
            listItem.itemUrl = item['itemUrl'] as string;
        }
        if ('itemUrlExt' in item) {
            listItem.itemUrlExt = item['itemUrlExt'] as boolean;
        }
        return listItem;
    }

    writeValue(propertyValue: U): void {
        this.selected = propertyValue;
    }
    registerOnChange(fn: (value: U) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
