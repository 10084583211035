<ng-container *ngIf="!routeData.tour; else elseSelectedBlock">
    <div class="left"></div>

    <div class="center">
        <div class="actives" *ngIf="routeData.paidTours.length">
            {{ (routeData.paidTours.length === 1 ? 'TOURNEES-ACTIVES-1' : 'TOURNEES-ACTIVES') | translate: { count: routeData.paidTours.length } }}
        </div>
        <app-select
            [showSearch]="true"
            [(ngModel)]="tourId"
            labelProperty="name"
            [options]="groupedTours"
            [placeholder]="'TRAVAILLER-SUR-TOURNEE' | translate"
            (ngModelChange)="ngModelChange()"
            [withClear]="'false'"
            *ngIf="routeData.paidTours.length"
        ></app-select>

        <div class="actives" *ngIf="!routeData.paidTours.length">{{ 'AUCUNE-TOURNEE-ACTIVE' | translate }}</div>
    </div>

    <div class="right" *ngIf="routeData.archivedPaidTours.length">
        <a class="app-button link" [routerLink]="'/tour/archived' | localize">
            {{ (routeData.archivedPaidTours.length === 1 ? 'TOURNEES-ARCHIVEES-COUNT-1' : 'TOURNEES-ARCHIVEES-COUNT') | translate: { count: routeData.archivedPaidTours.length } }}
        </a>
    </div>
</ng-container>

<ng-template #elseSelectedBlock>
    <div class="left">
        <button appButton class="link" (click)="clearSelection()">
            <!--<i class="icon-chevron-down" aria-hidden="true"></i>-->
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" style="transform: rotate(90deg); margin-bottom: 8px">
                <path fill-rule="nonzero" d="M9.95 11.485l4.242-4.242a1 1 0 1 1 1.415 1.414l-4.95 4.95a1 1 0 0 1-1.414 0l-4.95-4.95a1 1 0 0 1 1.414-1.414l4.243 4.242z" />
            </svg>
            {{ 'RETOUR-TOUTES-DATES' | translate }}
        </button>
    </div>

    <div class="center">
        <div>
            <div class="name" [innerHTML]="'TOURNEE-EN-COURS' | translate: { name: routeData.tour.getTranslatedProperty(this.translateService.currentLang, 'name') }"></div>
            <div class="subtext">
                {{ routeData.tour.organization.getTranslatedProperty(this.translateService.currentLang, 'name') }} - {{ routeData.tour.startDate | date: 'fullDate' }} au
                {{ routeData.tour.endDate | date: 'fullDate' }}
            </div>
        </div>
    </div>

    <div class="right">
        <button appButton [routerLink]="['/tour', routeData.tour.id, 'edit', 'settings'] | localize" *ngIf="routeData.tourAdmin">
            <i class="icon-pencil" aria-hidden="true"></i>
            {{ 'MODIFIER-TOURNEE' | translate }}
        </button>
    </div>
</ng-template>
