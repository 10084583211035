<div class="item-row">
    <!-- image -->
    <div class="item-row__col item-row__col--avatar item-row__avatar" *ngIf="shouldDisplayImage">
        <span class="item-row__avatar-container">
            <ng-container *ngIf="uploadUrl && displayImage; else noFilePreview">
                <img class="item-row__avatar-src w-100" src="{{ uploadUrl }}" alt="" />
            </ng-container>
            <ng-template #noFilePreview>
                <img class="item-row__avatar-src w-100" src="/assets/images/notfound-square.png" alt="" />
            </ng-template>
        </span>
    </div>
    <!--  vitrine ONLY: select file types -->
    <div class="item-row__col__styleselect pr-4">
        <nz-select
            *ngIf="isVitrine"
            nzPlaceHolder="{{ 'FORM.SELECTIONNER-UN-TYPE-DE-FICHIER' | translate }}"
            type="number"
            (ngModelChange)="selectFileTypeHandler($event)"
            [ngModel]="selectedFormSelect"
            [disabled]="!!uploadName || fileUploaded"
            class="w-100"
        >
            <ng-container *ngTemplateOutlet="selectedFormSelect ? selectedOptionTpl : defaultOptionTpl"></ng-container>
            <ng-template #selectedOptionTpl>
                <nz-option
                    [nzLabel]="'VITRINE_ADDITIONAL_MATERIAL_TYPE.' + getAdditionalMaterialTypeBaseById(selectedFormSelect) | translate"
                    [nzValue]="selectedFormSelect"
                ></nz-option>
            </ng-template>
            <ng-template #defaultOptionTpl>
                <ng-container *ngFor="let type of remainingItems$ | async">
                    <nz-option [nzLabel]="'VITRINE_ADDITIONAL_MATERIAL_TYPE.' + getAdditionalMaterialTypeBaseById(type.id) | translate" [nzValue]="type.id"></nz-option>
                </ng-container>
            </ng-template>
        </nz-select>
        <p class="item-row__text item-row__text--title" *ngIf="!showSelectFileLabel">{{ title || item.desc }}</p>
        <p class="item-row__text item-row__text--subtitle" *ngIf="!isVitrine">{{ uploadUrl ? uploadName : ('AUCUN-FICHIER' | translate) }}</p>
    </div>

    <!-- Credit Photo -->
    <div class="item-row__col" *ngIf="uploadUrl && showCredit">
        <input type="text" placeholder="{{ 'CREDIT-PHOTO' | translate }}" nz-input required (ngModelChange)="creditChange($event)" [(ngModel)]="creditPhoto" />
        <span class="has-error" *ngIf="!creditPhoto">
            {{ 'CREDIT-PHOTO-REQUIS' | translate }}
        </span>
    </div>
    <!-- UPLOAD: selectedFileType -->
    <div class="w-100 d-flex align-items-center justify-content-between">
        <!-- IF HAS uploadUrl -->
        <ng-container *ngIf="uploadUrl; else noFile">
            <ng-container *ngTemplateOutlet="isVitrine ? uploadedFileNameTpl : null; context: { $implicit: uploadName }"></ng-container>
            <ng-container *ngTemplateOutlet="!isDisabled ? deleteButtonTpl : null"></ng-container>
        </ng-container>

        <!-- ELSE: DISPLAY SELECT + BUTTON -->
        <ng-template #noFile>
            <div class="file-upload d-flex align-items-center justify-content-between w-100">
                <nz-upload
                    [nzDisabled]="isVitrine && !selectedFileType"
                    class="w-100"
                    nzAction="{{ item.action }}"
                    [nzShowUploadList]="false"
                    [nzBeforeUpload]="beforeUpload"
                    (nzChange)="handleChange($event)"
                    nzName="{{ item.param }}"
                >
                    <div class="d-flex justify-content-between align-items-center">
                        <ng-container *ngTemplateOutlet="selectedFileType ? hasFilesTpl : uploadButtonTpl"></ng-container>
                        <ng-container *ngTemplateOutlet="!isDisabled && !!uploadName ? rawDeleteButtonTpl : null"></ng-container>
                    </div>
                </nz-upload>
            </div>
            <ng-template #hasFilesTpl>
                <ng-container *ngTemplateOutlet="!uploadName ? uploadButtonTpl : null"></ng-container>
            </ng-template>
        </ng-template>
    </div>
</div>
<!-- UPLOAD BUTTON TEMPLATE -->
<ng-template #uploadButtonTpl>
    <button appButton class="btn btn-input-upload" [ngClass]="{ loading: loading }">
        <span nz-icon nzType="file" nzTheme="outline"></span>
        <span>
            {{ 'FORM.DEPOSER-FICHIER' | translate }}
        </span>
    </button>
</ng-template>

<!-- DELETE BUTTON TEMPLATE -->
<ng-template #deleteButtonTpl>
    <div class="row file-upload__item align-items-center flex-nowrap" [ngClass]="{ 'mr-1 ml-auto': !isVitrine }">
        <ng-container *ngTemplateOutlet="rawDeleteButtonTpl"></ng-container>
    </div>
</ng-template>
<ng-template #rawDeleteButtonTpl>
    <button class="btn icon-only" appButton (click)="removeUpload()">
        <i class="icon-trash" aria-hidden="true"></i>
    </button>
</ng-template>

<ng-template #uploadedFileNameTpl let-uploadName>
    <ng-container *ngIf="uploadName; else noFileNameTpl">
        <span class="d-flex align-items-center">
            <i nz-icon aria-hidden="true" [nzType]="'check-circle'" style="color: #00d1ae"></i>
            <span class="pl-2 pb-1">{{ uploadName }}</span>
        </span>
    </ng-container>
</ng-template>

<ng-template #noFileNameTpl>
    {{ 'AUCUN-FICHIER' | translate }}
</ng-template>
