import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';

@Component({
    selector: 'app-nz-form-item',
    template: `
        <nz-form-item>
            <div class="row w-100 mx-0">
                <div class="col-12 mb-2 px-0">
                    <ng-content select=".input-custom-label"></ng-content>
                </div>
                <div class="col-12 px-0">
                    <nz-form-control [ngClass]="formControlClass">
                        <nz-form-label *ngIf="label" nzNoColon="true">{{ label }}</nz-form-label>
                        <div *ngIf="isFlex" class="d-flex">
                            <input nz-input [disabled]="isDisabled" [type]="inputType" [formControl]="control" [ngClass]="inputClass" />
                            <ng-content select=".input-custom-suffix"></ng-content>
                        </div>

                        <div *ngIf="!isFlex">
                            <input nz-input [type]="inputType" [formControl]="control" [ngClass]="inputClass" />
                            <ng-content select=".input-custom-suffix"></ng-content>
                        </div>
                    </nz-form-control>
                    <ng-content select=".input-custom-error-msg"></ng-content>
                </div>
            </div>
        </nz-form-item>
    `,
    styleUrls: ['./nz-form-item.component.scss']
})
export class NzFormItemComponent {
    @Input() label: string;
    @Input() inputType: string = 'text'; // Default input type is text
    @Input() control: UntypedFormControl | string; // Accepts either FormControl or string
    @Input() id: string = ''; // Accepts either FormControl or string
    @Input() inputClass: string = ''; // additional class for input

    @Input() formControlClass: any = {}; // additional class for input
    @Input() inputId: string = ''; // additional id for input
    @Input() isFlex: boolean = false; // additional id for input
    @Input() isDisabled: boolean = false; // additional id for input
    constructor(@Optional() @Self() public ngControl: NgControl) {}

    getControl(): UntypedFormControl {
        if (this.control instanceof UntypedFormControl) {
            return this.control;
        } else if (typeof this.control === 'string' && this.ngControl && this.ngControl.control) {
            return this.ngControl.control.get(this.control) as UntypedFormControl;
        } else {
            throw new Error('Invalid control provided to CustomInputComponent');
        }
    }
}
