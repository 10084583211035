<div nz-row class="tarif-row">
	<div class="number">
		<input nz-input type="number" [(ngModel)]="tarif.price">
	</div>
	<div class="text">
		$ {{'POUR' | translate}}
	</div>
	<div class="selector">
		<nz-select nzPlaceHolder="{{'FORM.SELECTIONNER' | translate}}" [nzDropdownMatchSelectWidth]="false"
			[(ngModel)]="tarif.participantTypeId">
			<nz-option *ngFor="let tarifType of tarifTypes$ | async" [nzValue]="tarifType.id"
				[nzLabel]="tarifType.label"></nz-option>
		</nz-select>
	</div>
	<div class="selector">
		<nz-select nzPlaceHolder="{{'FORM.SELECTIONNER' | translate}}" [nzDropdownMatchSelectWidth]="false"
			[(ngModel)]="tarif.minInscriptions">
			<nz-option *ngFor="let volumeType of volumeTypes" [nzValue]="volumeType.id"
				nzLabel="{{ volumeType.label | translate}}"></nz-option>
		</nz-select>
	</div>
	<div class="delete-button">
		<button type="button" class="btn-square" (click)="doDelete(tarif.id)">
			<i class="icon-trash"></i>
		</button>
	</div>
</div>
<nz-divider></nz-divider>
