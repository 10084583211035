import { Component, Input, HostBinding, ViewChild, ContentChild, TemplateRef, AfterContentInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

let unique = 0;

@Component({
    selector: 'sp-select',
    templateUrl: './select.component.html'
})
export class SelectComponent<T, U> implements ControlValueAccessor, AfterContentInit {
    private onChange: (value: U) => void;
    private onTouched: () => void;
    @ContentChild(TemplateRef, { static: true }) optionTemplate!: TemplateRef<any>;
    @Input() control: UntypedFormControl;
    @Input() options: Array<T> | Record<string, Array<T>>;
    @Input() disabled: boolean;
    @Input() validateStatus = '';
    @Input() labelProperty = 'label';
    @Input() valueProperty = 'id';
    @Input() placeholder: string;
    @Input() withClear = true;
    @Input() withSearch = false;
    @Input() simpleLabel = false;
    @Input() compoundLabel = false; //if need to access object inside the main object
    @Input() labelAccessor: string;
    @Input() labelPrefix: string = '';
    @Input() customLabel: (option: T) => string;
    @HostBinding('class.warning') get classWarning(): boolean {
        return this.validateStatus === 'warning';
    }
    @HostBinding('class.error') get classError(): boolean {
        return this.validateStatus === 'error';
    }
    @HostBinding('class.disabled') get classDisabled(): boolean {
        return this.disabled;
    }
    id = `SelectComponent-${unique++}`;
    selected: U;
    @ViewChild('prefix', { static: true }) prefix: unknown;
    constructor(readonly translateService: TranslateService, private readonly ngControl: NgControl) {
        this.ngControl.valueAccessor = this;
    }
    ngAfterContentInit() {
        return;
    }
    ngModelChange(): void {
        this.onChange(this.selected);
        this.onTouched();
    }
    writeValue(propertyValue: U): void {
        this.selected = propertyValue;
    }
    registerOnChange(fn: (value: U) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    hasGroups(): boolean {
        return !(this.options instanceof Array);
    }
}
