import { Component, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { SiteConcepts, GetSiteConceptsSpec } from '../../../enums/site-concepts.enum';
import { BreadcrumbService } from '../../../services/breadcrumb.services';
import { BreadcrumbItem } from '../../../model/breadcrumb.model';
import { filter, map } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { NotificationsService } from 'src/app/concepts/notifications/services/notifications.service';
/**
 * Ce composant permet de gérer le breadcrumb (fil d'Ariane) de manière semi-dynamique
 * Le fil d'Ariane est par défaut présent sur toutes les pages du site, sauf le dashboard.
 * Le premier niveau (show, bottin, compte, etc..) est géré à l'aide des données enrichies des routes (Cf : ficher .routes.ts)
 *
 * Variables importantes
 * showBreadcrumb - Détermine s'il faut afficher ou pas le fil d'ariane
 * breadcrumbMainConcept - Permet de gérer la section/concept de la page courrante
 * breadcrumbItems - Permet de gérer les niveaux multiples (pages single et plus au besoin)
 *
 * Les fonctions suivantes permettent :
 * routeNavigationListener - Écouter les évenements de navigation et mettre a jour le concept lié à la route
 * addCascadeEventsListener - Écouter les évenements d'ajout de niveaux en provenance des pages Single(addBreadcrumbCascade)
 * changeManConceptListener - Écouter les évenements de changement du concept pour une page en particulier
 *
 */

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    //changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbComponent implements OnInit {
    showBreadcrumb: boolean;
    isSinglePage: boolean;
    mainConcept: SiteConcepts;
    breadcrumbMainConcept: BreadcrumbItem = new BreadcrumbItem();
    breadcrumbItems$: Observable<BreadcrumbItem[]> = this.breadcrumbService.breadcrumbItems$;

    previousRoute: any;

    subs: Array<Subscription> = [];

    constructor(
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        public translate: TranslateService,
        private location: PlatformLocation,
        public notificationsService: NotificationsService,
        public breadcrumbService: BreadcrumbService,
        private router: Router,
        private route: ActivatedRoute,
    ) {}

    ngOnInit(): void {
        // FIRST INIT
        this.breadcrumbInit();

        // Detect if user navigate back
        this.location.onPopState((v) => {
            this.breadcrumbService.setBreadcrumbItems([]);
        });

        // Route nagigate/change subscriber
        this.routeNavigationListener();

        // Listen for Single page Cascade
        this.addCascadeEventsListener();

        // This method is called to change the main concept
        this.changeManConceptListener();

        // This listen to reset trigger then reset this.breadcrumbItems
        this.resetList();
    }

    public resetList(): void {
        this.breadcrumbService.listResetListener().subscribe(() => {
            this.breadcrumbService.setBreadcrumbItems([]);
        });
    }
    private breadcrumbInit(): void {
        // Get the breadcrumb data info in the current route
        const loadingRoute = this.getFirstChild(this.route);
        const haveBreadcrumb = loadingRoute.data.value.breadcrumb;

        if (haveBreadcrumb) {
            // Show or hide dashboard link
            this.showBreadcrumb = !haveBreadcrumb?.includes(SiteConcepts.DASHBOARD);
            this.getBreadcrumbFromRouting(loadingRoute.data.value);
        }
    }

    // Event Listeners
    private changeManConceptListener(): void {
        this.breadcrumbService.conceptChangedListener().subscribe((data) => {
            this.mainConcept = data;
            this.breadcrumbMainConcept = new BreadcrumbItem(GetSiteConceptsSpec(data));
        });
    }

    private addCascadeEventsListener(): void {
        this.breadcrumbService.cascadeListener().subscribe((data) => {
            if (data.stepLevel) {
                this.breadcrumbService.setBreadcrumbItems([]);
            }
            // Update breadcrumb items without changing the main Concept
            this.setSingleCascade(data.breadcrumbItems);
        });
    }

    private routeNavigationListener(): void {
        this.subs[0] = this.router.events
            .pipe(
                // Get the final step of the navigation
                filter((event) => event instanceof NavigationEnd),
                // get the last imbrication of FirstChild
                map(() => this.getFirstChild(this.route.snapshot))
            )
            .subscribe((route: any) => {
                // Determine if we are on a single page or not
                this.isSinglePage = route.data.breadcrumb?.includes(SiteConcepts.SINGLE);
                // Update Notifications count
                this.notificationsService.triggerNotificationCountUpdate();
                // Setup the breadcrum
                if (!this.previousRoute || this.previousRoute.localizeRouter.path != route.data.localizeRouter.path) {
                    // When navigation between tabs on the same page
                    this.previousRoute = route.data;
                    this.getBreadcrumbFromRouting(route.data);
                }
                if (!route.data.breadcrumb?.includes(SiteConcepts.SHOW)) {
                    sessionStorage.removeItem('showAdvFilters');
                }
                if (!route.data.breadcrumb?.includes(SiteConcepts.BOTTIN)) {
                    sessionStorage.removeItem('bottinAdvFilters');
                    sessionStorage.removeItem('bottinLeftFilters');
                }
            });
    }

    // Get the last imbrication of FirstChild
    private getFirstChild(route) {
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }

    private getBreadcrumbFromRouting(route) {
        if (route.breadcrumb) {
            this.setBreadcrumbItems(route.breadcrumb);
        } else {
            this.showBreadcrumb = false;
        }
    }

    public setBreadcrumbItems(breadcrumbNodes: SiteConcepts[]): void {
        // Determine if Dashboard link should be hidden
        this.showBreadcrumb = breadcrumbNodes[0] != SiteConcepts.DASHBOARD;

        if (this.mainConcept != breadcrumbNodes[0]) {
            this.mainConcept = breadcrumbNodes[0];
            this.breadcrumbService.setBreadcrumbItems([]);
            this.breadcrumbMainConcept = new BreadcrumbItem(GetSiteConceptsSpec(breadcrumbNodes[0]));
        } else if (!this.isSinglePage) {
            this.breadcrumbService.setBreadcrumbItems([]);
        }
    }

    public setSingleCascade(singleCascade: BreadcrumbItem[]): void {
        // Loop for setting Single Cascade
        this.breadcrumbService.setBreadcrumbItems(singleCascade);
    }

    ngOnDestroy(): void {
        this.subs.forEach((s) => s.unsubscribe());
    }
}
