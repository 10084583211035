<form *ngIf="formGroup" nz-form [formGroup]="formGroup" (ngSubmit)="doSubmit()" class="mb-4">
    <div nz-row nzGutter="16">
        <div nz-col nzMd="18">
            <nz-form-item>
                <nz-form-control>
                    <label for="name">
                        {{ 'FORM.TITRE' | translate }}*
                        <input nz-input type="text" id="name" formControlName="name" />
                    </label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <label for="organizationId">{{ 'FORM.ORGANISATION-PROPRIETAIRE' | translate }}</label>
                    <nz-select nzShowSearch name="organizationId" nzAllowClear class="w-100" formControlName="organizationId" class="select-input">
                        <nz-option *ngFor="let organization of organizations" [nzLabel]="organization.getTranslatedProperty(lang, 'name')" [nzValue]="organization.id"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <label for="descIntern">
                        {{ 'FORM.DESCRIPTION-INTERNE' | translate }}
                        <input nz-input type="text" id="descInternal" formControlName="descInternal" />
                    </label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <label for="descPublic">
                        {{ 'FORM.DESCRIPTION-PUBLIQUE' | translate }}
                        <input nz-input type="text" id="descPublic" formControlName="descPublic" />
                    </label>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control>
                    <label for="productType">{{ 'FORM.TYPE-DE-PRODUIT' | translate }}</label>
                    <nz-select id="productType" class="w-100" formControlName="productTypeId" class="select-input">
                        <nz-option *ngFor="let type of productTypes" [nzLabel]="'PRODUCT-TYPE.' + type.name | translate" [nzValue]="type.value"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="formGroup.get('productTypeId').value === INSCRIPTION_TYPE || formGroup.get('productTypeId').value === DEPOT_VITRINE_TYPE">
                <nz-form-control>
                    <label for="meeting">{{ 'FORM.RENCONTRE' | translate }}</label>
                    <nz-select id="meeting" nzShowSearch nzAllowClear style="width: 100%" formControlName="meetingId" class="select-input">
                        <nz-option *ngFor="let meeting of meetings" [nzLabel]="meeting.label" [nzValue]="meeting.id"></nz-option>
                    </nz-select>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="formGroup.get('productTypeId').value === INSCRIPTION_TYPE">
                <nz-form-control>
                    <sp-checkbox formControlName="isStandOptional">
                        {{ 'FORM.PERMET-LE-CHOIX-DE-STAND' | translate }}
                    </sp-checkbox>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div nz-row nzGutter="16">
        <div nz-col nzMd="18">
            <nz-form-item *ngIf="formGroup.get('productDateRange')" class="validate-periode">
                <nz-form-control>
                    <label for="validityPeriod" class="d-block mb-2">{{ 'FORM.PERIODE-DE-VALIDITE' | translate }}</label>
                    <nz-radio-group class="input-radioBtn" [(ngModel)]="radioDateValue" [ngModelOptions]="{ standalone: true }">
                        <label for="" nz-radio nzValue="productRadioAllTime" class="input-radioBtn__radio d-block mb-2">{{ 'FORM.EN-TOUT-TEMPS' | translate }}</label>
                        <label for="" nz-radio nzValue="productRadioValidityDate" class="input-radioBtn__radio d-block mb-2">
                            {{ 'FORM.LIMITE-DANS-LE-TEMPS' | translate }}
                        </label>
                    </nz-radio-group>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="formGroup.get('productDateRange')" class="date-range">
                <nz-form-control>
                    <nz-range-picker
                        nzAllowClear="true"
                        [nzShowTime]="{ nzFormat: dateRangeTimeFormat }"
                        [nzFormat]="dateRangeFormat"
                        formControlName="productDateRange"
                        *ngIf="radioDateValue == 'productRadioValidityDate'"
                    ></nz-range-picker>

                    <span class="has-error" *ngIf="displayErrors && formGroup.get('productDateRange').errors">
                        {{ 'CHAMP-REQUIS' | translate }}
                    </span>
                </nz-form-control>
            </nz-form-item>

            <nz-form-item *ngIf="formGroup.get('quantity')">
                <nz-form-control>
                    <label for="validityPeriod" class="d-block mb-2">{{ 'FORM.QUANTITE-DISPONIBLE' | translate }}</label>
                    <nz-radio-group class="input-radioBtn" [(ngModel)]="radioQuantityValue" [ngModelOptions]="{ standalone: true }">
                        <label for="" nz-radio nzValue="productRadioUnlimited" class="input-radioBtn__radio d-block mb-2" formControlName="isQuantityUnlimited">
                            {{ 'FORM.ILLIMITEE' | translate }}
                        </label>
                        <label for="" nz-radio class="input-radioBtn__radio d-block mb-2" nzValue="productRadioQuantity">
                            <span class="mr-2">{{ 'FORM.LIMITEE-A' | translate }}</span>
                        </label>
                    </nz-radio-group>
                    <input nz-input type="text" class="w-50" *ngIf="radioQuantityValue == 'productRadioQuantity'" formControlName="quantity" />
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <ng-container>
        <button *appPermission="1" nz-button [disabled]="!formGroup.valid" [nzLoading]="isLoading" class="btn btn--default">
            {{ 'ENREGISTER-CONTINUER' | translate }}
        </button>
        <p *appPermission="1" class="d-inline-block ml-2">
            {{ 'OU' | translate }}
            <a *ngIf="!isLoading" (click)="doSubmit('simple')">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
            <span *ngIf="isLoading">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</span>
        </p>
    </ng-container>
</form>
