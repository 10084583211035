<div class="form-filter mb-5">
    <form class="w-100" nz-form [formGroup]="vitrineForm">
        <div nz-row nzGutter="24" class="form-filter__container p-3">
            <div nz-col nzMd="12">
                <nz-form-item>
                    <nz-form-control>
                        <label for="vitrineId" class="repertory-filter__label d-block">{{ 'SPECTACLE' | translate }} *</label>
                        <nz-select
                            nzShowSearch
                            nzAllowClear
                            id="vitrineId"
                            [nzDisabled]="!!selectedVitrine"
                            nzPlaceHolder="{{ 'FORM.SELECTIONNER-UN-SPECTACLE' | translate }}"
                            formControlName="vitrineId"
                            required
                        >
                            <nz-option *ngFor="let vitrine of meetingVitrinesList" [nzLabel]="vitrine.show.getTranslatedProperty(lang, 'title')" [nzValue]="vitrine.id"></nz-option>
                        </nz-select>

                        <span
                            class="has-error"
                            *ngIf="vitrineForm.invalid && vitrineForm.get('vitrineId').errors && (vitrineForm.get('vitrineId').dirty || vitrineForm.get('vitrineId').touched)"
                        >
                            {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                        </span>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzMd="6" class="select-type">
                <sp-select
                    formControlName="vitrineTypeId"
                    labelProperty="name"
                    [valueProperty]="'typeId'"
                    [options]="vitrineTypes"
                    [customLabel]="getVitrineTypesLabel.bind(this)"
                    [placeholder]="'Choisir'"
                >
                    <label for="vitrineTypeId" class="repertory-filter__label d-block">{{ 'TYPE-DE-VITRINE' | translate }} *</label>
                </sp-select>
            </div>
            <div nz-col nzMd="6">
                <nz-form-item>
                    <nz-form-control>
                        <label for="duration" class="repertory-filter__label d-block">{{ 'FORM.DUREE' | translate }}</label>
                        <div class="d-flex">
                            <input nz-input id="duration" class="w-50 mr-2 input-border" formControlName="duration" />
                            <span>minutes</span>
                        </div>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div class="form-filter__footer text-md-right p-3" style="display: flex; justify-content: flex-end">
            <button class="btn-rect px-3 d-inline-block mr-md-3" style="padding-top: 7px" (click)="dismiss()">{{ 'ANNULER' | translate }}</button>
            <button class="btn btn--small d-inline-block" nz-button nzSize="large" (click)="saveVitrine()" [disabled]="!vitrineForm.valid" nzType="default">
                {{ (!selectedVitrine ? 'AJOUTER' : 'ENREGISTER') | translate }}
            </button>
        </div>
    </form>
</div>
