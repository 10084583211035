import { Component, Input, OnInit } from '@angular/core';
import { Stand, StoredMeetingParticipantsOrganization } from '../../../model/stand.model';
import { StandService } from '../../../services/stand.service';
import { RideauNotificationService } from '../../../../../shared/services/rideau-notification.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Globals } from '../../../../../_configs/globals';
import { RegexpValidator } from '../../../../../shared/validators/regexp.validator';
import { Organization } from '../../../../organization/model/organization.model';
import { MeetingService } from '../../../../meeting/services/meeting.service';
import { OrganizationTypes } from '../../../../organization/enums/organization-types.enum';
import { map } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from '@app/shared/services/storage.service';
import { Observable, of } from 'rxjs';
import { StorageData } from '@app/concepts/tours/model/storage-data.model';
import { IUpdatedResponse } from '@app/shared/model/response.model';
import { CurrentCtxOrganization } from '@app/concepts/account/services/account.service';


@Component({
    selector: 'app-stand-form',
    templateUrl: './stand-form.component.html',
    styleUrls: [ './stand-form.component.scss' ]
})
export class StandFormComponent implements OnInit {
    @Input() meetingId: number;
    @Input() meetingOwnerOrgId: number;
    @Input() theStand: Stand;

    public formGroup: UntypedFormGroup;
    public displayErrors = false;
    public organizationList$: Observable<Organization[]>;
    public readonly allOrganizationsTypeId: OrganizationTypes = OrganizationTypes.VACANT;
    public readonly diffuserTypeId: OrganizationTypes = OrganizationTypes.IS_DIFFUSEUR;
    public readonly producerTypeId: OrganizationTypes = OrganizationTypes.IS_PRODUCTEUR;
    public readonly currentLang: string = this.translate.currentLang;
    private readonly sessionStorageParticipantsOrganizationKey = 'sp.meeting.participants.organizations';

    constructor(
        private fb: UntypedFormBuilder,
        private globals: Globals,
        private translate: TranslateService,
        private notification: RideauNotificationService,
        private meetingService: MeetingService,
        private standServices: StandService,
        private sessionStorage: SessionStorageService,
        private localStorageService: LocalStorageService,

    ) { }

    ngOnInit(): void {
        this.getOrganizationsList();
        const { orgaId } = this.localStorageService.getItem<CurrentCtxOrganization>('currentCtxOrganizationId');
        this.theStand.contextOrganizationId = Number(orgaId);
        this.formGroup = this.fb.group({
            label: new UntypedFormControl(this.theStand.label, [ Validators.required, RegexpValidator.validate(this.globals.standsLabelRegex) ]),
            // OrganizationTypes: organization-types.enum.ts
            organizationTypeId: new UntypedFormControl(this.theStand.organizationTypeId || OrganizationTypes.VACANT, [ Validators.required ]),
            // organizationId assigned to the stand
            organizationId: new UntypedFormControl(this.theStand.organizationId)
        });
    }

    public createStand() {
        if (!this.formGroup.valid) {
            this.displayErrors = true;
            return false; // Retrun false to prevent the modal close
        }
        this.updateStandObj();
        this.standServices.createStand(this.theStand).subscribe(() => {
            this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
        });
        return true;
    }

    public async updateStand() {
        if (!this.formGroup.valid) {
            // FORM is invalid
            this.displayErrors = true;
            return false; // Return false to prevent the modal close
        }
        this.updateStandObj();
        return await this.standServices
            .updateStand(this.theStand.toStandRequest())
            .toPromise()
            .then(({ isUpdated }: IUpdatedResponse) => {
                this.notification.success(this.translate.instant('FORM.SAUVEGARDE'));
                return { isUpdated };
            })
            .catch((err) => {
                console.warn(err);
                if ('error' in err) {
                    const { error } = err;
                    if ('others' in error && error.others.name === 'Unavailable') {
                        // Display a notification if the choice cannot be made
                        this.notification.error(this.translate.instant('UNAVAILABLE-CHOICE'));
                    } else {
                        this.notification.error(this.translate.instant('ERRORS.UPDATE_FAIL_ERROR'));
                    }
                }
            });
    }

    private getOrganizationsList(): void {
        // get data from sessionStorage
        const organizationList: Organization[] = this.getStoredOrganizationList();
        if (organizationList) {
            this.organizationList$ = of(organizationList.map((x) => new Organization(x)));
            return;
        }

        // if no data in sessionStorage, retrieve it from service
        this.organizationList$ = this.meetingService.getParticipantsOrganization(this.meetingId).pipe(
            map((result: any[]): Organization[] => {
                const output = result
                    .map((org: any) => new Organization(org))
                    .filter(
                        (org, index, self) => index === self.findIndex((t) => t.id === org.id) // Fix for remove doublons
                    );
                this.sessionStorage.setItem<StorageData<StoredMeetingParticipantsOrganization>>(this.sessionStorageParticipantsOrganizationKey, {
                    ts: Date.now(),
                    list: {
                        meetingId: this.meetingId,
                        organizationList: output
                    } as StoredMeetingParticipantsOrganization
                });
                return output;
            })
        );
    }

    private updateStandObj() {
        const { label, organizationTypeId, organizationId } = this.formGroup.value;
        this.theStand.organizationId = organizationId
        this.theStand.label = label.toUpperCase();
        this.theStand.organizationTypeId = <OrganizationTypes>organizationTypeId;
        const { orgaId } = this.localStorageService.getItem<CurrentCtxOrganization>('currentCtxOrganizationId');
        this.theStand.contextOrganizationId = +orgaId;
    }

    private get storedParticipantsOrganizations(): StorageData<StoredMeetingParticipantsOrganization> {
        return this.sessionStorage.getItem(this.sessionStorageParticipantsOrganizationKey);
    }

    private getStoredOrganizationList = (): Organization[] => {
        if (!this.storedParticipantsOrganizations) {
            return null;
        }
        const { list, ts } = this.storedParticipantsOrganizations;
        if (!list || !list.organizationList) {
            return null;
        }
        if (!list.organizationList.length) {
            return null;
        }
        if (Number(list.meetingId) !== Number(this.meetingId)) {
            return null;
        }
        const nowTimestamp = Date.now();
        // Calculate a timestamp that's 1 week (7 days) ahead
        const oneWeekAheadStoredTimestamp = ts + 7 * 24 * 60 * 60 * 1000;
        if (nowTimestamp > oneWeekAheadStoredTimestamp) {
            return null;
        }
        // export list
        return list.organizationList;
    };
}
