<form *ngIf="formGroup" id="activityAccesRestrictions" [formGroup]="formGroup" (ngSubmit)="doSubmit()">
    <div nz-row nzGutter="16" class="capacity-control">
        <div nz-col nzMd="4">
            <app-nz-form-item [label]="'FORM.CAPACITE' | translate" [control]="formGroup.get('capacity')"
                inputType="number"></app-nz-form-item>
        </div>
    </div>
    <label for="orgaTypeAcces">
        {{ 'FORM.ORGANIZATION-TYPE-ACCESS' | translate }}
    </label>

    <sp-radio-group [formControl]="formGroup.get('hasOrgaTypeRestrictions')" [options]="options"
        (selectionChange)="checkDisableOrganizationTypeRestriction($event)"></sp-radio-group>

    <div formGroupName="orgaTypeRestrictions" class="orgaTypeRestrictions">
        <nz-form-item class="mb-3" nz-row>
            <nz-form-control nzSpan="14">
                <label nz-checkbox [disabled]="formGroup.get('orgaTypeRestrictions').get('hasOrgaId').disabled"
                (ngModelChange)="checkDisableOrganizationNetworkRestriction($event)" formControlName="hasOrgaId" class="d-flex">
                {{ 'RESTRICTION-ORGANISATION-SPECIFIQUE' | translate }}
                </label>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row>
            <nz-form-control nzSpan="14">
                <nz-select nzPlaceHolder="{{ 'SELECT-NETWORK' | translate }}" nzAllowClear style="width: 100%"
                    formControlName="orgaId" [nzDisabled]="!formGroup.get('orgaTypeRestrictions.hasOrgaId').value">
                    <nz-option *ngFor="let network of networkList"
                        [nzLabel]="network.getTranslatedProperty(currentLang, 'name')"
                        [nzValue]="network.id"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row>
            <nz-form-control nzSpan="14">
                <label nz-checkbox formControlName="allowProducers" class="d-flex">
                    {{ 'ORGANIZATION-TYPE_1' | translate }}
                </label>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row>
            <nz-form-control nzSpan="14">
                <label nz-checkbox formControlName="allowDiffusers" class="d-flex">
                    {{ 'ORGANIZATION-TYPE_2' | translate }}
                </label>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row>
            <nz-form-control nzSpan="14">
                <label nz-checkbox formControlName="allowVenuesOwners" class="d-flex">
                    {{ 'ORGANIZATION-TYPE_3' | translate }}
                </label>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row>
            <nz-form-control nzSpan="14">
                <label nz-checkbox formControlName="allowGovernmental" class="d-flex">
                    {{ 'ORGANIZATION-TYPE_4' | translate }}
                </label>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row>
            <nz-form-control nzSpan="14">
                <label nz-checkbox formControlName="allowProviders" class="d-flex">
                    {{ 'ORGANIZATION-TYPE_5' | translate }}
                </label>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row>
            <nz-form-control nzSpan="14">
                <label nz-checkbox formControlName="allowMedia" class="d-flex">
                    {{ 'ORGANIZATION-TYPE_6' | translate }}
                </label>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row>
            <nz-form-control nzSpan="14">
                <label nz-checkbox formControlName="allowOthers" class="d-flex">
                   {{ 'ORGANIZATION-TYPE_7' | translate }}
                </label>
            </nz-form-control>
        </nz-form-item>
        <nz-form-item nz-row>
            <nz-form-control nzSpan="14">
                <label nz-checkbox formControlName="allowIndividuals" class="d-flex">
                    {{ 'INDIVIDUS' | translate }}
                </label>
            </nz-form-control>
        </nz-form-item>
    </div>
    <!-- Restriction de l'activité selon les produits de la rencontre -->
    <label>{{ 'FORM.MEETING-PRODUCT-ACCESS' | translate }}</label>
    <nz-form-item>
        <nz-form-control nzSpan="14">
            <nz-radio-group formControlName="hasNoProductsRestrictions"
                (ngModelChange)="checkDisableProductsRestrictions($event)">
                <label nz-radio class="input-radioBtn" [nzValue]="true">
                    {{ 'AVAILABLE-ALL-PRODUCTS' | translate }}
                </label>
                <label nz-radio class="input-radioBtn" [nzValue]="false">
                    {{ 'AVAILABLE-CERTAIN-PRODUCTS' | translate }}
                </label>
            </nz-radio-group>
        </nz-form-control>
    </nz-form-item>
    <div style="margin-left: 2%">
        <div *ngIf="areProductsLoading">
            <i nz-icon nzType="loading" nzTheme="outline" aria-hidden="true"></i>
        </div>
        <span *ngFor="let product of meetingProducts; let i = index">
            <nz-form-item nz-row>
                <nz-form-control nzSpan="14">
                    <label nz-checkbox [nzDisabled]="formGroup.get('hasNoProductsRestrictions').value"
                        [ngModel]="meetingProductsRestrictions.includes(product.id)"
                        (ngModelChange)="addNewProductRestriction($event, product.id)"
                        [ngModelOptions]="{ standalone: true }">
                        {{ product.getTranslatedProperty(currentLang, "name") ?
                        product.getTranslatedProperty(currentLang, "name") :
                        ('NO-NAME-PRODUCT') | translate
                        }}
                    </label>
                </nz-form-control>
            </nz-form-item>
        </span>
    </div>

    <button nz-button [nzLoading]="isLoading" class="btn btn--default mt-3">
        {{ 'ENREGISTER' | translate }}
    </button>
</form>