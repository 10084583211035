import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable } from 'rxjs';
import { TourEditRouteData } from 'src/app/concepts/tours/tour-edit-route-resolvers';

@Component({
    selector: 'app-edit-tour-nav',
    template: `
      <div class="list-filters">
        <div class="list-filters__box">
          <sp-menu>
            <sp-menu-item [routerLink]="'../settings' | localize" routerLinkActive="active">
                {{ 'PARAMETRES-TOURNEE' | translate }}
            </sp-menu-item>
            <sp-menu-item [routerLink]="'../admins' | localize" routerLinkActive="active" *ngIf="routeData.tour">
                {{ 'ADMINISTRATEURS' | translate }}
            </sp-menu-item>
            <sp-menu-item [routerLink]="'../diffuseurs' | localize" routerLinkActive="active" *ngIf="routeData.tour">
                {{ 'DIFFUSEURS' | translate }}
            </sp-menu-item>
        </sp-menu>
        </div>
      </div>
  `
})
export class EditTourNavComponent implements OnInit, OnDestroy {
    public routeData: TourEditRouteData;
    public subscription = new Subscription();

    constructor(
        readonly activatedRoute: ActivatedRoute
    ) {
    }

    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public ngOnInit() {
        this.subscription.add((this.activatedRoute.data as Observable<TourEditRouteData>).subscribe(routeData => {
            this.routeData = routeData;
        }));
    }
}
