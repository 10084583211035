<div class="program-calendar__component mb-5">
    <nz-calendar
      *ngIf="displayCal"
      class="program-calendar"
      [(ngModel)]="date"
      [nzDateCell]="dateCellTpl"
    > </nz-calendar>

    <!-- Passing TemplateRef -->
    <ng-template #dateCellTpl let-date>
         <app-date-programs [date]="date"></app-date-programs>
    </ng-template>

    <!-- LEGEND -->
    <div class="mt-3 mb-5">
      <div class="program-calendar__legend">
          <strong>{{ 'PROGRAMME-STATUS' | translate }}</strong>
          <div class="d-flex mx-2">
              <span class="legend__type legend__type--1"></span>
              <span>{{ 'PROGRAMME-STATUS-' + ProgramStatus[ProgramStatus.Internal] | translate }}</span>
          </div>
          <div class="d-flex mx-2">
              <span class="legend__type legend__type--2"></span>
              <span>{{ 'PROGRAMME-STATUS-' + ProgramStatus[ProgramStatus.Exploratory] | translate }}</span>
          </div>
          <div class="d-flex mx-2">
              <span class="legend__type legend__type--3"></span>
              <span>{{ 'PROGRAMME-STATUS-' + ProgramStatus[ProgramStatus.Provisory] | translate }}</span>
          </div>
          <div class="d-flex mx-2">
              <span class="legend__type legend__type--4"></span>
              <span>{{ 'PROGRAMME-STATUS-' + ProgramStatus[ProgramStatus.Confirmed] | translate }}</span>
          </div>
          <div class="d-flex mx-2">
              <span class="legend__type legend__type--5"></span>
              <span>{{ 'PROGRAMME-STATUS-' + ProgramStatus[ProgramStatus.Canceled] | translate }}</span>
          </div>
      </div>
    </div>
</div>
