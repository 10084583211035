import { Injectable } from '@angular/core';
import { MainService } from 'src/app/shared/services/main.services';
import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/_configs/globals';
import { ITourShowCreatedResponse, TourShow } from '../model/tour-show.model';
import { Tour, ITourKeepUpdate } from '../model/tour.model';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { SelectedFilter, Pagination } from 'src/app/shared/model/list-item.model';
import { map, delay, tap } from 'rxjs/operators';
import { TourShowInterest } from '../model/tour-show-interest.model';
import { TourShowStatus } from '../enums/tour-show-status.enum';
import { PxUser } from '../../account/model/project-x-user';

interface Event {
    method: string;
    args: Record<string, any>;
}

@Injectable({
    providedIn: 'root'
})
export class ToursService extends MainService {
    private readonly _event$ = new BehaviorSubject<Event>(null);

    constructor(protected httpClient: HttpClient, protected globals: Globals) {
        super(httpClient, globals);
    }

    get event$(): Observable<Event> {
        return this._event$.asObservable();
    }

    getTourById(tourId: number): Observable<Tour> {
        const storedData = sessionStorage.getItem('rideau.notifications.tours');
        const tourList: Tour[] = storedData ? JSON.parse(storedData) : [];
        const endpoint = this.uri + this.globals.endpoints.tour.getSingleEndpoint(tourId);
        return this.httpClient.get(endpoint).pipe(
            map((data) => new Tour(data)),
            tap((tour: Tour) => {
                tourList.push(tour);
                sessionStorage.setItem('rideau.notifications.tours', JSON.stringify([ ...tourList ]));
            })
        );
    }

    getTourByIdForNotifications(tourId: number): Observable<Tour> {
        const storedTour: Tour | null = this.getTourByIdFromStorage(tourId);
        if (storedTour) {
            return of(storedTour);
        }
        return this.getTourById(tourId);
    }

    getTourByIdFromStorage(tourId: number): Tour | null {
        const storedData = sessionStorage.getItem('rideau.notifications.tours');
        if (storedData) {
            const parsedData = JSON.parse(storedData) as Tour[];
            const index = [ ...parsedData ].findIndex(x => x.id === tourId);
            if (index > -1) {
                return new Tour(parsedData[ index ]);
            }
        }
        return null;
    }

    public setTourInStorage(tour: Tour): void {
        const tourList: Tour[] = [];
        const storedData = sessionStorage.getItem('rideau.notifications.tours');
        if (storedData) {
            const parsedData = JSON.parse(storedData) as Tour[];
            tourList.push(...parsedData);
        }
        const index = tourList.findIndex(x => x.id === tour.id);
        if (index > -1) {
            tourList[ index ] = new Tour({
                ...tourList[ index ],
                ...tour
            });
            sessionStorage.setItem('rideau.notifications.tours', JSON.stringify([ ...tourList ]));
            return;
        }
        sessionStorage.setItem('rideau.notifications.tours', JSON.stringify([ ...tourList, tour ]));
    }

    modifyTourShow(body: TourShow): Observable<any> {
        const payload = new TourShow({ ...body });
        this.cleanUpNullValues(payload);

        return this.httpClient.put(this.uri + this.globals.endpoints.tourShow.main, payload);
    }

    hideTourFromOrg(body: any): Observable<any> {
        return this.getVisibileTour(body);
    }

    getVisibileTour(body: any): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.tour.hide;

        // POST /api/tour-show
        return this.httpClient.post(endpoint, body);
    }

    createTourShow(body: TourShow): Observable<ITourShowCreatedResponse> {
        const endpoint = this.uri + this.globals.endpoints.tourShow.main;
        const payload = new TourShow({ ...body });
        this.cleanUpNullValues(payload);

        // POST /api/tour-show
        return this.httpClient.post<ITourShowCreatedResponse>(endpoint, payload);
    }

    /**
     * Gets submitted tour shows based on the provided filters, pagination, sort and search text.
     * @param {SelectedFilter[]} [filters] - An array of filters to apply to the query.
     * @param {Pagination} [pagination] - Pagination options for the query.
     * @param {any} [sort] - Sort options for the query.
     * @param {string} [searchText] - Text to search for in the tour shows.
     * @returns {Observable<TourShow[]>} An observable that emits an array of tour shows that match the provided criteria.
     */
    getSubmittedTourShows(filters?: SelectedFilter[], pagination?: Pagination, sort?: any, searchText?: string): Observable<TourShow[]> {
        let endpoint = this.uri + this.globals.endpoints.show.tourShows;
        const text = searchText ? searchText.trim() : null;

        endpoint = this.addFiltersToEndpoint(endpoint, filters);
        endpoint = this.addSortToEndpoint(endpoint, sort);
        endpoint = this.addPaginationToEndpoint(endpoint, pagination);
        endpoint = this.addSearchTextToEndpoint(endpoint, text);

        return this.httpClient.get(endpoint).pipe(
            map((data) => {
                if (pagination) {
                    pagination.total = data[ 'total' ];
                }
                return data[ 'tourShows' ];
            })
        );
    }

    /**
     * Adds filter parameters to the provided endpoint URL.
     * @private
     * @param {string} endpoint - The base endpoint URL to add filter parameters to.
     * @param {SelectedFilter[]} [filters] - An array of filters to apply to the query.
     * @returns {string} The updated endpoint URL with filter parameters added.
     */
    private addFiltersToEndpoint(endpoint: string, filters?: SelectedFilter[]): string {
        if (filters && filters.length) {
            endpoint += '?' + this.formatGetFilters(filters);
        }
        return endpoint;
    }
    /**
     * Adds sort parameters to the provided endpoint URL.
     * @private
     * @param {string} endpoint - The base endpoint URL to add sort parameters to.
     * @param {any} [sort] - Sort options for the query.
     */
    private addSortToEndpoint(endpoint: string, sort?: any): string {
        if (sort) {
            const separator = endpoint.includes('?') ? '&' : '?';
            endpoint += separator + 'sort=' + encodeURIComponent(sort);
        }
        return endpoint;
    }

    /**
     * Adds pagination parameters to the provided endpoint URL.
     * @private
     * @param {string} endpoint - The base endpoint URL to add pagination parameters to.
     * @param {Pagination} [pagination] - Pagination options for the query.
     * @returns {string} The updated endpoint URL with pagination parameters added.
     */
    private addPaginationToEndpoint(endpoint: string, pagination?: Pagination): string {
        if (pagination) {
            const separator = endpoint.includes('?') ? '&' : '?';
            endpoint += separator + this.formatPagination(pagination);
        }
        return endpoint;
    }

    /**
     * Adds search text parameter to the provided endpoint URL.
     * @private
     * @param {string} endpoint - The base endpoint URL to add search text parameter to.
     * @param {string} [searchText] - Text to search for in the tour shows.
     * @returns {string} The updated endpoint URL with search text parameter added.
     */
    private addSearchTextToEndpoint(endpoint: string, searchText?: string): string {
        if (searchText) {
            const separator = endpoint.includes('?') ? '&' : '?';
            endpoint += separator + `search=${encodeURIComponent(searchText)}`;
        }
        return endpoint;
    }

    getPaidTourShows(sourceTourId: number): Observable<TourShow[]> {
        return this.httpClient
            .get<{ tourShows: any[] }>(this.uri + this.globals.endpoints.show.tourShows, {
                params: {
                    tourId: sourceTourId + '',
                    statusId: TourShowStatus.KEPT + ''
                }
            })
            .pipe(map((data) => data.tourShows.map((v) => new TourShow(v))));
    }

    getTourShowsByTourId(tourId: number, orgId: number, showStatusId?: number): Observable<TourShow[]> {
        return this.httpClient.get<TourShow[]>(this.uri + `tours/${tourId}/tour-shows`, {
            params: {
                organizationId: orgId.toString(),
                showStatusId: showStatusId?.toString()
            }
        });
    }

    getTourShowById(tourShowId: number, filters?: SelectedFilter[]): Observable<TourShow> {
        let endpoint = this.uri + this.globals.endpoints.show.getTourShowSingleEndpoint(tourShowId);

        if (filters && filters.length) {
            endpoint += '?';
            endpoint += this.formatGetFilters(filters);
        }

        return this.httpClient.get(endpoint).pipe(map((data) => new TourShow(data)));
    }

    createTourShowInterest(tourShowId: number, tourShowInterest: TourShowInterest): Observable<any> {
        const body = tourShowInterest;
        const endpoint = this.uri + this.globals.endpoints.tourShow.singleInterestEndpoint(tourShowId);
        return this.httpClient.post(endpoint, body);
    }

    getTourShowInterests(tourShowId: number): Observable<any> {
        const endpoint = this.uri + this.globals.endpoints.tourShow.getInterestsEndpoint(tourShowId);
        return this.httpClient.get(endpoint);
    }

    getMyOrganizationTourShowInterest(tourShowId: number, orgId: number): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.tourShow.getInterestsEndpoint(tourShowId);
        endpoint += '?organizationId=' + orgId;
        return this.httpClient.get(endpoint);
    }

    getAllTours(pagination: Pagination, isAvailable?: number): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.tour.all;

        if (pagination) {
            endpoint += '?';
            endpoint += this.formatPagination(pagination);
        }

        if (isAvailable) {
            endpoint += pagination ? '&' : '';
            endpoint += `isAvailable=${isAvailable}`;
        }
        return this.httpClient.get(endpoint).pipe(
            map((data) => {
                if (pagination) {
                    pagination.total = data[ 'total' ];
                }
                return data[ 'tours' ];
            })
        );
    }

    getToursCount(orgId: number, tourId?: number, filters?: SelectedFilter[], searchText?: string): Observable<any> {
        let endpoint = this.uri + this.globals.endpoints.show.tourShows + '/count';
        const text = searchText ? searchText.trim() : null;

        if (orgId) {
            endpoint += '?';
            endpoint += `organizationId=${orgId}`;
        }

        if (tourId) {
            endpoint += orgId ? '&' : '?';
            endpoint += `tourId=${tourId}`;
        }

        if (filters && filters.length) {
            endpoint += orgId || tourId ? '&' : '?';
            endpoint += this.formatGetFilters(filters);
        }

        if (text) {
            endpoint += `&search=${encodeURIComponent(text)}`;
        }

        return this.httpClient.get(endpoint);
    }

    createTour(body: Tour): Observable<any> {
        return this.httpClient.post(this.uri + this.globals.endpoints.tour.main, this.cleanUpNullValues(body));
    }

    updateTour(body: Tour | ITourKeepUpdate): Observable<any> {
        return this.httpClient.put(this.uri + this.globals.endpoints.tour.main, this.cleanUpNullValues(body));
    }

    updateTourShow(body: Tour | ITourKeepUpdate): Observable<any> {
        return this.httpClient.put(this.uri + this.globals.endpoints.tourShow.main, this.cleanUpNullValues(body));
    }

    openForDeposit(tour: Tour) {
        return this.httpClient.put(this.uri + this.globals.endpoints.tour.main, new Tour({ ...tour, isDepositOpen: true }));
    }

    closeForDeposit(tour: Tour) {
        return this.httpClient.put(this.uri + this.globals.endpoints.tour.main, new Tour({ ...tour, isDepositOpen: false, isDepositDone: true }));
    }

    openForVotes(tour: Tour) {
        return this.httpClient.put(this.uri + this.globals.endpoints.tour.main, new Tour({ ...tour, isVoteOpen: true }));
    }

    closeForVotes(tour: Tour) {
        return this.httpClient.put(this.uri + this.globals.endpoints.tour.main, new Tour({ ...tour, isVoteOpen: false, isVoteDone: true }));
    }

    // enpoints relatifs aux tournees - tournees et depots sont la meme entite dans le backend - une tournee est un depot dont la propriete isPaid est a true.

    paidTours(organizationId: number): Observable<Tour[]> {
        return this.httpClient
            .get(`${this.uri + this.globals.endpoints.tour.paid}?organizationId=${organizationId}&isAvailable=1`)
            .pipe(map((data: { tours: unknown[] }) => data.tours.map((v) => new Tour(v))));
    }

    archivedPaidTours(organizationId: number): Observable<Tour[]> {
        return this.httpClient
            .get(`${this.uri + this.globals.endpoints.tour.paid}?organizationId=${organizationId}&isAvailable=0`)
            .pipe(map((data: { tours: unknown[] }) => data.tours.map((v) => new Tour(v))));
    }

    addDiffuseur(
        tourId: number,
        organizationId: number
    ): Observable<{
        tourDiffuser: { id: number; organizationId: number };
        isCreated: boolean;
    }> {
        return this.httpClient.post<{
            tourDiffuser: { id: number; organizationId: number };
            isCreated: boolean;
        }>(this.uri + this.globals.endpoints.tour.getDiffuseurEndpoint(tourId), {
            organizationId
        });
    }

    addAllDiffuseurs(
        tourId: number
    ): Observable<{
        tourDiffusers: Array<{ id: number; organizationId: number }>;
        isCreated: boolean;
    }> {
        return this.httpClient.post<{
            tourDiffusers: Array<{ id: number; organizationId: number }>;
            isCreated: boolean;
        }>(this.uri + this.globals.endpoints.tour.getAllDiffuseursEndpoint(tourId), null);
    }

    removeDiffuseur(tourId: number, organizationId: number): Observable<{ isDeleted: boolean }> {
        return this.httpClient.delete<{ isDeleted: boolean }>(this.uri + this.globals.endpoints.tour.getOneDiffuseursEndpoint(tourId, organizationId));
    }

    accesDiffuseurs(tourId: number, organizationId: number, open: boolean): Observable<{ isUpdated: boolean }> {
        return this.httpClient.put<{ isUpdated: boolean }>(this.uri + this.globals.endpoints.tour.diffuseur, {
            id: tourId,
            organizationId,
            isDepositOpen: +open
        });
    }

    addDateChoice(tour: Tour, tourShow: TourShow, date: Date): Observable<void> {
        // ! TO FIX: METHOD NOT IMPLEMENTED
        return this.removeDateChoice(tour, tourShow, date);
    }

    removeDateChoice(tour: Tour, tourShow: TourShow, date: Date): Observable<void> {
        // ! TO FIX: METHOD NOT IMPLEMENTED
        return of(null).pipe(delay(2000));
    }

    deleteTourShow(tourShowId: number, ctxId: number): Observable<{ isDeleted: boolean }> {
        return this.httpClient.delete<{ isDeleted: boolean }>(this.uri + this.globals.endpoints.tourShow.getTourShowDeleteEndpoint(tourShowId, ctxId));
    }

    /**
     * ADMIN SECTION
     */

    getTourAdmins(tourId: number): Observable<{ total: number; tourAdmins: PxUser[] }> {
        return this.httpClient.get<{ total: number; tourAdmins: PxUser[] }>(this.uri + this.globals.endpoints.tour.getTourAdmins(tourId));
    }

    getOrganizationTourShows(organizationID: number): Observable<OrganizationToursShows> {
        return this.httpClient
            .get<OrganizationToursShows>(this.uri + this.globals.endpoints.organization.getTourShows(organizationID))
            .pipe(map((tourShow: ITourShows) => new OrganizationToursShows(tourShow)));
    }

    getVisibleTourByOrganization(organizationID: number): Observable<any> {
        return this.httpClient.get<any>(this.uri + this.globals.endpoints.organization.getVisibleTourByOrg(organizationID));
    }
    HideTourForOrganization(organizationId: number, tourId: number): Observable<any> {
        return this.httpClient.post<any>(this.uri + this.globals.endpoints.tour.HideTourForOrganization, {
            organizationId: organizationId,
            tourId: tourId
        });
    }

    deleteTourShowBulk(ids: number[]) {
        return this.httpClient.patch(this.uri + 'tour-shows/bulk-delete', {
            ids: ids
        });
    }

    addTourAdmin(tourId: number, userId: number): Observable<any> {
        return this.httpClient.post<any>(this.uri + this.globals.endpoints.tour.addTourAdmin(tourId), {
            userId
        });
    }

    deleteTourAdmin(tourId: number, userId: number): Observable<any> {
        return this.httpClient.delete(this.uri + this.globals.endpoints.tour.deleteTourAdmin(tourId, userId));
    }

    /**
     * END ADMIN SECTION
     */
}

export class OrganizationToursShows {
    tourShows: TourShows[] = [];

    constructor(data: ITourShows) {
        data.tourShows.forEach((tourShow) => {
            const tourShowss: TourShows = {
                tour: new Tour(tourShow.tour),
                tourShows: tourShow.tourShows.map((tourShow) => new TourShow(tourShow))
            };

            this.tourShows.push(tourShowss);
        });
    }
}

export interface ITourShows {
    tourShows: TourShows[];
}

export interface TourShows {
    tour: Tour;
    tourShows: TourShow[];
}
