import { Component, Input, OnInit, HostBinding, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

let unique = 0;

@Component({
    selector: 'app-time-picker',
    template: `
        <nz-form-control [nzValidateStatus]="validateStatus">
            <nz-time-picker
                class="w-100"
                [(ngModel)]="selected"
                (ngModelChange)="ngModelChange()"
                nzFormat="HH:mm"
                [nzDisabled]="disabled"
                [nzDefaultOpenValue]="date"
            ></nz-time-picker>
        </nz-form-control>
    `,
    styles: [
        `
            :host {
                display: block;
            }

            :host /deep/ nz-time-picker,
            :host /deep/ nz-picker,
            :host /deep/ span.ant-calendar-picker,
            :host /deep/ input.ant-calendar-picker-input {
                display: block;
                width: 100%;
            }

            :host /deep/ .ant-picker {
                --border-color: #e5e3df;
                border-radius: 5px !important;
                border: 2px solid var(--border-color) !important;
                height: 45px;
                display: flex;
                align-items: center;
                &.ant-picker-focused {
                    --border-color: var(--primary-color);
                }
            }

            :host /deep/ .ant-picker-input > input {
                &:hover {
                    border: none !important;
                }
            }

            :host.warning /deep/ .ant-calendar-picker-input {
                border: 2px solid #faad14 !important;
                border-radius: 4px !important;
            }

            :host.warning /deep/ .ant-calendar-picker-input:hover {
                border: 2px solid #faad14 !important;
            }

            :host.error /deep/ .ant-calendar-picker-input {
                border: 2px solid #f5222d !important;
                border-radius: 4px !important;
            }

            :host.error /deep/ .ant-calendar-picker-input:hover {
                border: 2px solid #f5222d !important;
            }
        `
    ]
})
export class TimePickerComponent implements OnInit, ControlValueAccessor, OnChanges {
    private onChange: (value: Date) => void;
    private onTouched: () => void;
    @Input() disabled: boolean;
    @Input() format: string;
    @Input() validateStatus = '';
    @Input() control: UntypedFormControl;
    date: Date;
    @HostBinding('class.warning') get classWarning() {
        return this.validateStatus === 'warning';
    }
    @HostBinding('class.error') get classError() {
        return this.validateStatus === 'error';
    }
    @HostBinding('class.disabled') get classDisabled() {
        return this.disabled;
    }
    id = `timePickerComponent-${unique++}`;
    selected: Date;
    constructor(readonly translateService: TranslateService, readonly ngControl: NgControl) {
        ngControl.valueAccessor = this;
    }
    ngOnInit(): void {
        this.translateService.currentLang;
        this.date = new Date();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['disabled']) {
            this.setDisabledState(changes['disabled'].currentValue);
        }
    }

    ngModelChange(): void {
        this.onChange(this.selected);
        this.onTouched();
    }
    writeValue(value: Date): void {
        if (value) {
            this.selected = value;
        }
    }
    registerOnChange(fn: (value: Date) => void): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
