<ng-container *ngIf="isReady">
    <app-page-title
        [title]="organization.getTranslatedProperty(lang, 'name')"
        [categories]="organization.types"
        [image]="organization.logo"
        [buttonText]="'FORM.MODIFIER' | translate"
        [buttonLink]="editLink"
        [permission]="1"
        [organizationId]="organization.id"
        [isWithImagePage]="true"
    ></app-page-title>

    <div class="container page-template">
        <div nz-row nzGutter="16">
            <div nz-col nzSpan="24" nzMd="11">
                <quill-editor
                    [modules]="{ toolbar: false }"
                    [readOnly]="true"
                    class="fiche-content"
                    [ngModel]="descriptionModel ? descriptionModel : ('AUCUNE-DONNEE' | translate)"
                ></quill-editor>
            </div>
            <div nz-col nzSpan="24" nzMd="8">
                <p class="page-template-labels mb-1">{{ 'COORDONNEES' | translate }}</p>

                <div class="fiche-details mb-3">
                    <ng-container *ngIf="organization.address">
                        <ng-container *ngTemplateOutlet="text; context: { $implicit: organization.address.address1 }"></ng-container>
                        <ng-container *ngTemplateOutlet="text; context: { $implicit: organization.address.address2 }"></ng-container>
                        <ng-container *ngTemplateOutlet="text; context: { $implicit: organization.address.city + ', ' + organizationState }"></ng-container>
                        <ng-container *ngTemplateOutlet="text; context: { $implicit: 'COUNTRIES.' + organization.address.countryId | translate }"></ng-container>
                        <ng-container *ngTemplateOutlet="text; context: { $implicit: organization.address.zipCode }"></ng-container>
                    </ng-container>
                    <ng-container
                        *ngTemplateOutlet="
                            text;
                            context: { $implicit: (organization.phone || '') + (organization.phonePostNumber ? '[' + organization.phonePostNumber + ']' : '') }
                        "
                    ></ng-container>
                    <ng-container *ngTemplateOutlet="text; context: { link: { mailto: true, url: organization.email } }"></ng-container>
                    <ng-container *ngTemplateOutlet="text; context: { link: { mailto: false, url: organization.website | externalLink } }"></ng-container>
                    <div class="d-flex align-items-center mt-3" *ngIf="organization.wikidataId">
                        <ng-container
                            *ngTemplateOutlet="
                                text;
                                context: {
                                    link: {
                                        mailto: false,
                                        url: wikidataUrlBase + organization.wikidataId | externalLink,
                                        content: ('VENUE-DATA.PAGE' | translate) + '&nbsp;' + ('VENUE-DATA.WIKIDATA_TITLE' | translate),
                                        tooltip: 'VENUE-DATA.TOOLTIP_LINK' | translate
                                    }
                                }
                            "
                        ></ng-container>
                    </div>
                </div>
                <ng-container class="social-icons" *ngFor="let social of socialMediasArray">
                    <app-social-link linkUrl="{{ social.url | externalLink }}" linkLabel="{{ social.urlDomain }}"></app-social-link>
                </ng-container>
            </div>
            <div nz-col nzSpan="24" nzMd="5" *ngIf="organization.networks.length > 0">
                <h3>{{ 'MEMBRE-DE' | translate }}</h3>
                <div nz-row class="network-tem mb-3" *ngFor="let network of networks">
                    <div nz-col nzSpan="8">
                        <img [src]="network.logo || '/assets/images/placeholder-organisation.svg'" alt="network logo" />
                    </div>
                    <div nz-col nzSpan="12">
                        <a [routerLink]="['/organization/', network.id] | localize">
                            <span class="page-template-text page-template-text--small">
                                {{ network.getTranslatedProperty(lang, 'name') }}
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid bg-gray py-5 mt-5 d-flex justify-content-center">
        <div class="container d-flex justify-content-center">
            <div class="col">
                <div nz-row nzGutter="16" class="mb-3" class="row d-flex align-items-center justify-content-center">
                    <div nz-col class="d-flex align-items-center justify-content-center">
                        <nz-radio-group class="fiche-filter" [(ngModel)]="activeTab">
                            <label class="fiche-filter__radio" nz-radio-button nzValue="TAB_TEAM" (click)="toggleOrganizationContent('TAB_TEAM')">
                                {{ 'EQUIPE' | translate }}
                                <span class="fiche-filter__count">
                                    {{ organizationTeamList.length }}
                                </span>
                            </label>

                            <label
                                class="fiche-filter__radio"
                                *ngIf="networkMembers.length > 0"
                                nz-radio-button
                                nzValue="TAB_NETWORK_MEMBERS"
                                (click)="toggleOrganizationContent('TAB_NETWORK_MEMBERS')"
                            >
                                {{ 'MEMBRES' | translate }}
                                <span class="fiche-filter__count">
                                    {{ networkMembers.length }}
                                </span>
                            </label>

                            <label
                                class="fiche-filter__radio"
                                *ngIf="organization?.types.includes(organizationTypes.IS_PRODUCTEUR)"
                                nz-radio-button
                                nzValue="TAB_SHOWS"
                                (click)="toggleOrganizationContent('TAB_SHOWS')"
                            >
                                {{ 'SHOWS' | translate }}
                                <span class="fiche-filter__count">
                                    {{ producerShows.length }}
                                </span>
                            </label>
                        </nz-radio-group>
                    </div>
                </div>
                <div nz-row nzGutter="16" class="row mt-3">
                    <div nz-col class="w-100">
                        <div *ngIf="activeTab == 'TAB_TEAM'">
                            <div class="item-card-grid">
                                <ng-container *ngIf="organizationTeamList.length; else nothingToShow">
                                    <ng-container *ngFor="let teamMate of organizationTeamList">
                                        <div class="item-card-wrapper">
                                            <span style="font-family: 'GT-Haptik-Bold', sans-serif">{{ teamMate.firstName + ' ' + teamMate.lastName }}</span>
                                            <span>{{ teamMate.title }}</span>
                                            <a href="mailto:{{ teamMate.email ? teamMate.email : '' }}" class="email-ellipsis">
                                                <span>
                                                    <i nz-icon aria-hidden="true" nzType="mail" nzTheme="outline" style="margin-right: 7px"></i>
                                                    {{ teamMate.email }}
                                                </span>
                                            </a>
                                            <span>
                                                <i nz-icon aria-hidden="true" nzType="phone" nzTheme="outline"></i>
                                                {{ teamMate.phone }}
                                            </span>
                                            <span *ngIf="teamMate.phonePostNumber">Poste : {{ teamMate.phonePostNumber }}</span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>

                        <div *ngIf="activeTab == 'TAB_NETWORK_MEMBERS'">
                            <div class="item-card-grid">
                                <ng-container *ngIf="networkMembers.length > 0; else nothingToShow">
                                    <ng-container *ngFor="let member of networkMembers">
                                        <div class="item-card-wrapper">
                                            <app-item-card [item]="member"></app-item-card>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>

                        <div *ngIf="activeTab == 'TAB_SHOWS'">
                            <div class="item-card-grid">
                                <ng-container *ngIf="producerShows.length; else nothingToShow">
                                    <ng-container *ngFor="let show of producerShows">
                                        <div class="item-card-wrapper">
                                            <app-item-card [item]="show"></app-item-card>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </div>
                        </div>
                        <ng-template #nothingToShow>
                            <p class="text-center">{{ 'AUCUNE-INFORMATIONS' | translate }}</p>
                        </ng-template>
                    </div>
                    <ng-template #nothingToShow>
                        <p class="text-center">{{ 'AUCUNE-INFORMATIONS' | translate }}</p>
                    </ng-template>
                </div>
            </div>


        </div>
    </div>
</ng-container>

<ng-template #text let-content let-link="link">
    <p class="mb-0">
        <ng-container *ngTemplateOutlet="!link ? defaultTpl : link.mailto ? linkTpl : urlTpl; context: { $implicit: content, link: link }"></ng-container>
        <ng-template #defaultTpl let-content>
            <ng-container *ngIf="content">{{ content }}</ng-container>
        </ng-template>
        <ng-template #linkTpl let-link="link">
            <ng-container *ngIf="link.url">
                <a href="mailto:{{ link.url }}">
                    {{ link.content || link.url }}
                </a>
            </ng-container>
        </ng-template>
        <ng-template #urlTpl let-link="link">
            <ng-container *ngIf="link.url">
                <a class="website label-only label-with-icon" href="{{ link.url }}" target="_blank">
                    <span>
                        {{ link.content || link.url }}
                    </span>
                    <ng-container *ngIf="link.tooltip">
                        <span class="help-icon help-icon--blue help-icon--small" nz-tooltip nzTooltipTitle="{{ link.tooltip }}" nzTooltipPlacement="right"></span>
                    </ng-container>
                </a>
            </ng-container>
        </ng-template>
    </p>
</ng-template>
