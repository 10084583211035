<app-page-title title="{{ 'DATE-SPECTACLE' | translate }}">
    <div class="show-info" *ngIf="program && program.tourId">
        <i class="icon-world" aria-hidden="true"></i>
        <h5 *ngIf="tour">{{ tour.organization | translatable : 'name' : '' }} - {{ tour | translatable : 'name' }}</h5>
    </div>
    <!-- DELETE DATE INSCRIPTION -->
    <button
        type="button"
        style="margin-right: 10px"
        class="btn-square"
        (click)="deleteDateInscription()"
        *ngIf="program && program.id && (program.tourId ? program.isTourAdmin : true)"
    >
        <i class="icon-trash" aria-hidden="true"></i>
    </button>
</app-page-title>

<form nz-form [formGroup]="programForm" (ngSubmit)="submitFormHandler(isMatchUpdatedLocal)" *ngIf="program">
    <div class="container">
        <div class="row justify-content-center" *ngIf="notification$ | async">
            <div class="col col-sm-8 col-lg-6 mb-5">
                <app-notifications-component [notification]="notification$ | async"></app-notifications-component>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col col-sm-8 col-lg-6 mb-5">
                <div nz-row nzGutter="16" class="ml-1 mb-1 row">
                    <!-- date -->
                    <app-date-picker formControlName="date" [validateStatus]="isDateUpdatePending ? (isMatchUpdatedLocal ? 'error' : 'warning') : ''" class="w-100">
                        {{ 'FORM.DATE' | translate }} *
                    </app-date-picker>

                    <!-- showId -->
                    <ng-container [ngTemplateOutlet]="isCustomShowEnable ? customShowTpl : selectableShowTpl"></ng-container>
                    <ng-template #customShowTpl>
                        <!-- showCustom -->
                        <app-text-input formControlName="showCustom" [disabled]="isDisabled || !checkIfCanUpdateFormControlProgramInTour('showCustom')">
                            {{ 'TITLE' | translate }}

                            <p *ngIf="!isDisabled" after class="form-text manual-input">
                                {{ 'CHERCHER-REPERTOIRE-SPECTACLE' | translate }}
                                <a (click)="toggleShowManualInput()">{{ 'CHERCHER-REPERTOIRE' | translate }}</a>
                            </p>
                        </app-text-input>
                    </ng-template>
                    <!-- selectable Show -->
                    <ng-template #selectableShowTpl>
                        <app-row-select
                            [rowType]="rowTypes.SHOW"
                            [options]="shows"
                            labelProperty="title"
                            formControlName="showId"
                            (ngModelChange)="updateShowRelated($event)"
                            [disabled]="isDisabled || !!program.match || !checkIfCanUpdateFormControlProgramInTour('showId')"
                            class="w-100"
                        >
                            {{ 'SPECTACLE' | translate }}

                            <p after class="form-text manual-input" *ngIf="!isDisabled && !program.match && !isProducer">
                                {{ 'SAISIE-MANUELLE-SPECTACLE' | translate }}
                                <a (click)="toggleShowManualInput()">{{ 'SAISIE-MANUELLE' | translate }}</a>
                            </p>
                        </app-row-select>
                    </ng-template>

                    <!-- diffuserId -->

                    <div *ngIf="!isDiffuser || isProducer || !!program.tourId">
                        <app-row-select
                            *ngIf="!isCustomVenueEnable; else customVenue"
                            [rowType]="rowTypes.DIFFUSEUR"
                            [options]="diffusers"
                            labelProperty="name"
                            formControlName="diffuserId"
                            [disabled]="isDisabled || !checkIfCanUpdateFormControlProgramInTour('diffuserId')"
                        >
                            {{ 'DIFFUSEUR' | translate }}

                            <p after class="form-text manual-input" *ngIf="!isDisabled && !isCustomVenueEnable">
                                {{ 'SAISIE-MANUELLE-DIFFUSEUR' | translate }}
                                <a (click)="toggleDiffuserManualInput()">{{ 'SAISIE-MANUELLE' | translate }}</a>
                            </p>
                        </app-row-select>

                        <!-- venueCustom -->
                        <ng-template #customVenue>
                            <app-text-input formControlName="venueCustom">
                                {{ 'FORM.LIEU' | translate }}

                                <p after class="form-text manual-input" *ngIf="!isDisabled">
                                    {{ 'CHERCHER-REPERTOIRE-DIFFUSEUR' | translate }}
                                    <a (click)="toggleDiffuserManualInput()">{{ 'CHERCHER-REPERTOIRE' | translate }}</a>
                                </p>
                            </app-text-input>
                        </ng-template>
                    </div>

                    <section class="w-100" *ngIf="!isProducer || !isProducer || isCustomVenueEnable || program.match">
                        <div nz-row nzGutter="16" class="mx-0">
                            <!-- venueId -->
                            <div nz-col nzMd="16" class="col-8 px-0 mr-0" *ngIf="!isCustomVenueEnable && (!isProducer || program.match)">
                                <app-select
                                    formControlName="venueId"
                                    labelProperty="name"
                                    [options]="venues"
                                    [disabled]="isDisabled || (isProducer && !!program.match) || !checkIfCanUpdateFormControlProgramInTour('venueId')"
                                    [validateStatus]="isVenueIdUpdatePending ? (isMatchUpdatedLocal ? 'error' : 'warning') : ''"
                                    [showSearch]="true"
                                >
                                    {{ 'FORM.SALLE' | translate }}
                                </app-select>
                            </div>

                            <!-- hour -->
                            <div nz-col nzMd="8" class="col-4 px-0 ml-0 mr-0">
                                <nz-form-item class="filed-hour" [ngClass]="!isCustomVenueEnable && (!isProducer || program.match) ? 'filed-hour' : 'filed-hour simple-hour' ">
                                    <label class="filed-hour-label" for="hour">{{ 'HEURE' | translate }}</label>
                                    <app-time-picker
                                        formControlName="hour"
                                        [disabled]="isProducer || !checkIfCanUpdateFormControlProgramInTour('hour')"
                                        [validateStatus]="isHourUpdatePending ? (isMatchUpdatedLocal ? 'error' : 'warning') : ''"
                                    ></app-time-picker>
                                </nz-form-item>
                            </div>
                        </div>
                    </section>

                    <!-- timezoneId -->
					<app-select
                        formControlName="timezoneId"
                        labelProperty="tz_id"
                        [options]="timezones"
                        [disabled]="isDisabled || !checkIfCanUpdateFormControlProgramInTour('timezoneId')"
                        [validateStatus]="(programForm.get('timezoneId').errors && programForm.get('timezoneId').touched) ? 'error' : ''"
                        [isRequired]="programForm.get('timezoneId').errors && programForm.get('timezoneId').touched"
                        class="w-100"
                        [showSearch]="true"
                    >
                    {{ 'TIMEZONE' | translate }} *
                    </app-select>

                    <!-- statusId -->
                    <app-radio
                        formControlName="statusId"
                        [Options]="programStatus"
                        *ngIf="(program.id && program.match) || program.tourId || isCustomShowEnable || (isProducer && isCustomVenueEnable) || (isDiffuser && isCustomShowEnable)"
                        [validateStatus]="isStatusUpdatePending ? (isMatchUpdatedLocal ? 'error' : 'warning') : ''"
                        [disabled]="!checkIfCanUpdateFormControlProgramInTour('statusId')"
                        translateKeyPrefix="PROGRAMME-STATUS-"
                        [filterOutKeys]="[0, 1]"
                        class="w-100"
                    >
                        {{ 'FORM.STADE-CONFIRMATION' | translate }}
                    </app-radio>

                    <button nz-button [nzLoading]="loading" class="btn btn--default mb-5 mt-3" *ngIf="isMatchUpdatedLocal && programForm.value.statusId === program.match.statusId">
                        <i nz-icon nzType="check"></i>
                        {{ 'VALIDATE-PENDING-CHANGES' | translate }}
                    </button>
                </div>

                <section *ngIf="!tour || (tour && program.isTourAdmin)" >
                    <div nz-row nzGutter="16" class="ml-1 mb-3" *ngIf="showPrivateFieldsSeparator()">
                        <div class="form-separator">
                            <span class="form-separator__line"></span>
                            <i>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                    <g fill="none" fill-rule="evenodd">
                                        <g fill="#1C1C1B" fill-rule="nonzero">
                                            <path
                                                d="M10 3c2.689 0 4.882 2.122 4.995 4.783L15 9.267C15.597 9.613 16 10.259 16 11v4c0 1.105-.895 2-2 2H6c-1.105 0-2-.895-2-2v-4c0-.74.402-1.386.999-1.732L5 8c0-2.761 2.239-5 5-5zm0 2C8.402 5 7.096 6.249 7.005 7.824L7 9h5.999L13 8c0-1.657-1.343-3-3-3z"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </i>
                            <span class="form-separator__line"></span>
                        </div>
                        <p class="form-text">{{ 'FORM.PRIVATE-INFOS' | translate }}</p>
                    </div>

                    <div nzGutter="16" class="ml-1 mb-1 col p-0">
                        <!-- calendarId -->

                            <nz-form-item class="w-100">
                                <div class="row w-100 m-0">
                                    <div class="col-12 mb-1 p-0">
                                        <label for="calendarId">{{ 'CALENDAR' | translate }}</label>
                                    </div>
                                    <div class="col-12 p-0">
                                        <nz-form-control>
                                            <nz-select name="calendarId" nzAllowClear style="width: 100%"
                                                formControlName="calendarId" [nzDisabled]="!checkIfCanUpdateFormControlProgramInTour('calendarId')">
                                                <nz-option
                                                    *ngFor="let calendar of calendars"
                                                    [nzLabel]="calendar.getTranslatedProperty(translate.currentLang, 'title')"
                                                    [nzValue]="calendar.id"
                                                ></nz-option>
                                            </nz-select>
                                        </nz-form-control>
                                    </div>
                                </div>
                            </nz-form-item>

                            <!-- ADDITIONNAL NOTE -->
                            <app-text-input formControlName="note">
                                {{ 'FORM.NOTE' | translate }}
                            </app-text-input>
                    </div>
                </section>
            </div>
        </div>
    </div>

    <!-- FORM SUBMIT -->
    <div *ngIf="!isDisabled" class="bg-neutral py-5">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col col-sm-8 col-lg-6 mb-5">
                    <button nz-button [nzLoading]="loading" [disabled]="!programForm.valid || loading || disabledButtonSaveInTourForProducer" class="btn btn--default">
                        {{ 'ENREGISTER' | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
