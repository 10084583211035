import { Component, Input, OnInit, OnDestroy, HostBinding, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { Program } from 'src/app/concepts/program/model/program.model';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { sameDay } from '../../date-utilities';
import { take, finalize } from 'rxjs/operators';
import { ProgramStatus } from '../../../program/model/program-status.model';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { ProgramRouteData } from 'src/app/concepts/program/program-route-resolvers';
import { TourActions } from 'src/app/concepts/tours/tour-actions.service';
import { PaidTourProgramsResolver } from 'src/app/concepts/tours/paid-tour-programs.resolver';
import { PaidTourShowResolver } from 'src/app/concepts/tours/paid-tour-show.resolver';
import { ShowStatus } from 'src/app/concepts/show/enums/show-status.enum';
import { ProgramService } from '../../services/program.services';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { OrganizationTypes } from 'src/app/concepts/organization/enums/organization-types.enum';

@Component({
    selector: 'app-date-programs',
    templateUrl: './date-programs.component.html',
    styleUrls: ['./date-programs.component.scss']
})
export class DateProgramsComponent implements OnInit, OnDestroy, OnChanges {
    programStatus = ProgramStatus;
    routeData: ProgramRouteData;
    @Input() date: Date;
    @HostBinding('class.selected') selected = false;
    @HostBinding('class.selectable') selectable = false;

    loading = false;
    showWarningDateModal = false;
    showTourShowWarningModal = false;
    showMorePrograms = 3;
    programs: Program[];
    showPrograms: Program[];
    showStatusObject = ShowStatus;
    currentOrganizationId: number;

    isTourShowInDateRange = false;
    isTourShowRangeFirstDate = false;
    private destroyed: Subject<void> = new Subject();

    constructor(
        private readonly activatedRoute: ActivatedRoute,
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        private readonly router: Router,
        private readonly tourActions: TourActions,
        private readonly paidTourProgramsResolver: PaidTourProgramsResolver,
        private readonly paidTourShowResolver: PaidTourShowResolver,
        public readonly translateService: TranslateService,
        private programService: ProgramService,
        private accountService: AccountService
    ) {}

    ngOnDestroy(): void {
        this.destroyed.next();
        this.destroyed.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.date) {
            this.ngOnInit();
        }
    }

    ngOnInit(): void {
        this.currentOrganizationId = this.accountService.getCurrentCtxOrganizationId();
        (this.activatedRoute.data as Observable<ProgramRouteData>).pipe(takeUntil(this.destroyed)).subscribe((routeData) => {
            this.routeData = routeData;
            this.updatePrograms();
        });

        this.paidTourProgramsResolver.state$.pipe(takeUntil(this.destroyed)).subscribe((programs) => {
            this.routeData.tourPrograms = programs;
            this.updatePrograms();
        });

        this.paidTourShowResolver.state$.pipe(takeUntil(this.destroyed)).subscribe((tourShow) => {
            this.routeData.tourShow = tourShow;
            this.updatePrograms();
        });
    }

    updatePrograms(): void {
        if (this.isProducer()) {
            this.updateProgramsForProducer();
        } else {
            this.updateProgramsForDiffuser();
        }
        if (this.routeData.tour) {
            this.updateShowProgramsInTour();
            this.updateProgramInTour();

            if (this.routeData.tourShow) {
                this.updateShowProgramsAsTourShow();
                this.checkIfTourShowIsInRange();
                this.checkIfIsFirstAvailableDateForTourShow();
                this.updateProgramAsTourShow();

                this.selected = this.showPrograms.some(
                    (showProgram) =>
                        showProgram.showId === this.routeData.tourShow.show.id &&
                        showProgram.tourId === this.routeData.tour.id &&
                        showProgram.organizationId === this.currentOrganizationId
                );
                const programCount = this.routeData.tourShow.programCount || 0;
                this.selectable = this.selected || programCount < this.routeData.tour.maxDateSelected;
            }
            if (this.routeData.tourAdmin) {
                this.selectable = false;
                this.updateProgramsForTourAdmin();
            }
        }
    }

    public selectProgram(program: Program): void {
        if (this.isShowExpired(program) || this.isDisabledForUser(program)) {
            return;
        }

        const isTourMatching = this.routeData.tour && this.routeData.tour.id === program.tourId;
        const isProgramStatusValid = [ProgramStatus.Confirmed, ProgramStatus.Provisory, this.programStatus.Canceled].includes(program.statusId);

        if (isTourMatching || !program.tourId || isProgramStatusValid) {
            this.router.navigate([this.localizeRouter.translateRoute(`/programmations/${program.id}`)]);
        }
    }

    onToggle(): void {
        this.loading = true;
        if (this.selected) {
            if (!this.checkDateIsInTourDates()) {
                this.showWarningDateModal = true;
            } else if (!this.isTourShowInDateRange) {
                this.showTourShowWarningModal = true;
            } else {
                this.addDateChoice();
            }
        } else {
            this.tourActions
                .removeDateChoice(
                    this.routeData.organization,
                    this.showPrograms.find((showProgram) => {
                        return (
                            showProgram.showId === this.routeData.tourShow.show.id &&
                            showProgram.tourId === this.routeData.tour.id &&
                            showProgram.organizationId === this.currentOrganizationId
                        );
                    })
                )
                .pipe(
                    finalize(() => (this.loading = false)),
                    take(1)
                )
                .subscribe();
        }
    }

    public isShowExpired = (program: Program): boolean => {
        if (!program.show) {
            return false;
        }
        return program.show.statusId === this.showStatusObject.EXPIRE;
    };

    public handleCancelWarningDateModal(): void {
        this.showWarningDateModal = false;
        this.loading = false;
        this.selected = false;
    }

    public handleValidateWarningDateModal(): void {
        this.showWarningDateModal = false;
        this.addDateChoice();
    }

    public handleValidateWarningTourShowDateModal(): void {
        this.showTourShowWarningModal = false;
        this.addDateChoice();
    }

    public handleCancelWarningTourShowDateModal(): void {
        this.showTourShowWarningModal = false;
        this.loading = false;
        this.selected = false;
    }

    public actionProgram(programData: { type: string; program: Program }): void {
        switch (programData.type) {
            case 'confirm': {
                programData.program.statusId = 4;
                this.programService
                    .updateProgram(programData.program)
                    .pipe(takeUntil(this.destroyed))
                    .subscribe(() => this.updateSingleProgramAfterAction(programData.program));
                break;
            }
            case 'provisory': {
                programData.program.statusId = 3;
                this.programService
                    .updateProgram(programData.program)
                    .pipe(takeUntil(this.destroyed))
                    .subscribe(() => this.updateSingleProgramAfterAction(programData.program));
                break;
            }
            case 'delete': {
                this.programService
                    .deleteSingleProgram(programData.program.id)
                    .pipe(takeUntil(this.destroyed))
                    .subscribe(() => {
                        const programIndex = this.showPrograms.findIndex((program) => program.id === programData.program.id);
                        if (programIndex !== -1) {
                            this.showPrograms.splice(programIndex, 1);
                        }
                    });
                break;
            }
            default: {
                break;
            }
        }
    }

    public isDisabledForUser(program: Program): boolean {
        if (this.routeData.tour) {
            return this.routeData.tour.id !== program.tourId || program.statusId !== ProgramStatus.Confirmed;
        } else if(program.tourId) {
            return program.statusId !== ProgramStatus.Confirmed;
        }
        // all users can access the non-tour show date form  whichever its status
        return false;
    }

    private checkDateIsInTourDates(): boolean {
        const tourStartDate = new Date(this.routeData.tour.startDate);
        const tourEndDate = new Date(this.routeData.tour.endDate);
        return this.date >= tourStartDate && this.date <= tourEndDate;
    }

    private addDateChoice(): void {
        this.tourActions
            .addDateChoice(this.routeData.organization, this.routeData.tour, this.routeData.tourShow.show, this.date)
            .pipe(
                finalize(() => (this.loading = false)),
                take(1)
            )
            .subscribe();
    }

    private checkIfTourShowIsInRange(): void {
        this.isTourShowInDateRange = this.routeData.tourShow.isDateIsAvailableForTour(this.date, this.routeData.tour);
    }

    private checkIfIsFirstAvailableDateForTourShow(): void {
        this.isTourShowRangeFirstDate = this.routeData.tourShow.isFirstDateAvailable(this.date);
    }

    private updateProgramsForProducer = (): void => {
        if (!this.routeData.programs) {
            this.programs = [];
            return;
        }
        const programOnTour = [...this.routeData.programs]
            .filter((program) => program.tourId && program.statusId == 4)
            .filter((program) => sameDay(program.date, this.date))
            .filter((program) => this.currentOrganizationId === program.show.organization.id);
        const programOutTour = [...this.routeData.programs]
            .filter((program) => !!program.show)
            .filter((program) => sameDay(program.date, this.date))
            .filter((program) => this.currentOrganizationId === program.show.organization.id)
            .filter((program) => !program.tourId)
            .filter((program) => !program.isDiffuser);
        this.programs = programOutTour.concat(programOnTour);
    };

    private updateProgramsForDiffuser = (): void => {
        if (!this.routeData.programs) {
            this.programs = [];
            return;
        }
        this.programs = [...this.routeData.programs]
            .filter((program) => (!this.routeData.tour || program.calendarId !== this.routeData.tour.calendarId) && sameDay(program.date, this.date))
            .filter((program) => this.filterForOrganization(program));
    };

    private updateProgramsForTourAdmin = () => {
        this.programs = this.routeData.tourPrograms.filter((program) => program.tourId === undefined).filter((program) => sameDay(program.date, this.date));
        this.showPrograms = this.routeData.tourPrograms.filter((programs) => programs.tourId).filter((program) => sameDay(program.date, this.date));
    };

    private updateProgramInTour = () => {
        this.programs = this.routeData.tourPrograms
            .filter((program) => program.tourId === this.routeData.tour.id)
            .filter((program) => this.filterForOrganization(program))
            .filter((program) => sameDay(program.date, this.date));
    };

    private updateProgramAsTourShow = () => {
        this.programs = this.routeData.tourPrograms
            ? this.routeData.tourPrograms
                  .filter((program) => (!this.routeData.tour || program.calendarId !== this.routeData.tour.calendarId) && sameDay(program.date, this.date))
                  .filter((program) => this.filterForOrganization(program))
                  .filter((program) => (this.showPrograms.indexOf(program) === -1 ? true : false))
            : [];
    };

    private updateShowProgramsInTour = () => {
        this.showPrograms = this.routeData.tourPrograms
            .filter((program) => program.tourId === null || program.tourId != this.routeData.tour.id)
            .filter((program) => this.filterForOrganization(program))
            .filter((program) => sameDay(program.date, this.date));
    };
    private updateShowProgramsAsTourShow = () => {
        this.showPrograms = this.routeData.tourPrograms
            .filter((program) => sameDay(program.date, this.date))
            .filter((program) => !!program.show && program.show.id === this.routeData.tourShow.show.id);
    };

    private filterForOrganization = (program: Program): boolean => {
        return this.currentOrganizationId === program.organizationId;
    };

    private isProducer = (): boolean => this.routeData.organization.types.some((type) => type === OrganizationTypes.IS_PRODUCTEUR);

    private updateSingleProgramAfterAction(programData: Program) {
        const programIndex = this.routeData.tourPrograms.findIndex((program) => program.id === programData.id);
        const showProgramIndex = this.showPrograms.findIndex((program) => program.id === programData.id);
        if (programIndex !== -1 && showProgramIndex !== -1) {
            this.routeData.tourPrograms.splice(programIndex, 1, new Program(programData));
            this.showPrograms.splice(showProgramIndex, 1, new Program(programData));
        }
    }
}
