<form id="organisation-step-3" class="mb-4" nz-form *ngIf="formGroup" [formGroup]="formGroup" (ngSubmit)="doSubmit()">
    <nz-form-item class="d-block">
        <nz-form-label>{{ 'FORM.CACHET' | translate }}</nz-form-label>
        <br/>
        <nz-form-control>
            <div nz-row class="d-flex align-items-center">
                <div nz-col nzMd="2">{{ 'ENTRE' | translate }}</div>
                <div nz-col nzMd="4">
                    <nz-select *ngIf="formGroup.get('coutPlateauMin')" id="coutPlateauMin" formControlName="coutPlateauMin">
                        <nz-option *ngFor="let value of trayCostValues" [nzValue]="value" nzLabel="{{ value | currency: 'CAD':'symbol-narrow':'1.0-0' }}"></nz-option>
                    </nz-select>
                </div>
                <div nz-col nzMd="1" class="text-center">{{ 'ET' | translate }}</div>
                <div nz-col nzMd="4">
                    <nz-select *ngIf="formGroup.get('coutPlateauMax')" id="coutPlateauMax" formControlName="coutPlateauMax">
                        <nz-option *ngFor="let value of filteredTrayCost()" [nzValue]="value" nzLabel="{{ value | currency: 'CAD':'symbol-narrow':'1.0-0' }}"></nz-option>
                        <nz-option [nzValue]="20001" nzLabel="+$20,000"></nz-option>
                    </nz-select>
                </div>
                <div nz-col *ngIf="formGroup.controls['coutPlateauMin'].value || formGroup.controls['coutPlateauMax'].value" nzSpan="4" class="ml-4">
                    <a class="btn btn--small" (click)="resetCachet()">
                        {{ 'REINITIALISER' | translate }}
                    </a>
                </div>
            </div>
        </nz-form-control>
    </nz-form-item>

    <div nz-row>
        <label for="" class="ant-form-item-control">{{ 'FORM.DUREE-DE-SPECTACLE' | translate }}</label>
    </div>
    <div nz-row>
        <div nz-col nzMd="8" class="d-flex align-items-center">
            <div nz-col nzSpan="6">
                <nz-form-item *ngIf="formGroup.get('showDurationHour')">
                    <nz-form-control>
                        <input nz-input ="number" min="0" class="text-center" id="showDurationHour" formControlName="showDurationHour" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col class="text-center" nzSpan="4">
                <nz-form-item *ngIf="formGroup.get('showDurationHour')">
                    <nz-form-control>
                        <span>h</span>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col nzSpan="6">
                <nz-form-item *ngIf="formGroup.get('showDurationMinutes')">
                    <nz-form-control>
                        <input nz-input type="number" min="0" max="59" class="text-center" id="showDurationMinutes" formControlName="showDurationMinutes" />
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-col class="ml-2" nzSpan="4">
                <nz-form-item *ngIf="formGroup.get('showDurationMinutes')">
                    <nz-form-control>
                        <span>minutes</span>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
        <div nz-col nzMd="16">
            <div nz-row>
                <div nz-col nzMd="10">
                    <div class="d-inline-block pl-3">
                        <sp-checkbox [classList]="['pt-1']" *ngIf="formGroup.get('showHasEntracte')" formControlName="showHasEntracte">
                            <strong>{{ 'FORM.AVEC-ENTRACTE' | translate }}</strong>
                        </sp-checkbox>
                    </div>
                </div>
                <div nz-col nzMd="12" class="d-flex align-items-center">
                    <div nz-col nzSpan="6">
                        <nz-form-item *ngIf="formGroup.get('showEntracteDuration')">
                            <nz-form-control>
                                <input
                                    nz-input
                                    id="showEntracteDuration"
                                    formControlName="showEntracteDuration"
                                    class="text-center"
                                    type="number"
                                    min="0"
                                    [attr.disabled]="!formGroup.value.showHasEntracte"
                                />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col class="ml-2">
                        <nz-form-item *ngIf="formGroup.get('showEntracteDuration')">
                            <nz-form-control>
                                <span>minutes</span>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div nz-row>
        <label for="" class="ant-form-item-control">{{ 'FORM.TEMPS-DE-MONTAGE' | translate }}</label>
    </div>
    <div nz-row class="d-flex align-items-center">
        <div nz-col nzSpan="2">
            <nz-form-item *ngIf="formGroup.get('showMountingDurationHour')">
                <nz-form-control>
                    <input nz-input type="number" min="0" class="text-center" id="showMountingDurationHour" formControlName="showMountingDurationHour" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col class="text-center" nzSpan="1">
            <nz-form-item *ngIf="formGroup.get('showMountingDurationHour')">
                <nz-form-control>
                    <span>h</span>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="2">
            <nz-form-item *ngIf="formGroup.get('showMountingDurationMinutes')">
                <nz-form-control>
                    <input nz-input type="number" min="0" class="text-center" max="59" id="showMountingDurationMinutes" formControlName="showMountingDurationMinutes" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col class="ml-2" nzSpan="2">
            <nz-form-item *ngIf="formGroup.get('showMountingDurationMinutes')">
                <nz-form-control>
                    <span>minutes</span>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div nz-row>
        <label for="" class="ant-form-item-control">{{ 'FORM.TEMPS-DE-DEMONTAGE' | translate }}</label>
    </div>
    <div nz-row class="d-flex align-items-center">
        <div nz-col nzSpan="2">
            <nz-form-item *ngIf="formGroup.get('showDemountingDurationHour')">
                <nz-form-control>
                    <input nz-input type="number" min="0" class="text-center" id="showDemountingDurationHour" formControlName="showDemountingDurationHour" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col class="text-center" nzSpan="1">
            <nz-form-item>
                <nz-form-control>
                    <span>h</span>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="2">
            <nz-form-item>
                <nz-form-control *ngIf="formGroup.get('showDemountingDurationMinutes')">
                    <input nz-input type="number" min="0" class="text-center" max="59" id="showDemountingDurationMinutes" formControlName="showDemountingDurationMinutes" />
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col class="ml-2" nzSpan="2">
            <nz-form-item>
                <nz-form-control>
                    <span>minutes</span>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div nz-row>
        <label for="" class="ant-form-item-control">{{ 'FORM.PLATEAU' | translate }}</label>
    </div>
    <div nz-row class="d-flex align-items-center">
        <div nz-col nzSpan="3" class="mr-3">
            <nz-form-item *ngIf="formGroup.get('plateauWidth')">
                <nz-form-control>
                    <input nz-input id="plateauWidth" type="number" step="0.01" min="0" formControlName="plateauWidth" class="d-block" />
                    <span>{{ 'FORM.LARGEUR' | translate }}</span>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="3" class="mr-3">
            <nz-form-item *ngIf="formGroup.get('plateauDepth')">
                <nz-form-control>
                    <input nz-input id="plateauDepth" type="number" step="0.01" min="0" formControlName="plateauDepth" class="d-block" />
                    <span>{{ 'FORM.PROFONDEUR' | translate }}</span>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col nzSpan="3" class="mr-3">
            <nz-form-item *ngIf="formGroup.get('plateauHeight')">
                <nz-form-control>
                    <input nz-input id="plateauHeight" type="number" step="0.01" min="0" formControlName="plateauHeight" class="d-block" />
                    <span>{{ 'FORM.HAUTEUR' | translate }}</span>
                </nz-form-control>
            </nz-form-item>
        </div>
        <div nz-col class="mb-3" nzSpan="3">
            <nz-form-item>
                <nz-form-control>
                    <span>{{ 'FORM.METRES' | translate }}</span>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div nz-row>
        <div nz-col nzSpan="12">
            <div nz-row>
                <nz-form-item class="w-100" *ngIf="formGroup.get('urlTechSpecification')">
                    <nz-form-control>
                        <label>
                            {{ 'FORM.DEVIS-TECHNIQUE' | translate }}
                            <input nz-input id="urlTechSpecification" formControlName="urlTechSpecification" />
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-row>
                <nz-form-item *ngIf="formGroup.get('jaugeMax')">
                    <nz-form-control>
                        <label for="jaugeMax">
                            {{ 'FORM.JAUGE-MAXIMALE' | translate }}
                            <input nz-input id="jaugeMax" type="number" step="0.01" min="0" formControlName="jaugeMax" />
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-row>
                <nz-form-item *ngIf="formGroup.get('personOnRoad')">
                    <nz-form-control>
                        <label for="personOnRoad">
                            {{ 'FORM.PERSONNES-SUR-LA-ROUTE' | translate }}
                            <input nz-input id="personOnRoad" type="number" min="0" formControlName="personOnRoad" />
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
            <div nz-row>
                <nz-form-item *ngIf="formGroup.get('personsOnStage')">
                    <nz-form-control>
                        <label for="personsOnStage">
                            {{ 'FORM.PERSONNES-SUR-LA-SCENE' | translate }}
                            <input nz-input id="personsOnStage" type="number" min="0" formControlName="personsOnStage" />
                        </label>
                    </nz-form-control>
                </nz-form-item>
            </div>
        </div>
    </div>
    <ng-container *ngIf="displaySaveForLater()">
        <button *appPermission="1; objectOrganizationId: show.organizationId" [disabled]="isLoading || formGroup.invalid" class="btn btn--primary">
            {{ 'ENREGISTER-CONTINUER' | translate }}
        </button>
        <p *appPermission="1; objectOrganizationId: show.organizationId" class="d-inline-block ml-2">
            {{ 'OU' | translate }}
            <a *ngIf="!isLoading" (click)="doSubmit('simple')">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</a>
            <span *ngIf="isLoading">{{ 'ENREGISTER-TERMINER-PLUS-TARD' | translate }}</span>
        </p>
    </ng-container>
    <ng-container *ngIf="displaySubmit()">
        <button *appPermission="1; objectOrganizationId: show.organizationId" [disabled]="isLoading || formGroup.invalid" class="btn btn--primary">
            {{ 'ENREGISTER' | translate }}
        </button>
    </ng-container>
</form>
