<div class="gratuitie-row row align-items-center">
    <!-- Number Input -->

    <div class="number col-auto">
        <input nz-input type="number" [(ngModel)]="gratuitie.price" class="form-control w-100" />
    </div>

    <!-- Static Text -->

    <div class="text col-auto">
        <span>$ {{ 'POUR' | translate }}</span>
    </div>

    <!-- User Selector -->

    <div class="selector col">
        <nz-select
            nzMode="multiple"
            id="select-users"
            [nzTokenSeparators]="[',']"
            class="w-100"
            [(ngModel)]="gratuitie.users"
            nzServerSearch
            (nzOnSearch)="this.searchUserSubject.next($event)"
            (nzOpenChange)="onOpenChange($event)"
            [nzSuffixIcon]="isUsersMenuOpen ? 'up' : 'down'"
            [nzShowArrow]="true"
        >
            <nz-option *ngFor="let user of users" [nzLabel]="user.email" [nzValue]="user"></nz-option>
        </nz-select>
    </div>

    <!-- Delete Button -->

    <div class="col-auto">
        <button type="button" class="btn-square" (click)="doDelete()">
            <i class="icon-trash"></i>
        </button>
    </div>
</div>

<nz-divider></nz-divider>
