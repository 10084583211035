<ng-container *ngIf="isReady">

  <app-page-title [title]="meeting.getTranslatedProperty(lang, 'name')">
  </app-page-title>

  <div class="container pb-5">
    <div nz-row
         nzGutter="24">

      <!-- MENU -->
      <div nz-col
           nzMd="6"
           class="mb-4 mb-md-0">
        <div class="list-filters">
          <div class="list-filters__box">
            <sp-menu>
                <sp-menu-item  [selected]="openedTab == 'general'" [classList]="['errorCount']" [routerLink]="['../general'] | localize" [label]="'RENSEIGNEMENTS-GENERAUX' | translate"></sp-menu-item>
                <sp-menu-item [selected]="openedTab == 'activities'" [routerLink]="['../activities'] | localize" [label]="'ACTIVITIES' | translate"></sp-menu-item>
                <sp-menu-item [selected]="openedTab == 'stands'" [routerLink]="['../stands'] | localize" [label]="'STANDS' | translate"></sp-menu-item>
                <sp-menu-item [selected]="openedTab == 'showcases'" [routerLink]="['../showcases'] | localize" [label]="'SHOWCASES' | translate"></sp-menu-item>
                <sp-menu-item [selected]="openedTab == 'participants'" [routerLink]="['../participants'] | localize" [label]="'PARTICIPANTS' | translate"></sp-menu-item>
                <sp-menu-item [selected]="openedTab == 'products'" [routerLink]="['../products'] | localize" [label]="'PRODUCTS' | translate"></sp-menu-item>
                <sp-menu-item [selected]="openedTab == 'jury'" [routerLink]="['../jury'] | localize" [label]="'JURIES' | translate"></sp-menu-item>
            </sp-menu>
          </div>
        </div>
      </div>

      <div nz-col
           nzMd="18">
        <!-- GENERAL FORM -->
        <ng-container *ngIf="openedTab == 'general'">
          <h2 class="mb-4">{{ 'RENSEIGNEMENTS-GENERAUX' | translate}}</h2>
          <app-meeting-general-form [(meeting)]="meeting"></app-meeting-general-form>
        </ng-container>

        <!-- ACTIVITIES FORM -->
        <ng-container *ngIf="openedTab == 'activities'">
          <div class="d-flex"
               style="justify-content: space-between">
            <h2 class="mb-4">{{ 'ACTIVITIES' | translate}}</h2>
            <button nz-button
                    class="btn btn--default"
                    (click)="createActivity()">
              <i nz-icon
                 nzType="plus"
                 nzTheme="outline"
                 class="mr-1"
                 aria-hidden="true"></i>
              Ajouter une activité
            </button>
          </div>
          <app-activity-list [meetingId]="meetingId"
                             [enableEditing]="true"
                             [enabledDefaultfilters]="false"></app-activity-list>
        </ng-container>

        <!-- STANDS FORM -->
        <ng-container *ngIf="openedTab == 'stands'">
          <div nz-row
               nzGutter="24 ">

            <div nz-col
                 [nzMd]="20">
              <div nz-row
                   nzType="flex"
                   nzJustify="space-between">
                <h2 class="mb-4">{{ 'STANDS' | translate}}</h2>
                <div>
                  <app-stand-create [meetingId]="meetingId"
                                    [meetingOwnerOrgId]="meeting.organizationId"
                                    (reloadStandList)="reloadStandList.emit($event)"> </app-stand-create>
                </div>
              </div>
              <!-- STANDS LIST-->
              <div nz-row>
                <app-stand-list [meetingId]="meetingId"
                                [isAdminView]="true"
                                [meetingOwnerOrgId]="meeting.organizationId"
                                [reloadStandList]="reloadStandList"></app-stand-list>
              </div>
            </div>

            <!-- STAND IMAGE -->
            <div nz-col
                 [nzMd]="4"
                 class="">
              <ng-container *ngIf="meeting.standImageUrl; else noStandImg">
                <div class="avatar-upload ant-upload ant-upload-select ant-upload-select-picture-card">
                  <img *ngIf="meeting.standImageUrl"
                       [src]="meeting.standImageUrl"
                       class="avatar"
                       alt="">
                  <i class="avatar-upload__remove icon-trash"
                     (click)="removeImage()"></i>
                </div>
              </ng-container>
              <ng-template #noStandImg>
                <nz-upload class="avatar-uploader"
                           nzAction="{{ uploadAction }}"
                           nzName="meetingstand"
                           nzListType="picture-card"
                           [nzShowUploadList]="false"
                           [nzDisabled]="false"
                           [nzBeforeUpload]="beforeUpload"
                           (nzChange)="handleImageChange($event)">
                  <ng-container *ngIf="!logoUrl">
                    <i class="upload-icon"
                       nz-icon
                       [nzType]="loading ? 'loading' : 'plus'"
                       aria-hidden="true"></i>
                    <div class="ant-upload-text">{{ 'AJOUTER' | translate }}</div>
                  </ng-container>
                  <img *ngIf="logoUrl"
                       [src]="logoUrl"
                       class="avatar"
                       alt="">
                </nz-upload>
                <p class="image-info">{{'FORM.PHOTO-PROFILE-RULES' | translate}}</p>
              </ng-template>
            </div>
          </div>
        </ng-container>

        <!-- SHOWCASES SECTION -->
        <ng-container *ngIf="openedTab == 'showcases'">
          <div class="d-flex mb-4"
               style="justify-content: space-between">
            <h2>{{ 'SHOWCASES' | translate}}</h2>
            <button nz-button
                    *ngIf="isScenePro || isMeetingAdmin"
                    class="btn btn--default"
                    (click)="exportVitrine()">
              <i nz-icon
                 nzType="export"
                 nzTheme="outline"
                 class="mr-1"
                 aria-hidden="true"></i>
              {{'EXPORTER' | translate }}
            </button>
          </div>
          <app-vitrine-list [meetingId]="meetingId"
                            [enableFilters]="true"
                            [useViewSwitcher]="true"
                            [isAdminUsage]="true"></app-vitrine-list>
        </ng-container>

        <!-- PARTICIPANTS -->
        <ng-container *ngIf="openedTab == 'participants'">
          <div class="d-flex mb-4"
               style="justify-content: space-between">
            <h2>{{ 'PARTICIPANTS' | translate}}</h2>
            <button nz-button
                    *appPermission="5;objectOrganizationId:meeting.organizationId"
                    class="btn btn--default"
                    (click)="exportParticipants()">
              <i nz-icon
                 nzType="export"
                 nzTheme="outline"
                 class="mr-1"
                 aria-hidden="true"></i>
              {{'EXPORTER' | translate }}
            </button>
          </div>
          <app-meeting-participant [meeting]="meeting"></app-meeting-participant>
        </ng-container>

        <!-- PRODUCTS SECTION -->
        <ng-container *ngIf="openedTab == 'products'">
          <h2 class="mb-4">{{ 'PRODUCTS' | translate}}</h2>
          <app-meeting-product-list [meeting]="meeting"></app-meeting-product-list>
        </ng-container>

        <!-- JURY SECTION -->
        <ng-container *ngIf="openedTab == 'jury'">
          <app-meeting-jury [meetingId]="meetingId"></app-meeting-jury>
        </ng-container>
      </div>

    </div>
  </div>
</ng-container>
