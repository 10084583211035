<form id="profile-form" class="mb-4" *ngIf="networkInfosForm" nz-form [formGroup]="networkInfosForm" (ngSubmit)="saveFinance()">
    <div class="row">
        <div class="col-md-6">
            <app-nz-form-item inputId="'responsibleMember'" [control]="networkInfosForm.get('responsibleMember')">
                <div class="input-custom-label">
                    <label for="responsibleMember">{{ 'FORM.REPRESENTANT' | translate }}</label>
                </div>
            </app-nz-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <nz-form-item>
                <nz-form-control>
                    <label for="legalStatusId">{{ 'FORM.STATUT-LEGAL' | translate }}*</label>
                    <nz-select id="legalStatusId" style="width: 100%" formControlName="legalStatusId">
                        <nz-option *ngFor="let option of legalStatusOptions" [nzLabel]="option.label" [nzValue]="option.value"></nz-option>
                    </nz-select>
                    <span class="has-error" *ngIf="networkInfosForm.get('legalStatusId').errors">
                        {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                    </span>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>

    <h3 class="sub-title">{{ 'FORM.FINANCEMENT' | translate }}</h3>

    <div class="row mb-3">
        <div class="col-md-6">
            <sp-checkbox formControlName="hasCALQSupport">
                {{ 'FORM.SOUTIENT-CALQ' | translate }}
            </sp-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <nz-form-item>
                <nz-form-control class="money-input">
                  <label for="annualAmountCALQ">{{ 'FORM.MONTANT-ANNUEL' | translate }}</label>
                  <input nz-input
                         id="annualAmountCALQ"
                         name="annualAmountCALQ"
                         formControlName="annualAmountCALQ"
                         min="0"> $
                </nz-form-control>
              </nz-form-item>
        </div>
    </div>
    <div class="row mb-3">
        <div class="col-md-6">
            <sp-checkbox formControlName="hasFederalSupport">
                {{ 'FORM.SOUTIENT-FEDERAL' | translate }}
            </sp-checkbox>
        </div>
    </div>

    <div class="row mb-3">
        <div class="col-md-6">
            <sp-checkbox formControlName="hasMunicipalSupport">
                {{ 'FORM.SOUTIENT-MUNICIPAL' | translate }}
            </sp-checkbox>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <nz-form-item>
                <nz-form-control>
                    <label for="broadcastEmployeesNumber">{{ 'FORM.NB-EMPLOYES' | translate }}*</label>
                    <input nz-input id="broadcastEmployeesNumber" name="broadcastEmployeesNumber" formControlName="broadcastEmployeesNumber" type="number" min="0" />
                    <span class="has-error" *ngIf="networkInfosForm.get('broadcastEmployeesNumber').errors">
                        {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                    </span>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>

    <h3 class="sub-title">{{ 'FORM.ADHESION-INFOS' | translate }}</h3>

    <div class="row">
        <div class="col-md-6">
            <nz-form-item>
                <nz-form-control>
                    <label for="financialYearEnd">{{ 'FORM.FIN-ANNEE' | translate }}*</label>
                    <br />
                    <nz-date-picker nzAllowClear="true" nzPlaceHolder="yyyy/mm/dd" nzFormat="yyyy/MM/dd" formControlName="financialYearEnd" class="w-50"></nz-date-picker>
                    <span class="has-error" *ngIf="networkInfosForm.get('financialYearEnd').errors">
                        {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                    </span>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <nz-form-item>
                <nz-form-control class="money-input" [ngClass]="{'has-error': networkInfosForm.get('ticketsRevenue').invalid }">
                    <label for="ticketsRevenue">{{ 'FORM.REVENU-BILLETS' | translate }}*</label>
                    <input nz-input id="ticketsRevenue" name="ticketsRevenue" formControlName="ticketsRevenue" min="0" />
                    $
                    <span class="has-error" *ngIf="networkInfosForm.get('ticketsRevenue').hasError('required')">
                        {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                    </span>
                </nz-form-control>
                <p class="input-small-description">{{ 'FORM.REVENU-BILLETS-DESC' | translate }}</p>
            </nz-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <nz-form-item>
                <nz-form-control class="money-input" [ngClass]="{'has-error': networkInfosForm.get('artistsFees').invalid }">
                    <label for="artistsFees">{{ 'FORM.CACHETS' | translate }}*</label>
                    <input nz-input id="artistsFees" name="artistsFees" formControlName="artistsFees" min="0" />
                    $
                    <span class="has-error" *ngIf="networkInfosForm.get('artistsFees').hasError('required')">
                        {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                    </span>
                    <span class="has-error"  *ngIf="networkInfosForm.get('artistsFees').hasError('greaterThanZero')">
                      {{ 'CHAMP-SUPERIEUR-ZERO' | translate }}
                  </span>
                </nz-form-control>

            </nz-form-item>
        </div>
    </div>
    <div class="row">
        <div class="col-md-6">
            <nz-form-item >
                <nz-form-control [ngClass]="{'has-error': networkInfosForm.get('soldTicketsNumber').invalid }">
                    <label for="soldTicketsNumber">{{ 'FORM.BILLETS-VENDUS' | translate }}*</label>
                    <input nz-input id="soldTicketsNumber" name="soldTicketsNumber" formControlName="soldTicketsNumber" min="0" />
                    <span class="has-error" *ngIf="networkInfosForm.get('soldTicketsNumber').hasError('required')">
                        {{ 'CHAMP-REQUIS-SIMPLE' | translate }}
                    </span>
                    <span class="has-error" *ngIf="networkInfosForm.get('soldTicketsNumber').hasError('greaterThanZero')">
                      {{ 'CHAMP-SUPERIEUR-ZERO' | translate }}
                  </span>
                </nz-form-control>
            </nz-form-item>
        </div>
    </div>

    <button
        *appPermission="1; objectOrganizationId: organization.id; shouldBeApproved: true"
        nz-button
        [nzLoading]="isLoading"
        class="btn btn--default"
        [disabled]="networkInfosForm.invalid"
    >
        {{ 'ENREGISTER' | translate }}
    </button>
</form>
