import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { ISearchProgramCalendar } from '../../../model/program.model';
import { takeUntil, tap, finalize, map, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Observable, combineLatest, Subject, BehaviorSubject, of } from 'rxjs';
import { Organization } from 'src/app/concepts/organization/model/organization.model';
import { OrganizationTypes } from 'src/app/concepts/organization/enums/organization-types.enum';
import { BottinService } from '@app/concepts/organization/services/bottin.service';
import { ListItem, Pagination, SelectedFilter } from '@app/shared/model/list-item.model';
import { AccountService } from '@app/concepts/account/services/account.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganisationStatus } from '@app/concepts/organization/enums/organization-status.enum';
import { SessionStorageService } from '@app/shared/services/storage.service';
import { Globals } from '@app/_configs/globals';
import { ISessionStorageDiffusers, ProgramAdvancedSearchRequest, ProgramRouteData } from './models';

@Component({
    selector: 'app-program-advanced-search',
    templateUrl: './program-advanced-search.component.html',
    styleUrls: ['./program-advanced-search.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ProgramAdvancedSearchComponent implements OnInit, OnDestroy {
    @Output() onSearchChange = new EventEmitter<ISearchProgramCalendar>();
    selectedDiffusers: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([]);
    diffusers$: Observable<ListItem[]>;
    public formGroup = new UntypedFormGroup({
        show: new UntypedFormControl(undefined),
        artist: new UntypedFormControl(undefined),
        venue: new UntypedFormControl(undefined),
        producer: new UntypedFormControl(undefined),
        diffusers: new UntypedFormControl(undefined)
    });

    isProducer: boolean;
    isTourAdmin: boolean;
    isLoading: boolean;
    isDiffuserMenuOpen = false;
    private destroyed$ = new Subject<void>();
    private currentLanguage: string = this.translate.currentLang;
    private sessionStorageDiffusersKey = 'sp.diffusers';
    private sessionStorageDiffusersPaginationKey = 'sp.diffusers.pagination';
    private diffuserPagination: Pagination;
    private diffuserRequestFilters: SelectedFilter[] = [
        {
            field: 'organizationTypeId',
            value: OrganizationTypes.IS_DIFFUSEUR
        },
        {
            field: 'statusId',
            value: this.accountService.isScenePro ? '' : OrganisationStatus.APPROUVE
        }
    ];
    private get storedDiffusers(): ISessionStorageDiffusers | null {
        return this.sessionStorage.getItem<ISessionStorageDiffusers>(this.sessionStorageDiffusersKey);
    }

    constructor(
        private globals: Globals,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,
        private bottinService: BottinService,
        private accountService: AccountService,
        private sessionStorage: SessionStorageService
    ) {}

    ngOnInit(): void {
        this.initPagination();
        this.initFormGroupValueFromRouteParams();
        this.formValueChangedHandler();
        this.diffuserFormValueChangedHandler();
    }

    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
        if(this.storedDiffusers) {
            this.sessionStorage.setItem<ISessionStorageDiffusers>(this.sessionStorageDiffusersKey, {
                ...this.storedDiffusers,
                selected: null
            });
        }
    }

    public onSearchHandler(payload: ISearchProgramCalendar) {
        this.onSearchChange.emit(payload);
        this.storeSelectedDiffuserValues(this.selectedDiffusers.value);
    }

    private getCurrentOrganizationType = (routeData: ProgramRouteData): void => {
        this.isProducer = routeData.organization.types.some((type) => type === OrganizationTypes.IS_PRODUCTEUR);
        this.isTourAdmin = routeData.tourAdmin;
    };

    private getDiffusers = () => {
        if (this.storedDiffusers) {
            this.getDiffusersFromStorage();
            return;
        }
        this.getDiffusersFromService();
    };

    private storeSelectedDiffuserValues = (values: number[]): void => {
        if (!values || (values && !values.length)) {
            return;
        }
        if (!this.storedDiffusers) {
            return;
        }
        this.sessionStorage.setItem<ISessionStorageDiffusers>(this.sessionStorageDiffusersKey, {
            ...this.storedDiffusers,
            selected: values
        });
    };

    private initPagination(): void {
        /**
         * * https://logient.atlassian.net/browse/RIDEAU-1813
         * * If other fields become multiple select fields,
         * * the limit (500) should be be changed and an actual pagination system
         * * should be implemented
         */
        this.diffuserPagination = new Pagination(this.globals.pagination.offset, 500);
        const session = this.sessionStorage.getItem(this.sessionStorageDiffusersPaginationKey);
        if (session) {
            Object.assign(this.diffuserPagination, session);
        }
    }

    private getDiffusersFromStorage = (): void => {
        const { ts, list } = this.storedDiffusers;
        // Get the current timestamp (in milliseconds)
        const nowTimestamp = Date.now();
        // Calculate a timestamp that's 1 week (7 days) ahead
        const oneWeekAheadStoredTimestamp = ts + 7 * 24 * 60 * 60 * 1000;
        if (nowTimestamp < oneWeekAheadStoredTimestamp) {
            // export list to Observable consumed in template
            this.diffusers$ = of([...list]);
        }
    };

    private getDiffusersFromService = (): void => {
        this.diffusers$ = this.bottinService.getBottin(this.diffuserRequestFilters, this.diffuserPagination).pipe(
            // Show loading indicator
            tap(() => (this.isLoading = true)),
            map((organizationsData: Organization[]): ListItem[] => {
                // Transform organizations data into displayable list items
                const listItems = organizationsData.map((catalogItem) => {
                    // Convert an Organization to a ListItem
                    const res: ListItem = catalogItem.toListItem(this.currentLanguage);
                    res.itemId = catalogItem.id;
                    // Generate subtitle for the item
                    res.itemSubtitle = catalogItem.types
                        .reduce((acc, type) => {
                            return acc + this.translate.instant('ORGANIZATION-TYPE_' + type) + ' - ';
                        }, '')
                        .slice(0, -2);
                    // Set item status based on user role
                    if (!this.accountService.isScenePro) {
                        res.itemStatus = null;
                    }
                    return res;
                });
                // Store transformed list in sessionStorage
                this.sessionStorage.setItem<ISessionStorageDiffusers>(this.sessionStorageDiffusersKey, {
                    ts: Date.now(),
                    list: [...listItems]
                });
                return listItems;
            }),
            finalize(() => {
                // Finalize: hide loading indicator, update search with initial data
                this.isLoading = false;
            })
        );
    };

    private diffuserFormValueChangedHandler = (): void => {
        this.formGroup
            .get('diffusers')
            .valueChanges.pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
            .subscribe((v: number[]) => this.selectedDiffusers.next(v));
    };

    private formValueChangedHandler = (): void => {
        this.formGroup.valueChanges
            .pipe(
                debounceTime(750),
                map((values: ProgramAdvancedSearchRequest) => ({
                    ...values,
                    diffusers: values.diffusers.join(',')
                })),
                takeUntil(this.destroyed$)
            )
            .subscribe((values: ISearchProgramCalendar) => {
                this.onSearchHandler(values);
            });
    };

    private initFormGroupValueFromRouteParams = (): void => {
        combineLatest([this.activatedRoute.queryParams, this.activatedRoute.data as Observable<ProgramRouteData>])
            .pipe(
                map(([queryParams, routeData]: [Params, ProgramRouteData]) => {
                    this.getCurrentOrganizationType(routeData);
                    return queryParams;
                }),
                map((queryParams: Params) => {
                    const { show = '', artist = '', venue = '', producer = '', diffusers } = queryParams;
                    let diffuserArray: number[] = [];
                    if (diffusers) {
                        diffuserArray = diffusers.split(',').map((x: string) => Number(x));
                    }
                    this.storeSelectedDiffuserValues(diffuserArray);
                    this.formGroup.patchValue({ show, artist, venue, producer, diffusers: diffuserArray }, { emitEvent: false });
                    this.getDiffusers();
                }),
                takeUntil(this.destroyed$)
            )
            .subscribe();
    };

    onOpenChange(open: boolean): void {
        this.isDiffuserMenuOpen = open;
    }
}
