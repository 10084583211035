import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PxUser } from 'src/app/concepts/account/model/project-x-user';
import { Gratuitie } from '../../model/gratuities.model';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import {
  SelectedFilter,
  Pagination,
} from 'src/app/shared/model/list-item.model';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-gratuitie-row',
  templateUrl: './gratuitie-row.component.html',
  styleUrls: ['./gratuitie-row.component.scss'],
})
export class GratuitieRowComponent implements OnInit {
  @Input()
  gratuitie: Gratuitie;

  @Output()
  deleteGratuitie: EventEmitter<any> = new EventEmitter<any>();

  public users: PxUser[];
  searchUserSubject: Subject<string> = new Subject<string>();
  pagination: Pagination = new Pagination(0, 10);
  isUsersMenuOpen = false;

  constructor(private accountService: AccountService) {}

  ngOnInit(): void {
    this.gratuitie.users = this.gratuitie.users ? this.gratuitie.users : [];
    this.users = this.gratuitie.users;

    // first request to fill list;
    this.getUsers();

    this.searchUserSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe(this.getUsers.bind(this));
  }

  doDelete(): void {
    this.gratuitie.toDelete = 1;
    this.deleteGratuitie.emit();
  }

  onOpenChange(open: boolean): void {
    this.isUsersMenuOpen = open;
  }

  getUsers(text?: string) {
    const filters: SelectedFilter[] = [];
    if (text) {
      const filter = new SelectedFilter();
      filter.field = 'email';
      filter.value = text;
      filters.push(filter);
    }
    this.accountService
      .getAllUsers(filters, this.pagination)
      .subscribe((users) => {
        //enlever de la liste ceux qui y sont déjà
        const tmp_users = users.filter((user) => {
          return !this.gratuitie.users.find((g_user) => g_user.id === user.id);
        });
        this.users = [...this.gratuitie.users, ...tmp_users];
      });
  }
}
