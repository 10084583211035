<ng-container [ngTemplateOutlet]="routerLink ? routerLinkTpl : clicked ? clickableMenuItemTpl : defaultMenuItem"></ng-container>

<ng-template #routerLinkTpl>
    <li [ngClass]="classList" [routerLink]="routerLink" routerLinkActive="active">
        <ng-container *ngIf="label; else content">{{ label }}</ng-container>
        <ng-content select=".errorCount"></ng-content>
    </li>
</ng-template>
<ng-template #clickableMenuItemTpl>
    <li [ngClass]="classList" (click)="clickHandler()">
        <ng-container *ngIf="label; else content">{{ label }}</ng-container>
    </li>
</ng-template>
<ng-template #defaultMenuItem>
    <li [ngClass]="classList">
        <ng-container *ngIf="label; else content">{{ label }}</ng-container>
    </li>
</ng-template>
<ng-template #content><ng-content></ng-content></ng-template>
