<app-page-title title="{{ user.firstName }}"></app-page-title>
<div class="container mb-5">
    <div class="row">
        <div class="col-md-3">
            <div class="list-filters">
                <div class="list-filters__box">
                    <sp-menu>
                        <ng-container *ngFor="let route of accountRoutes">
                            <sp-menu-item [routerLink]="[route.link] | localize" [label]="route.label | translate"></sp-menu-item>
                        </ng-container>
                    </sp-menu>
                </div>
            </div>
        </div>
        <div class="col-md-9">
            <ng-container *ngIf="openedTab == 'profile'">
                <!--Profil Tab-->
                <form [formGroup]="validateForm" (ngSubmit)="submitForm()">
                    <div class="col-12">
                        <h2 class="mb-4">{{ 'PROFIL' | translate }}</h2>
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-12">
                                        <app-nz-form-item [label]="'FORM.FIRSTNAME' | translate" [control]="validateForm.get('firstName')" inputType="text"></app-nz-form-item>
                                    </div>
                                    <div class="col-12">
                                        <app-nz-form-item [label]="'FORM.LASTNAME' | translate" [control]="validateForm.get('lastName')" inputType="text"></app-nz-form-item>
                                    </div>
                                    <div class="col-12">
                                        <app-nz-form-item [label]="'FORM.ADRESSE-COURRIEL' | translate" [control]="validateForm.get('email')" inputType="text">
                                            <p class="has-error input-custom-error-msg" *ngIf="validateForm.get('email').errors">
                                                <span
                                                *ngIf="validateForm.get('email').errors['required']; else incorrectField">{{ 'CHAMP-REQUIS' | translate }}</span>
                                                    <ng-template #incorrectField>{{'ERRORS.EMAIL-FORMAT' | translate}}</ng-template>
                                            </p>
                                        </app-nz-form-item>
                                        <p class="warning-text" *ngIf="newEmailRequested">{{ 'FORM.NEW-EMAIL-REQUEST' | translate }}</p>
                                    </div>

                                    <div class="col-12" *ngIf="showEmailPasswordField">
                                        <app-nz-form-item [label]="'Password'" [control]="validateForm.get('password')" inputType="password">
                                            <p class="has-error input-custom-error-msg" *ngIf="validateForm.get('password').errors">
                                                <span *ngIf="validateForm.get('password').errors['required']">{{ 'CHAMP-REQUIS-SIMPLE' | translate }}</span>
                                            </p>
                                        </app-nz-form-item>
                                    </div>

                                    <div class="col-12">
                                        <div class="row align-items-center">
                                            <div class="col-md-6">
                                                <app-nz-form-item [label]="'FORM.TELEPHONE' | translate" [control]="validateForm.get('phone')" inputType="text"></app-nz-form-item>
                                            </div>
                                            <div class="col-md-6 mt-3">
                                                <app-input-phone-extension formControlName="phonePostNumber"></app-input-phone-extension>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!showEmailPasswordField">
                                        <div *ngIf="!showPasswordForm; else passwordFormTemplate" class="col-12 mb-4">
                                            <label class="d-block">{{ 'MOT-DE-PASSE' | translate }}</label>
                                            <button class="d-block account_password_btn mt-2" nz-button type="button" (click)="showPasswordForm = true">
                                                {{ 'CHANGE-PASSWORD' | translate }}
                                            </button>
                                        </div>

                                        <ng-template #passwordFormTemplate>
                                            <form [formGroup]="passwordForm">
                                                <div class="col-12">
                                                    <nz-form-item>
                                                        <app-password-input
                                                            formControlName="currentPassword"
                                                            label="FORM.MOT-DE-PASSE-ACTUEL"
                                                            labelColor="#000"
                                                        ></app-password-input>
                                                    </nz-form-item>
                                                </div>
                                                <div class="col-12">
                                                    <nz-form-item>
                                                        <app-password-input formControlName="newPassword" label="FORM.NOUVEAU-MOT-DE-PASSE" labelColor="#000"></app-password-input>
                                                    </nz-form-item>
                                                    <app-password-hint-validator
                                                        textColor="black"
                                                        [isValid]="passwordForm.controls.newPassword.valid"
                                                        sentence="{{ 'PASSWORD-REGEX' | translate }}"
                                                    ></app-password-hint-validator>
                                                </div>

                                                <div class="col-12">
                                                    <nz-form-item>
                                                        <app-password-input
                                                            formControlName="confirmPassword"
                                                            label="FORM.CONFIRMER-MOT-DE-PASSE"
                                                            labelColor="#000"
                                                        ></app-password-input>
                                                    </nz-form-item>
                                                </div>
                                            </form>
                                        </ng-template>
                                    </div>
                                    <div class="col-md-12">
                                        <sp-checkbox formControlName="emailsOption">
                                            <strong>{{ 'NOTIFICATIONS.OPTION' | translate }}</strong>
                                        </sp-checkbox>
                                    </div>

                                    <div class="col-12">
                                        <button class="btn btn--small mr-2 d-inline-block" nzSize="large" style="margin-top: 20px"
                                         [disabled]="validateForm.invalid"
                                        >{{ 'ENREGISTER' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 pt-2">
                                <h4>{{ 'FORM.PHOTO-DE-PROFIL' | translate }}</h4>
                                <app-img-upload
                                    [imageTitle]="'FORM.PHOTO-DE-PROFIL' | translate"
                                    [maxCropperWidth]="imageConfig.maxWidth"
                                    [maxCropperHeight]="imageConfig.maxHeight"
                                    [currentImage]="user.avatar"
                                    [uploadAction]="uploadAction"
                                    uploadActionName="avatar"
                                    [maxFileWeigth]="1000"
                                    (onUploadFile)="onUploadFileCallback($event)"
                                ></app-img-upload>
                            </div>
                        </div>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="openedTab == 'organizations'">
                <!--Organization Tab-->
                <form nz-form [nzLayout]="vertical" [formGroup]="validateForm" (ngSubmit)="submitForm()">
                    <div class="col-md-12">
                        <div class="row mb-4">
                            <div class="col-md-7">
                                <h2 class="mb-0">{{ 'ORGANISATIONS' | translate }}</h2>
                            </div>
                            <div class="col-md-5 d-flex justify-content-md-end align-items-center">
                                <button class="btn btn--small mr-2 d-inline-block" (click)="createOrganizationHandler()" type="button">
                                    <i nz-icon aria-hidden="true" [nzType]="'plus'" style="padding-right: 8px"></i>
                                    {{ 'NOUVELLE-ORGANISATION' | translate }}
                                </button>
                            </div>
                        </div>
                        <div>
                            <nz-table class="table table--neutral" #orgaTable [nzData]="userOrganizations$ | async">
                                <tr class="table__row" *ngFor="let data of orgaTable.data">
                                    <td class="table__avatar-td">
                                        <span class="table__avatar-container">
                                            <img class="table__avatar" src="{{ data.logo }}" alt="" />
                                        </span>
                                    </td>
                                    <td class="table__textContent">
                                        <p class="table__title">{{ data.getTranslatedProperty(currentLang, 'name') }}</p>
                                        <p class="table__type">{{ data.email }}</p>
                                    </td>
                                    <td>
                                        <span *ngIf="data.statusId === 1">
                                            <i nz-icon aria-hidden="true" [nzType]="'minus-circle'" style="color: blue"></i>
                                            {{ 'STATUS.NON_SOUMIS' | translate }}
                                        </span>
                                        <span *ngIf="data.statusId === 2">
                                            <i nz-icon aria-hidden="true" [nzType]="'question-circle'" style="color: orange"></i>
                                            {{ 'STATUS.SOUMIS' | translate }}
                                        </span>
                                        <span *ngIf="data.statusId === 3">
                                            <i nz-icon aria-hidden="true" [nzType]="'check-circle'" style="color: green"></i>
                                            {{ 'STATUS.APPROUVE' | translate }}
                                        </span>
                                        <span *ngIf="data.statusId === 4">
                                            <i nz-icon aria-hidden="true" [nzType]="'close-circle'" style="color: red"></i>
                                            {{ 'STATUS.REJETE' | translate }}
                                        </span>
                                    </td>
                                    <td class="text-right">
                                        <button class="btn-rect mr-2 d-inline-block" (click)="selectOrganization($event, data.id)">{{ 'FORM.MODIFIER' | translate }}</button>
                                        <span nz-tooltip>
                                            <button
                                                class="btn btn--small mr-2 d-inline-block"
                                                [disabled]="!getCanLeaveOrg(data.id)"
                                                onclick="event.preventDefault();"
                                                (click)="leaveOrganization(data)"
                                                style="padding-top: 9px"
                                            >
                                                {{ 'FORM.QUITTER' | translate }}
                                            </button>
                                        </span>
                                    </td>
                                </tr>
                            </nz-table>
                        </div>
                    </div>
                </form>
            </ng-container>

            <!-- FACTURATION -->
            <ng-container *ngIf="openedTab == 'billing'">
                <app-account-billing [displayCreditCardSection]="false"></app-account-billing>
            </ng-container>
        </div>
    </div>
</div>
