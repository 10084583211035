<!--Team & admin Tab-->
<div nz-row>
  <div nz-col
       nzMd="16">
    <h2>{{ 'ORGANISATIONS-MEMBRES' | translate}}</h2>
  </div>
  <div class="text-md-right"
       nz-col
       nzMd="8">
    <button type="button"
            *appPermission="permissions.CAN_MANAGE;objectOrganizationId : organization.id"
            class="btn"
            [disabled]="checkedCollapse"
            (click)="toogleCollapse()"
            nzType="default"
            style="padding:7px 22px 6px 19px">
      <i class="icon-plus icon-for-btn"></i>
      {{'AJOUTER-ORGANISATION' | translate}}
    </button>
  </div>
</div>

<div class="d-flex align-items-center"
     nz-row
     nzType='flex'
     nzJustify='space-between'>

  <!-- CREATE MEMBER FORM -->
  <ng-container *ngIf="checkedCollapse">
    <form class="w-100"
          nz-form
          (ngSubmit)="addNewMember()">
      <div class="form-filter"
           *ngIf="!invitationSent">
        <div nz-row
             nzGutter="24"
             class="form-filter__container p-3">
          <div nz-col
               nzMd="12">
            <nz-form-item>
              <nz-form-control>
                <label for="email"
                       class="repertory-filter__label d-block">{{'ORGANISATIONS' | translate}}</label>
                <nz-select name="organization.id"
                           nzShowSearch
                           nzAllowClear
                           class="organization-select"
                           [(ngModel)]="orgaToAddId">
                  <nz-option *ngFor="let org of organizationList"
                             [nzLabel]="org.getTranslatedProperty(lang,'name')"
                             [nzValue]="org.id"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
        <div class="form-filter__footer text-md-right p-3"
             style="display:flex; justify-content: flex-end">
          <button class="btn-rect px-3 d-inline-block mr-md-3"
                  style="padding-top:7px"
                  (click)="toogleCollapse()">{{ 'ANNULER' | translate }}</button>
          <button class="btn btn--small d-inline-block"
                  nz-button
                  nzSize="large"
                  [disabled]="!orgaToAddId || isAdding"
                  nzType="default">{{ 'AJOUTER' | translate }}</button>
        </div>
      </div>

      <div class="form-filter"
           *ngIf="invitationSent">
        <div nz-row
             nzGutter="24"
             class="form-filter__container p-3">
          <div nz-col
               class="invitation-sent-message">
            <p>{{'INVITATION-ENVOYEE' | translate}}</p>
          </div>
        </div>
      </div>
    </form>

  </ng-container>
</div>


<nz-divider></nz-divider>
<!-- MEMBER LIST  -->
<ng-container *ngIf="networkMembers.length > 0">
  <div nz-row
       class="d-flex align-items-center mb-3">
    <div nz-col
         nzMd="12">
      <h3 class="m-md-0 mr-3">{{ 'ORGANISATIONS' | translate}}</h3>
    </div>
    <div nz-col
         nzMd="12">
      <!-- <nz-input-group [nzSuffix]="suffixIconSearch">
        <input type="text"
               nz-input
               placeholder="{{'TROUVER-UNE-ORGANISATION' | translate}}">
      </nz-input-group> -->
      <ng-template #suffixIconSearch>
        <i nz-icon
           type="search"
           aria-hidden="true"></i>
      </ng-template>
    </div>
  </div>
  <div class="table">
    <ng-container *ngFor="let member of networkMembers">
      <div class="item-row item-row--light">
        <div class="item-row__col item-row__col--avatar item-row__avatar">
          <span class="item-row__avatar-container">
            <img class="item-row__avatar-src"
                 src="{{member.logo}}" alt="">
          </span>
        </div>
        <div class="item-row__col clickable"
             (click)="goToOrga(member.id)">
          <div class="item-row__text item-row__text--title d-inline-block">
            {{ member.getTranslatedProperty(lang,'name') }}
          </div>
        </div>
        <div class="item-row__col item-row__col--roles text-md-right item-row__col--auto pr-3">
          <div class="d-flex mb-3 my-3">
            <button type="button"
                    *appPermission="permissions.CAN_MANAGE"
                    class="btn-rect"
                    (click)="removeOrganizationMember(member.id)">{{'RETIRER' | translate}}</button>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <nz-divider></nz-divider>
</ng-container>
