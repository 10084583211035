import { Globals } from '../../../../../_configs/globals';
import { Component, OnInit } from '@angular/core';
import { AccountService } from 'src/app/concepts/account/services/account.service';
import { AbstractVenueFormComponent } from '../venue-abstract-form/venue-abstract-form.component';
import { BooleanUtils } from 'src/app/shared/utils/boolean-utils';
import { ValidatorFn, Validators } from '@angular/forms';
@Component({
    selector: 'app-venue-residency-form',
    templateUrl: './venue-residency-form.component.html',
    styleUrls: [ './venue-residency-form.component.scss' ]
})
export class VenueResidencyFormComponent extends AbstractVenueFormComponent implements OnInit {
    constructor(protected globals: Globals, protected accountService: AccountService) {
        super(accountService, globals);
    }

    ngOnInit(): void {
        this.initForm();
        this.switchResidencyStatusValidation();
    }

    protected initForm(): void {
        // init du formulaire
        this.formGroup = this.venue.getResidencyFormGroup();
    }

    protected switchResidencyStatusValidation(): void {
        if (!this.venueValue.isResidency) {
            this.setValidators('totalStageWidth');
            this.setValidators('totalSceneDepth');
        } else {
            this.setValidators('totalStageWidth', [ Validators.required ]);
            this.setValidators('totalSceneDepth', [ Validators.required ]);
        }
    }

    switchResidencyStatus(status: boolean): void {
        this.venueValue.isResidency = BooleanUtils.convertToNumber(status);
        this.switchResidencyStatusValidation();
    }

    public isFormGroupInvalid(formGroupName: string): boolean {
        return this.formGroup[ 'controls' ][ formGroupName ].invalid;
    }

    doSubmit(param?: any): void {
        this.venueValue.isTypeCreation = BooleanUtils.convertToNumber(this.getFormControl('isTypeCreation', 'type').value);
        this.venueValue.isTypeTechnical = BooleanUtils.convertToNumber(this.getFormControl('isTypeTechnical', 'type').value);
        this.venueValue.isTypeDried = BooleanUtils.convertToNumber(this.getFormControl('isTypeDried', 'type').value);
        this.venueValue.isDisponibilityFall = BooleanUtils.convertToNumber(this.getFormControl('isDisponibilityFall', 'availability').value);
        this.venueValue.isDisponibilitySummer = BooleanUtils.convertToNumber(this.getFormControl('isDisponibilitySummer', 'availability').value);
        this.venueValue.isDisponibilitySpring = BooleanUtils.convertToNumber(this.getFormControl('isDisponibilitySpring', 'availability').value);
        this.venueValue.isDisponibilityWinter = BooleanUtils.convertToNumber(this.getFormControl('isDisponibilityWinter', 'availability').value);
        this.venueValue.isDurationDay = BooleanUtils.convertToNumber(this.getFormControl('isDurationDay', 'duration').value);
        this.venueValue.isDurationWeek = BooleanUtils.convertToNumber(this.getFormControl('isDurationWeek', 'duration').value);
        this.venueValue.isDurationMonth = BooleanUtils.convertToNumber(this.getFormControl('isDurationMonth', 'duration').value);
        this.venueValue.hasReceptionStaff = BooleanUtils.convertToNumber(this.getFormControl('hasReceptionStaff').value);
        this.venueValue.hasAssemblyStaff = BooleanUtils.convertToNumber(this.getFormControl('hasAssemblyStaff').value);
        this.venueValue.hasTechnicalDirector = BooleanUtils.convertToNumber(this.getFormControl('hasTechnicalDirector').value);
        this.venueValue.hasSoundEngineer = BooleanUtils.convertToNumber(this.getFormControl('hasSoundEngineer').value);
        this.venueValue.hasLightingDesigner = BooleanUtils.convertToNumber(this.getFormControl('hasLightingDesigner').value);
        this.venueValue.hasStageTechnician = BooleanUtils.convertToNumber(this.getFormControl('hasStageTechnician').value);
        this.venueValue.hasRigger = BooleanUtils.convertToNumber(this.getFormControl('hasRigger').value);
        this.venueValue.totalStageWidth = this.parseFloatNumber(this.getFormControl('totalStageWidth').value);
        this.venueValue.totalSceneDepth = this.parseFloatNumber(this.getFormControl('totalSceneDepth').value);
        this.venueValue.clearHeightLamps = this.parseFloatNumber(this.getFormControl('clearHeightLamps').value);
        this.venueValue.widthPlayground = this.parseFloatNumber(this.getFormControl('widthPlayground').value);
        this.venueValue.playgroundDepth = this.parseFloatNumber(this.getFormControl('playgroundDepth').value);
        this.venueValue.hasAttachmentPoints = BooleanUtils.convertToNumber(this.getFormControl('hasAttachmentPoints').value);
        this.venueValue.hasDanceFloor = BooleanUtils.convertToNumber(this.getFormControl('hasDanceFloor').value);
        this.venueValue.hasVideoProjector = BooleanUtils.convertToNumber(this.getFormControl('hasVideoProjector').value);
        this.venueValue.hasSoundEquipment = BooleanUtils.convertToNumber(this.getFormControl('hasSoundEquipment').value);
        this.venueValue.hasLightEquipment = BooleanUtils.convertToNumber(this.getFormControl('hasLightEquipment').value);
        this.venueValue.hasInternet = BooleanUtils.convertToNumber(this.getFormControl('hasInternet').value);
        this.venueValue.hasTelepresenceEquipment = BooleanUtils.convertToNumber(this.getFormControl('hasTelepresenceEquipment').value);
        this.venueValue.hasLodge = BooleanUtils.convertToNumber(this.getFormControl('hasLodge').value);
        this.venueValue.hasStorageSpace = BooleanUtils.convertToNumber(this.getFormControl('hasStorageSpace').value);
        this.venueValue.hasKitchen = BooleanUtils.convertToNumber(this.getFormControl('hasKitchen').value);
        this.venueValue.hasLaundryRoom = BooleanUtils.convertToNumber(this.getFormControl('hasLaundryRoom').value);
        this.venueValue.hasHostingPartnership = BooleanUtils.convertToNumber(this.getFormControl('hasHostingPartnership').value);
        this.venueValue.hasLandingStage = BooleanUtils.convertToNumber(this.getFormControl('hasLandingStage').value);
        super.doSubmit(param);
    }

    private setValidators = (formControlName: string, validators?: ValidatorFn[]): void => {
        this.formGroup.get(formControlName).setValidators(validators || null);
        this.formGroup.get(formControlName).setErrors(null);
    };

    private parseFloatNumber = (value: number | string): number => {
        const regExp = new RegExp(/(,)/, 'gm');
        if (typeof value === 'string') {
            return (regExp.test(value) && parseFloat(value.replace(regExp, '.'))) || parseFloat(value);
        }
        return value;
    };
}
