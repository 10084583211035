import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LocalizeRouterModule } from '@gilsdav/ngx-translate-router';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgZorroModule } from '../ng-zorro.module';
import { BadgeComponent } from './components/badge/badge.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { InputMeterComponent } from './components/input-meter-label/input-meter.component';
import { DownloadPhotoLightboxComponent } from './components/download-photo-lightbox/download-photo-lightbox.component';
import { ItemCardComponent } from './components/item-card/item-card.component';
import { ItemListComponent } from './components/item-list/item-list.component';
// tslint:disable-next-line: max-line-length
import { ItemRowBillingSubscriptionComponent } from './components/item-rows/item-row-billing-subscription/item-row-billing-subscription.component';
import { ItemRowBillingComponent } from './components/item-rows/item-row-billing/item-row-billing.component';
import { ItemRowContactComponent } from './components/item-rows/item-row-contact/item-row-contact.component';
import { ItemRowFileComponent } from './components/item-rows/item-row-file/item-row-file.component';
import { ItemRowMemberComponent } from './components/item-rows/item-row-member/item-row-member.component';
import { ItemRowComponent } from './components/item-rows/item-row/item-row.component';
import { ItemUploadRepeaterComponent } from './components/item-upload-repeater/item-upload-repeater.component';
import { BreadcrumbComponent } from './components/layout/breadcrumb/breadcrumb.component';
import { MainNavigationComponent } from './components/layout/main-navigation/main-navigation.component';
import { PageTitleComponent } from './components/layout/page-title/page-title.component';
import { SectionTitleComponent } from './components/layout/section-title/section-title.component';
import { NewCreditCardComponent } from './components/new-credit-card/new-credit-card.component';
import { AudioPlayerComponent } from './components/players/audio-player/audio-player.component';
import { VideoPlayerComponent } from './components/players/video-player/video-player.component';
import { BillComponent } from './components/prints/bill/bill.component';
import { SelectCreditCardComponent } from './components/select-credit-card/select-credit-card.component';
import { SocialLinkComponent } from './components/social-link/social-link.component';
import { UserInfosComponent } from './components/user-infos/user-infos.component';
import { ExternalLink } from './pipes/external-link.pipe';
import { PaidBillComponent } from './components/prints/paid-bill/paid-bill.component';
import { UnpaidBillComponent } from './components/prints/unpaid-bill/unpaid-bill.component';
import { ItemRowPromoComponent } from './components/item-rows/item-row-promo/item-row-promo.component';
import { ItemRowParticipantComponent } from './components/item-rows/item-row-participant/item-row-participant.component';
import { EtiquettesMiniComponent } from './components/etiquettes/etiquettes-mini/etiquettes-mini.component';
import { EtiquettesLargeComponent } from './components/etiquettes/etiquettes-large/etiquettes-large.component';
import { NetworkCardComponent } from './components/network-card/network-card.component';
import { DepotButtonComponent } from './components/depot-button/depot-button.component';
import { ImgUploadComponent } from './components/img-upload/img-upload.component';
import { TimeagoModule } from 'ngx-timeago';
import { ExportDropdownComponent } from './components/export-dropdown/export-dropdown.component';
import { RichTextComponent } from './components/rich-text/rich-text.component';
import { QuillModule } from 'ngx-quill';
import { TextInputComponent } from './components/text-input/text-input.component';
import { RowSelectComponent } from './components/row-select/row-select.component';
import { RowMultipleSelectComponent } from './components/row-select/row-multiple-select.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { SelectComponent } from './components/select/select.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ButtonConnectionComponent } from './components/button-connection/button-connection.component';
import { ConnectionContainerComponent } from './components/connection-container/connection-container.component';
import { ModalComponent } from './components/modal/modal.component';
import { InputPhoneExtensionComponent } from './components/input-phone-extension/input-phone-extension.component';
import { ItemCardBannerComponent } from './components/item-card/item-card-banner/item-card-banner.component';
import { PasswordHintValidatorComponent } from './components/password-hint-validator/password-hint-validator.component';
import { PasswordInputComponent } from './components/password-input/password-input.component';
import { DynamicSearchBarComponent } from './components/dynamic-search-bar/dynamic-search-bar.component';
import { RowItemActionComponent } from './components/row-item-action/row-item-action.component';
import { LoadingIndicationComponent } from './components/loading-indication/loading-indication.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import { ShowIdentificationComponent } from './components/show-identification/show-identification.component';
import { ShowModalitiesComponent } from './components/show-modalities/show-modalities.component';
import { AlertComponent } from './components/alert/alert.component';
import { FormErrorComponent } from './components/form-error/form-error.component';
import { DirectivesModule } from './directives/directives.module';
import { PipesModule } from './pipes/pipes.modules';
import { ComponentsModule } from './components/components.modules';
import { NzDatePickerComponent } from './components/nz-date-picker/nz-date-picker.component';
import { SpQuillEditorComponent } from './components/sp-quill-editor/sp-quill-editor.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { NzCheckboxComponent } from './components/nz-checkbox/nz-checkbox.component';
import { NzFormItemComponent } from './components/nz-form-item/nz-form-item.component';
import { CustomFormComponent } from './components/custom-form/custom-form.component';
import { ListComponent } from './components/list/list.component';
import { ListItemComponent } from './components/list/list-item/list-item.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { NzListModule } from 'ng-zorro-antd/list';
import { SpSimpleSelectComponent } from './components/sp-selesct/sp-simple-select.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
const components = [
    ItemCardComponent,
    ItemRowComponent,
    ItemRowMemberComponent,
    ItemRowContactComponent,
    ItemRowFileComponent,
    ItemRowBillingComponent,
    ItemRowBillingSubscriptionComponent,
    ItemRowPromoComponent,
    ItemUploadRepeaterComponent,
    MainNavigationComponent,
    PageTitleComponent,
    SectionTitleComponent,
    SocialLinkComponent,
    BreadcrumbComponent,
    BadgeComponent,
    ItemListComponent,
    ExternalLink,
    DownloadPhotoLightboxComponent,
    NewCreditCardComponent,
    SelectCreditCardComponent,
    BillComponent,
    ContactFormComponent,
    UserInfosComponent,
    VideoPlayerComponent,
    AudioPlayerComponent,
    PaidBillComponent,
    UnpaidBillComponent,
    ItemRowParticipantComponent,
    NetworkCardComponent,
    DepotButtonComponent,
    EtiquettesMiniComponent,
    EtiquettesLargeComponent,
    ImgUploadComponent,
    RichTextComponent,
    TextInputComponent,
    ExportDropdownComponent,
    RowSelectComponent,
    RowMultipleSelectComponent,
    TimePickerComponent,
    DatePickerComponent,
    SelectComponent,
    CalendarComponent,
    ButtonConnectionComponent,
    ConnectionContainerComponent,
    InputPhoneExtensionComponent,
    ModalComponent,
    ItemCardBannerComponent,
    PasswordHintValidatorComponent,
    PasswordInputComponent,
    NzDatePickerComponent,
    DynamicSearchBarComponent,
    RowItemActionComponent,
    LoadingIndicationComponent,
    OverlayComponent,
    ItemCardBannerComponent,
    ShowIdentificationComponent,
    ShowModalitiesComponent,
    AlertComponent,
    InputMeterComponent,
    FormErrorComponent,
    CustomFormComponent,
    NzFormItemComponent,
    NzCheckboxComponent,
    RadioGroupComponent,
    SpSimpleSelectComponent,
    SpQuillEditorComponent,
    ListComponent,
    ListItemComponent,
    RadioButtonComponent
];
const modules = [DirectivesModule, PipesModule, ComponentsModule, NgZorroModule];
@NgModule({
    declarations: [...components],

    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NzCalendarModule,
        NzModalModule,
        TranslateModule,
        RouterModule,
        LocalizeRouterModule,
        QuillModule.forRoot(),
        NzListModule,
        ImageCropperComponent,
        ...modules
    ],
    exports: [TimeagoModule, NzDropDownModule, ...modules, ...components],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
