<div nz-row class="mb-3">
    <form class="w-50" [formGroup]="formGroup">
        <nz-input-group class="d-flex align-items-center w-100 stand-search" [nzPrefix]="prefixIconSearch">
            <input type="text" formControlName="keyword" nz-input class="w-100 stand-search__input" placeholder="{{ 'RECHERCHER' | translate }}" />
        </nz-input-group>
    </form>
    <ng-template #prefixIconSearch>
        <i nz-icon nzType="search" aria-hidden="true"></i>
    </ng-template>
</div>

<div nz-row *ngIf="isAdminView; else userStandList" class="d-block row stand-list">
    <ng-container *ngFor="let standItem of standList$ | async">
        <app-item-row
            [item]="standItem"
            class="mb-1"
            [enableEditing]="true"
            [enableRemoving]="true"
            [hideThumbnails]="true"
            (modifyItem)="modifyStand($event)"
            (removeItem)="removeStand($event)"
        ></app-item-row>
    </ng-container>
</div>

<ng-template #userStandList>
    <table class="stand-table" role="none">
        <thead>
            <tr>
                <td>{{ 'STANDS' | translate }}</td>
                <td>{{ 'NOM' | translate }}</td>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let standItem of standList$ | async">
                <tr>
                    <td>{{ standItem.itemTitle }}</td>
                    <td>
                        <ng-container *ngIf="standItem.itemUrl; else noUrl">
                            <a [routerLink]="standItem.itemUrl">{{ standItem.itemInfo1 | translate }}</a>
                        </ng-container>
                        <ng-template #noUrl>
                            <span>{{ standItem.itemInfo1 | translate }}</span>
                        </ng-template>
                    </td>
                </tr>
            </ng-container>
        </tbody>
    </table>
</ng-template>
