<nz-modal [(nzVisible)]="showModal" (nzOnCancel)="onCancelModal()">
  <div *nzModalContent class="app-modal-body">
      <div class="app-modal-body__title">
          <span *ngIf="type==='warning'" class="app-modal-body__title__warningIconContainer">
            <i class="icon-warning app-modal-body__title__warningIcon"></i>
          </span>
        <div class="app-modal-body__title__text">
          <h4>
            {{modalTitle}}
          </h4>
        </div>
      </div>
      <div class="app-modal-body__content">
        <h5>
          {{modalContent}}
        </h5>
      </div>
  </div>
  <div *nzModalFooter class="modal-footer">
    <button class="btn-rect btn--large" (click)="onCancelModal()">{{cancelModalFooterText}}</button>
    <button class="btn btn--large" (click)="onValidateModal()">{{validateModalFooterText}}</button>
  </div>
</nz-modal>
