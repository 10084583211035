<div class="sidenav-container">
    <app-section-title title="{{ 'MES-SPECTACLES' | translate }}"
        buttonText="{{ 'NOUVELLE-OFFRE-SPECTACLE' | translate }}" (buttonClicked)="newShow()" [permission]="1"
        [orgaType]="1" [buttonIsLoading]="isDisabled"></app-section-title>

    <section class="sidenav-container__content">
        <div nz-row class="sidenav-container__list">
            <sp-list class="w-100">
                <!-- LOADING SPINNER -->
                <div *ngIf="isLoading" class="loader">
                    <i nz-icon aria-hidden="true" nzType="loading" nzTheme="outline"></i>
                </div>

                <!-- LIST -->
                <sp-list-item *ngFor="let show of showList" class="show-list-item">

                    <div nz-col nzSpan="6">
                        <div>
                            <a
                                routerLink="{{ '/shows-offers/' + show.organizationId + '/' + show.id + '/modify/' | localize }}">
                                <div class="sidebar-image-container">
                                    <img *ngIf="show.image" class="w-100" style="object-fit: cover; height: 69px"
                                        src="{{ show.image }}" alt="" />
                                    <img *ngIf="!show.image" class="w-100" style="object-fit: cover; height: 69px"
                                        src="{{ imgFallback }}" alt="" />
                                </div>
                            </a>
                        </div>
                    </div>

                    <div class="myShow-info">
                        <div nz-col nzSpan="14">
                            <div class="show-title">
                                <div style="display: contents">
                                    <a class="sidenav-container__content__links"
                                        [routerLink]="'/shows-offers/' + show.organizationId + '/' + show.id + '/modify/identification' | localize">
                                        {{ show.getTranslatedProperty(lang, 'title') }}
                                    </a>
                                    <sub class="sidenav-container__content__sub">
                                        {{ show | extractArtistListPipeable }}
                                    </sub>
                                </div>
                            </div>
                        </div>

                        <div nz-col nzSpan="4"></div>

                        <!-- STATUS -->
                        <span *ngIf="show.statusId === 1" class="status-bulb status-bulb--small mb-3">{{
                            'STATUS.NON_SOUMIS' | translate }}</span>
                        <span *ngIf="show.statusId === 2" class="status-bulb status-bulb--small status-bulb--pending">
                            <i nz-icon aria-hidden="true" nzType="clock-circle" nzTheme="outline"></i>
                        </span>
                        <span *ngIf="show.statusId === 3" class="status-bulb status-bulb--small status-bulb--success">
                            <i nz-icon aria-hidden="true" nzType="check" nzTheme="outline"></i>
                        </span>
                        <span *ngIf="show.statusId === 4" class="status-bulb status-bulb--small status-bulb--errors">
                            <i nz-icon aria-hidden="true" nzType="close" nzTheme="outline"></i>
                        </span>
                        <span *ngIf="show.statusId === 5" class="status-bulb status-bulb--small">{{
                            'STATUS.EXPIRE' | translate }}</span>
                    </div>
                </sp-list-item>
            </sp-list>
        </div>

        <div nz-col nzSpan="24" class="pt-2 footer-link">
            <span *ngIf="activeRoute != 'myshows'">
                <a class="sidenav-container__content__links" [routerLink]="'/account/my-shows' | localize"
                    style="font-size: 13px !important">
                    {{ 'TOUS-MES-SPECTACLES' | translate }}
                </a>
            </span>
        </div>
    </section>
</div>