<div class="program-calendar-header">
    <!-- FILTER -->
    <div class="program-calendar-header__filterSearch">
        <ng-container *ngIf="!isListViewRow" class="program-calendar-header">
            <button class="btn btn--larger btn--edit btn--filter" nz-dropdown nzType="default" [nzDropdownMenu]="exportMenu" (nzVisibleChange)="isRotate = !isRotate">
                {{ 'FILTER' | translate }}
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" [ngClass]="isRotate ? '_svgRotate' : ''">
                    <path
                        fill-rule="nonzero"
                        fill="white"
                        d="M9.95 11.485l4.242-4.242a1 1 0 1 1 1.415 1.414l-4.95 4.95a1 1 0 0 1-1.414 0l-4.95-4.95a1 1 0 0 1 1.414-1.414l4.243 4.242z"
                    />
                </svg>
            </button>
            <nz-dropdown-menu #exportMenu="nzDropdownMenu">
                <sp-menu [classList]="['dropdown-menu', 'filter-calendar']">
                    <sp-menu-item *ngIf="tour" [classList]="['list-filters__box__title', 'filter-calendar-item', 'filter-calendar-item-title']">{{ tour | translatable: 'name' }}</sp-menu-item>
                    <sp-menu-item *ngIf="tour" [classList]="['menuItem', 'filter-calendar-item']">
                        <sp-checkbox [(ngModel)]="inTourFilterModel" (ngModelChange)="onInTourModelChange($event)">
                            {{ 'TOURS.DATES-HORS-TOURNEE' | translate }}
                        </sp-checkbox>
                    </sp-menu-item>
                    <sp-menu-item [classList]="['list-filters__box__title', 'filter-calendar-item', 'filter-calendar-item-title']" [label]="'TOURS_FILTER' | translate">
                    </sp-menu-item>
                    <ng-container *ngFor="let calendar of routeData.calendars; let index = index">
                        <sp-menu-item [classList]="['menuItem', 'filter-calendar-item']">
                            <sp-checkbox [(ngModel)]="calendarsFilterModel[index]" (ngModelChange)="onCalendarsModelChange()">
                                {{ calendar | translatable: 'title' }}
                            </sp-checkbox>

                        </sp-menu-item>
                    </ng-container>
                    <sp-divider></sp-divider>
                    <sp-menu-item [classList]="['list-filters__box__title', 'filter-calendar-item', 'filter-calendar-item-title']" [label]="'STADE-CONFIRMATION' | translate">
                    </sp-menu-item>
                    <ng-container *ngFor="let stat of statusArray; let index = index">
                        <sp-menu-item [classList]="['menuItem', 'filter-calendar-item']">
                            <sp-checkbox [(ngModel)]="statusFilterModel[index]" (ngModelChange)="onStatusModelChange()">
                                {{ 'PROGRAMME-STATUS-' + ProgramStatus[stat.value] | translate }}
                            </sp-checkbox>

                        </sp-menu-item>
                    </ng-container>
                </sp-menu>
            </nz-dropdown-menu>
        </ng-container>

        <a class="search-advanced-link" (click)="checkedCollapse = !checkedCollapse">
            <span class="mr-1">{{ 'RECHERCHE-AVANCEE' | translate }}</span>
            <svg [ngClass]="{ 'rotate-180': checkedCollapse, 'rotate-init': !checkedCollapse }" width="20" height="20" viewBox="0 0 20 20">
                <path fill-rule="nonzero" d="M9.95 11.485l4.242-4.242a1 1 0 1 1 1.415 1.414l-4.95 4.95a1 1 0 0 1-1.414 0l-4.95-4.95a1 1 0 0 1 1.414-1.414l4.243 4.242z" />
            </svg>
        </a>
    </div>

    <div class="program-calendar-header__dateSelector">
        <!-- PREVIOUS -->
        <button class="program-calendar-header__Btn previous" (click)="previousMonth()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path fill-rule="nonzero" d="M9.95 11.485l4.242-4.242a1 1 0 1 1 1.415 1.414l-4.95 4.95a1 1 0 0 1-1.414 0l-4.95-4.95a1 1 0 0 1 1.414-1.414l4.243 4.242z" />
            </svg>
        </button>

        <!-- MONTH SELECTOR -->
        <nz-select
            *ngIf="mode === 'month'"
            class="ant-fullcalendar-month-select"
            [nzDropdownMatchSelectWidth]="false"
            [ngModel]="activeMonth"
            (ngModelChange)="updateMonth($event)"
        >
            <nz-option *ngFor="let month of months" [nzLabel]="month.label" [nzValue]="month.value"></nz-option>
        </nz-select>
        <!-- YEAR SELECTOR -->
        <nz-select class="ant-fullcalendar-year-select" [nzDropdownMatchSelectWidth]="false" [ngModel]="activeYear" (ngModelChange)="updateYear($event)">
            <nz-option *ngFor="let year of years" [nzLabel]="year.label" [nzValue]="year.value"></nz-option>
        </nz-select>

        <!-- NEXT -->
        <button class="program-calendar-header__Btn next" (click)="nextMonth()">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path fill-rule="nonzero" d="M9.95 11.485l4.242-4.242a1 1 0 1 1 1.415 1.414l-4.95 4.95a1 1 0 0 1-1.414 0l-4.95-4.95a1 1 0 0 1 1.414-1.414l4.243 4.242z" />
            </svg>
        </button>
    </div>

    <!-- VIEW SWITCHER -->
    <div class="program-calendar-header__viewSwitcher">
        <!-- EXPORT CALENDAR START -->
        <div class="program-calendar-header__exportCalendarButton" *ngIf="showExport">
            <app-export-dropdown #exportDropdown [exportOptions]="exportOptions" (onExportSelected)="onExportCallback($event)"></app-export-dropdown>
        </div>

        <!-- EXPORT CALENDAR END -->
        <button *ngIf="showListButton" id="list-item-row" (click)="changeListView(true)" class="filter-button {{ isListViewRow ? 'active' : '' }} ">
            <i class="icon-list"></i>
        </button>
        <button id="list-item-card" (click)="changeListView(false)" class="filter-button  mr-2 btn--switchCalendar {{ !isListViewRow ? 'active' : '' }}">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <path
                    fill="#1C1C1B"
                    fill-rule="nonzero"
                    d="M5 3.5v2H4v10h12v-10h-1v-2h2a1 1 0 0 1 .993.883L18 4.5v12a1 1 0 0 1-.883.993L17 17.5H3a1 1 0 0 1-.993-.883L2 16.5v-12a1 1 0 0 1 .883-.993L3 3.5h2zm3 8v2H6v-2h2zm3 0v2H9v-2h2zm3 0v2h-2v-2h2zm-6-3v2H6v-2h2zm3 0v2H9v-2h2zm3 0v2h-2v-2h2zm-7-7a1 1 0 0 1 .993.883L8 2.5v4a1 1 0 0 1-1.993.117L6 6.5v-4a1 1 0 0 1 1-1zm6 0a1 1 0 0 1 .993.883L14 2.5v4a1 1 0 0 1-1.993.117L12 6.5v-4a1 1 0 0 1 1-1zm-2 2v2H9v-2h2z"
                />
            </svg>
        </button>
    </div>
</div>

<app-program-advanced-search *ngIf="checkedCollapse" (onSearchChange)="onSearchData($event)"></app-program-advanced-search>
