import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { getVitrineTypesList } from '../../../../../vitrine/enums/vitrine-type.enum';
import { ActivityVitrine, Vitrine } from '../../../../../vitrine/model/vitrine.model';
import { Activity } from '../../../../model/activity.model';

@Component({
    selector: 'app-activity-vitrines-form',
    templateUrl: './activity-vitrines-form.component.html',
    styleUrls: [ './activity-vitrines-form.component.scss' ]
})
export class ActivityVitrinesFormComponent implements OnInit {
    @Input() activity: Activity;
    @Input() selectedVitrine: ActivityVitrine;
    @Input() meetingVitrinesList: Vitrine[];

    @Output() cancel = new EventEmitter<any>();
    @Output() saveChanges = new EventEmitter<ActivityVitrine>();

    vitrineForm: UntypedFormGroup;
    vitrineTypes = getVitrineTypesList();
    lang = this.translate.currentLang;

    constructor(private fb: UntypedFormBuilder, public translate: TranslateService) { }

    ngOnInit(): void {
        this.initVitrineForm();
    }

    initVitrineForm(): void {
        this.vitrineForm = this.fb.group({
            vitrineId: new UntypedFormControl(!this.selectedVitrine ? undefined : this.selectedVitrine.vitrineId, [ Validators.required ]),
            vitrineTypeId: new UntypedFormControl(!this.selectedVitrine ? undefined : this.selectedVitrine.vitrineTypeId, [ Validators.required ]),
            duration: new UntypedFormControl(!this.selectedVitrine ? undefined : this.selectedVitrine.duration, [ Validators.required ])
        });
    }

    saveVitrine(): void {
        if (this.vitrineForm.valid) {
            const theVitrine: ActivityVitrine = new ActivityVitrine({
                id: this.selectedVitrine ? this.selectedVitrine.id : undefined,
                duration: parseInt(this.vitrineForm.get('duration').value),
                vitrineId: parseInt(this.vitrineForm.get('vitrineId').value),
                vitrineTypeId: parseInt(this.vitrineForm.get('vitrineTypeId').value)
            });

            this.saveChanges.emit(theVitrine);
        }
    }

    dismiss(): void {
        this.cancel.emit(this.selectedVitrine ? this.selectedVitrine.id : null);
    }

    getVitrineTypesLabel(option: any): string {
        return this.translate.instant(`VITRINE_TYPE.${option.label}`);
    }
}
