import { Routes } from '@angular/router';
import { ProMeetingComponent } from '../../pages/pro-meeting/pro-meeting.component';
import { MeetingModifyComponent } from './components/meeting-modify/meeting-modify.component';
import { MeetingSingleComponent } from './components/meeting-single/meeting-single.component';
import { MeetingInscriptionComponent } from './components/meeting-inscription/meeting-inscription.component';
import { SiteConcepts } from '../../shared/enums/site-concepts.enum';
import { DepotVitrineComponent } from '../vitrine/components/depot-vitrine/depot-vitrine.component';
import { VitrineApprovalComponent } from '../vitrine/components/vitrine-approval/vitrine-approval.component';

export const MeetingRoutes: Routes = [
      {
        path: '',
        component: MeetingSingleComponent,
        data: {
          breadcrumb: [SiteConcepts.MEETING, SiteConcepts.SINGLE],
        },
      },
      {
        path: 'modify',
        component: MeetingModifyComponent,
        data: {
          breadcrumb: [SiteConcepts.MEETING, SiteConcepts.SINGLE],
        },
      },
      {
        path: 'modify/:page',
        component: MeetingModifyComponent,
        data: {
          breadcrumb: [SiteConcepts.MEETING, SiteConcepts.SINGLE],
        },
      },
      {
        path: 'inscription',
        component: MeetingInscriptionComponent,
        data: {
          breadcrumb: [SiteConcepts.MEETING],
        },
      },
      {
        path: 'showcase/:showcaseId/show/:showId',
        component: VitrineApprovalComponent,
        data: {
          breadcrumb: [SiteConcepts.MEETING],
        },
      },
      {
        path: 'showcase',
        component: DepotVitrineComponent,
        data: {
          breadcrumb: [SiteConcepts.MEETING],
        },
      },
];
