<ng-container *ngIf="isReady">
    <app-page-title
        [title]="meeting.title"
        [subtitle]="meeting.subtitle"
        [image]="meeting.image"
        buttonText="{{ 'FORM.MODIFIER' | translate }}"
        [buttonLink]="editMeetingLink"
        [permission]="CAN_EDIT"
        [organizationId]="meeting.organizationId"
        [isWithImagePage]="true"
    ></app-page-title>

    <div class="container">
        <div nz-row *ngIf="meeting.isPublished !== 1" class="w-100" style="position: relative; display: block">
            <p class="meeting-notPublished">
                <i nz-icon aria-hidden="true" nzType="warning" nzTheme="outline"></i>
                <span class="ml-2">{{ 'RENCONTRE-NON-PUBLIE' | translate }}</span>
            </p>
        </div>

        <div nz-row class="mb-5" [nzGutter]="16">
            <div nz-col nzSpan="12" class="gutter-row">
                <div nz-row>
                    <div nz-col nzSpan="24">
                        <quill-editor
                            [modules]="{ toolbar: false }"
                            [readOnly]="true"
                            class="fiche-content"
                            [ngModel]="meeting.description ? meeting.description : ('AUCUNE-DONNEE' | translate)"
                        ></quill-editor>
                    </div>
                    <div nz-col nzSpan="24" class="d-flex">
                        <!-- ORGANISATEUR -->
                        <div nz-col nzSpan="8">
                            <p class="page-template-labels mb-1">{{ 'ORGANISE-PAR' | translate }}</p>
                            <ng-container *ngIf="organization">
                                <div class="d-flex meeting-desc__organizer">
                                    <div class="meeting-desc__organizer__img">
                                        <img class="" [src]="organization.logo ? organization.logo : defaultImg" alt="" />
                                    </div>
                                    <div class="meeting-desc__organizer__title">
                                        <a [routerLink]="organizationLink | localize">
                                            <span class="">{{ organization.getTranslatedProperty(currentLang, 'name') }}</span>
                                        </a>
                                    </div>
                                </div>
                            </ng-container>
                        </div>

                        <!-- LIEU -->
                        <div nz-row>
                            <div nz-col nzSpan="8" class="mr-3">
                                <p class="page-template-labels mb-1">{{ 'FORM.VILLE' | translate }}</p>
                                <p class="d-block">{{ meeting.place || '-' }}</p>
                            </div>

                            <!-- WEBSITE -->
                            <div *ngIf="meeting.website" nz-col nzSpan="8">
                                <p class="page-template-labels mb-1">{{ 'FORM.SITE-WEB' | translate }}</p>
                                <a class="btn-Global d-flex" href="{{ meeting.website | externalLink }}" target="_blank">
                                    <i id="iconGlobal" class="mr-2" nz-icon aria-hidden="true" nzType="global" nzTheme="outline"></i>
                                    {{ meeting.website | translate }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Actions box -->
            <div nz-col nzSpan="12" class="gutter-row right-side-interactions h-100p">
                <!-- BLOC DE GAUCHE: INSCRIPTIONS: ACTIVITÉ, STANDS -->
                <div class="inscriptionBox border-right" style="border-right: 1px solid #ecebe7">
                    <!-- DROPDOWN DES PARTICIPANTS INSCRIS -->
                    <ng-container *ngIf="currentOrgparticipants.length; else noParticipants">
                        <div nz-row>
                            <div nz-col nzSpan="3">
                                <ng-container [ngTemplateOutlet]="greenCheck"></ng-container>
                            </div>
                            <div nz-col nzSpan="17" class="mb-4">
                                <ng-container *ngIf="currentUserOrg; else noOrg">
                                    <strong>{{ currentUserOrg?.getTranslatedProperty(currentLang, 'name') }}</strong>
                                </ng-container>
                                <ng-template #noOrg>
                                    <strong>{{ currentUser?.firstName + ' ' + currentUser?.lastName }}</strong>
                                </ng-template>
                                <div class="inscriptionBox-dropdown">
                                    <button
                                        id="viewParticipants"
                                        class="p-0 h-auto btn btn-link justify-content-start mt-0 d-flex align-items-center gap-2"
                                        nz-dropdown
                                        [nzPlacement]="'bottomLeft'"
                                        [nzTrigger]="'click'"
                                        [nzDropdownMenu]="dropdownParticipantsMenu"
                                    >
                                        <span>
                                            <ng-container
                                                [ngTemplateOutlet]="currentOrgparticipants.length > 1 ? multipleParticipantsTpl : uniqueParticipantTpl"
                                                [ngTemplateOutletContext]="{ $implicit: currentOrgparticipants.length }"
                                            ></ng-container>
                                            <ng-template #multipleParticipantsTpl let-value>{{ value }}&nbsp;{{ 'INSCRIPTIONS' | translate | lowercase }}</ng-template>
                                            <ng-template #uniqueParticipantTpl let-value>{{ value }}&nbsp;{{ 'INSCRIPTION' | translate | lowercase }}</ng-template>
                                        </span>
                                        <i nz-icon nzType="down"></i>
                                    </button>
                                    <nz-dropdown-menu #dropdownParticipantsMenu="nzDropdownMenu">
                                        <sp-menu [classList]="['dropdown-menu', 'meeting-dropdown-menu']">
                                            <ng-container *ngFor="let participant of currentOrgparticipants">
                                                <sp-menu-item [classList]="['dropdown-menu__item', 'dropdown-menu__item-flex']">
                                                    <img
                                                        style="max-height: 40px;border-radius: 4px;"
                                                        class="dropdown__item-image"
                                                        src="{{ participant.user.avatar || './assets/images/placeholder-membre.svg' }}"
                                                        alt=""
                                                    />
                                                    <div class="dropdown__item-content">
                                                        <span class="dropdown__item-title d-block" style="color: rgba(0, 0, 0, .65);">
                                                            <strong>{{ participant.user.firstName + ' ' + participant.user.lastName }}</strong>
                                                        </span>
                                                        <span class="dropdown__item-subtitle d-block" style="color: rgba(0, 0, 0, .65);font-size: 14px;font-weight: 300;line-height: 22px;">{{ productListToString(participant.products) }}</span>
                                                    </div>
                                                </sp-menu-item>
                                            </ng-container>
                                        </sp-menu>
                                    </nz-dropdown-menu>
                                </div>
                            </div>
                        </div>

                        <ng-container *ngIf="isOpenDateSubscription">
                            <div nz-row>
                                <div nz-col nzSpan="24" class="mb-2">
                                    <a class="btn btn-primary" [routerLink]="['./inscription'] | localize">
                                        {{ 'AJOUTER-INSCRIPTION' | translate }}
                                    </a>
                                </div>
                            </div>
                        </ng-container>
                        <!-- CHOIX DE STAND -->
                        <ng-container *ngIf="canChooseStand">
                            <div nz-row>
                                <div nz-col nzSpan="24">
                                    <ng-container *ngIf="selectedStand; else chooseStandBtn">
                                        <div class="d-flex align-items-center stand-rect mb-2">
                                            <span>{{ selectedStand.label }}</span>
                                            <button type="button" class="btn-square d-flex ml-auto" (click)="chooseStand()">
                                                <i class="icon-pencil"></i>
                                            </button>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <ng-template #chooseStandBtn>
                                <button nz-button class="btn btn--default mb-2" (click)="chooseStand()">
                                    {{ 'CHOISIR-STAND' | translate }}
                                </button>
                            </ng-template>
                        </ng-container>

                        <!-- CHOIX D'ACTIVITÉS -->
                        <ng-container *ngIf="isOpenDateActivityChoice">
                            <div nz-row>
                                <div nz-col nzSpan="24">
                                    <button nz-button class="btn btn--default mb-2" (click)="chooseActivities()">
                                        {{ 'CHOISIR-ACTIVITES' | translate }}
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!isOpenDateActivityChoice && isActivityOptional">
                            <div nz-row>
                                <div nz-col nzSpan="24">
                                    <button nz-button class="btn btn--default mb-2" (click)="seeActivities()">
                                        {{ 'VOIR-ACTIVITES' | translate }}
                                    </button>
                                </div>
                            </div>
                        </ng-container>
                    </ng-container>
                    <!-- BOUTTON D'INSCRIPTION -->
                    <ng-template #noParticipants>
                        <ng-container *ngIf="hasMeetingProducts && (isCurrentOrganizationApproved$ | async) && isOpenDateSubscription">
                            <button nz-button class="btn btn-primary mb-3" [routerLink]="['./inscription'] | localize">
                                {{ 'PARTICIPER-AU-MEETING' | translate }}
                            </button>
                        </ng-container>
                        <span class="u-text--disabled">{{ 'PARTICIPE-PAS-MEETING' | translate }}</span>
                    </ng-template>
                </div>
                <!-- BLOC DE DROITE: DEPOTS DE VITRINE -->
                <!-- SEUL UN PRODUCTEUR PEUT AJOUTER UNE VITRINE -->
                <ng-container [ngTemplateOutlet]="vitrineContainerTpl" [ngTemplateOutletContext]="{ $implicit: selectVitrinesByOrganizationId$ | async }"></ng-container>
                <ng-template #vitrineContainerTpl let-list>
                    <div class="inscriptionBox border-left" style="border-left: 1px solid #ecebe7" *appPermission="CAN_EDIT; organizationType: PRODUCTEUR">
                        <ng-container *ngTemplateOutlet="!list?.length ? noVitrineTpl : vitrineTpl"></ng-container>
                        <ng-template #noVitrineTpl>
                            <ng-container
                                [ngTemplateOutlet]="isOpenDateDepotVitrine ? addVitrineButtonTpl : addVitrinePlaceholderTpl"
                                [ngTemplateOutletContext]="{ $implicit: 'PROPOSER-VITRINE' }"
                            ></ng-container>
                            <span *ngIf="isOpenDateDepotVitrine" class="u-text u-text--disabled">{{ 'PAS-PROPOSE-VITRINE' | translate }}</span>
                        </ng-template>
                        <ng-template #vitrineTpl>
                            <div nz-row class="mb-4">
                                <div nz-col nzSpan="3">
                                    <ng-container [ngTemplateOutlet]="greenCheck"></ng-container>
                                </div>
                                <div nz-col nzSpan="17">
                                    <strong>{{ currentUserOrg?.getTranslatedProperty(currentLang, 'name') }}</strong>
                                    <div class="inscriptionBox-dropdown">
                                        <button
                                            id="viewVitrines"
                                            class="p-0 h-auto btn btn-link justify-content-start mt-0 d-flex align-items-center gap-2"
                                            nz-dropdown
                                            nzTrigger="click"
                                            [nzDropdownMenu]="menu"
                                            [nzPlacement]="'bottomLeft'"
                                            [nzClickHide]="nzClickHide"
                                        >
                                            <span>{{ list.length }}&nbsp;{{ 'VITRINE-PROPOSEE' | translate : { plural: list.length > 1 ? 's' : '' } | lowercase }}</span>
                                            <i class="d-flex pl-1 icon-chevron-down"></i>
                                        </button>
                                        <nz-dropdown-menu #menu="nzDropdownMenu">
                                            <sp-menu [classList]="['dropdown-menu', 'meeting-dropdown-menu']">
                                                <ng-container *ngFor="let vitrine of list">
                                                    <sp-menu-item [classList]="['dropdown-menu__item', 'dropdown-menu__item-flex', 'meeting-dropdown__item']">
                                                        <img class="dropdown__item-image" src="{{ vitrine.itemImgSrc || './assets/images/placeholder-membre.svg' }}" alt="" />
                                                        <div class="dropdown__item-content">
                                                            <span class="dropdown__item-title">
                                                                <strong>{{ vitrine.itemTitle }}</strong>
                                                            </span>
                                                            <span class="dropdown__item-subtitle">{{ vitrine.itemSubtitle }}</span>
                                                        </div>
                                                    </sp-menu-item>
                                                </ng-container>
                                            </sp-menu>
                                        </nz-dropdown-menu>
                                    </div>
                                </div>
                            </div>
                            <ng-container
                                [ngTemplateOutlet]="isOpenDateDepotVitrine ? addVitrineButtonTpl : addVitrinePlaceholderTpl"
                                [ngTemplateOutletContext]="{ $implicit: 'AJOUTER-VITRINE-ONLY' }"
                            ></ng-container>
                        </ng-template>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>

    <div class="container-fluid bg-gray py-5">
        <div class="container">
            <!-- FILTERS -->
            <div nz-row class="mb-5 d-flex align-items-center justify-content-center" nzGutter="16">
                <div nz-col class="d-flex align-items-center justify-content-center container__listSize">
                    <nz-radio-group class="d-inline-flex" [(ngModel)]="activeTab">
                        <label class="fiche-filter__radio d-inline-block" nz-radio-button nzValue="TAB_ACTIVITIES" (click)="toggleMeetingContent('TAB_ACTIVITIES')">
                            {{ 'ACTIVITIES' | translate }}
                            <span class="ml-2">
                                {{ activityListSize }}
                            </span>
                        </label>

                        <label class="fiche-filter__radio d-inline-block" nz-radio-button nzValue="TAB_VITRINES" (click)="toggleMeetingContent('TAB_VITRINES')">
                            {{ 'VITRINES' | translate }}
                            <span class="ml-2">
                                {{ vitrineListSize }}
                            </span>
                        </label>

                        <label class="fiche-filter__radio" nz-radio-button nzValue="TAB_STANDS" (click)="toggleMeetingContent('TAB_STANDS')">
                            {{ 'STANDS' | translate }}
                            <span class="ml-2">
                                {{ standListSize }}
                            </span>
                        </label>
                    </nz-radio-group>
                </div>
            </div>

            <!-- TABS CONTENT -->
            <div nz-row *ngIf="meetingReady" nzGutter="16" class="mb-5 d-flex align-items-center justify-content-center">
                <div nz-col class="w-100">
                    <div nz-row class="{{ activeTab == 'TAB_ACTIVITIES' ? 'd-block' : 'd-none' }}">
                        <app-activity-list
                            [meetingId]="meetingId"
                            [enableFilters]="true"
                            [enabledDefaultfilters]="true"
                            (activityListSize)="activityListSize = $event"
                        ></app-activity-list>
                    </div>
                    <div nz-row class="{{ activeTab == 'TAB_VITRINES' ? 'd-block' : 'd-none' }}">
                        <div nz-col [nzOffset]="6">
                            <app-vitrine-list
                                [meetingId]="meetingId"
                                [enableFilters]="isJuryMember"
                                [useViewSwitcher]="true"
                                (vitrineListSize)="vitrineListSize = $event"
                            ></app-vitrine-list>
                        </div>
                    </div>
                    <div nz-row [nzGutter]="24" class="{{ activeTab == 'TAB_STANDS' ? 'd-flex' : 'd-none' }} row">
                        <div nz-col [nzMd]="12" class="col">
                            <img class="w-100" src="{{ meeting.standImageUrl ? meeting.standImageUrl : defaultImg }}" alt="" />
                        </div>
                        <div nz-col [nzMd]="12" class="col">
                            <app-stand-list [meetingId]="meetingId" (standListSize)="standListSize = $event"></app-stand-list>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>

<ng-template #greenCheck>
    <span class="checkmark-container">
        <svg viewBox="64 64 896 896" fill="currentColor" width="2em" height="2em" data-icon="check-circle-green" aria-hidden="true">
            <path
                class="checkmark"
                d="M699 353h-46.9c-10.2 0-19.9 4.9-25.9 13.3L469 584.3l-71.2-98.8c-6-8.3-15.6-13.3-25.9-13.3H325c-6.5 0-10.3 7.4-6.5 12.7l124.6 172.8a31.8 31.8 0 0051.7 0l210.6-292c3.9-5.3.1-12.7-6.4-12.7z"
            ></path>
        </svg>
    </span>
</ng-template>
<ng-template #addVitrineButtonTpl let-label>
    <button nz-button class="btn btn-primary mb-3" [routerLink]="['./showcase'] | localize">
        {{ label | translate }}
    </button>
</ng-template>
<ng-template #addVitrinePlaceholderTpl>
    <span class="u-text u-text--disabled">{{ 'NO-ADD-VITRINE' | translate }}</span>
</ng-template>
