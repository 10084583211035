import { Component, OnDestroy, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import { forkJoin, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ShoppingCartService } from 'src/app/concepts/account/services/shopping-cart.service';
import { Meeting } from 'src/app/concepts/meeting/model/meeting.model';
import { MeetingService } from 'src/app/concepts/meeting/services/meeting.service';
import { ProductTypes } from 'src/app/concepts/product/enums/product-types-enum';
import { Product } from 'src/app/concepts/product/model/product.model';
import { ShowStatus } from 'src/app/concepts/show/enums/show-status.enum';
import { Show } from 'src/app/concepts/show/model/show.model';
import { FormItemSelectService } from 'src/app/shared/services/form.row.select.service';
import { RideauNotificationService } from 'src/app/shared/services/rideau-notification.service';
import { Globals } from '../../../../_configs/globals';
import { Vitrine } from '../../model/vitrine.model';
import { BreadcrumbItem } from './../../../../shared/model/breadcrumb.model';
import { ItemRowFile, UploadedItemFile } from '../../../../shared/model/item-row-file.model';
import { BreadcrumbService } from './../../../../shared/services/breadcrumb.services';
import { AccountService } from './../../../account/services/account.service';
import { AuthService } from './../../../account/services/auth/auth.service';
import { ProductService } from './../../../product/services/product.service';
import { ShowService } from './../../../show/services/show.service';
import { VitrineService } from './../../services/vitrine.service';
import { ItemUploadRepeaterService } from 'src/app/shared/components/item-upload-repeater/item-upload-repeater.service';
import { ContentType, getAdditionalMaterialTypeById, getVitrineAdditionalMaterialTypeBaseList } from '../../enums/vitrine-additional-material-type.enum';
import { CartProductRequest } from 'src/app/domain/entities/cart-product.model';
@Component({
    selector: 'app-depot-vitrine',
    templateUrl: './depot-vitrine.component.html',
    styleUrls: ['./depot-vitrine.component.scss']
})
export class DepotVitrineComponent implements OnInit, OnDestroy {
    // disabled (boolean): indicates if a button or form is disabled or not
    disabled = false;

    // isLoading (boolean): indicates if the component is currently loading data
    isLoading = false;

    // lang (string): current language of the user
    lang = this.translate.currentLang;

    // meetingId (number): ID of the current meeting
    meetingId: number;

    // meeting (Meeting): represents a meeting object
    meeting: Meeting;

    // shows (Show[]): an array of Show objects
    shows: Show[];

    // products (Product[]): an array of Product objects
    products: Product[];

    // vitrine (Vitrine): represents a Vitrine object
    vitrine: Vitrine = new Vitrine({});

    // selectedShow (Show): currently selected Show object
    selectedShow: Show;

    // selectedProduct (Product): currently selected Product object
    selectedProduct: Product;

    // reloading (boolean): indicates if the component is currently reloading data
    reloading = false;

    // cartProduct (CartProductRequest): an object representing a product in the user's cart
    cartProduct: CartProductRequest;

    // displayFullForm (boolean): indicates if the full form should be displayed
    displayFullForm = false;

    // rideauForm (FormGroup): represents the form for creating a new Rideau object
    rideauForm: UntypedFormGroup;

    // materielSupList (ItemRowFile[]): an array of uploaded files for the current Vitrine object
    additionalMaterialList: ItemRowFile[] = [];

    // vitrineFilesName (string): the name of the uploaded files for the current Vitrine object
    readonly vitrineFilesName = 'vitrineFiles';

    // presentationFileEndpoint (string): the endpoint for uploading presentation files
    readonly presentationFileEndpoint = this.globals.api.ENDPOINT + 'upload/vitrine/files';

    // vitrineAdditionalMaterialTypeLength (number): the number of additional material types available for the current Vitrine object
    private readonly vitrineAdditionalMaterialTypeLength: number = getVitrineAdditionalMaterialTypeBaseList().length;

    // formatter (Intl.NumberFormat): formats numbers as currency in the user's locale
    formatter = new Intl.NumberFormat('fr-CA', {
        style: 'currency',
        currency: 'CAD',
       // currencyDisplay: 'symbol',
        minimumFractionDigits: 2
    });

    constructor(
        private router: Router,
        private fb: UntypedFormBuilder,
        private globals: Globals,
        private route: ActivatedRoute,
        private authService: AuthService,
        private showService: ShowService,
        private translate: TranslateService,
        private meetingService: MeetingService,
        private productService: ProductService,
        private accountService: AccountService,
        private vitrineService: VitrineService,
        private breadcrumbService: BreadcrumbService,
        @Inject(LocalizeRouterService) private localizeRouter: LocalizeRouterService,
        private notification: RideauNotificationService,
        private shoppingCartService: ShoppingCartService,
        private formItemSelectService: FormItemSelectService,
        private itemUploadRepeaterService: ItemUploadRepeaterService
    ) {}

    /**
     * @summary Initializes component properties and fetches data from API to populate the view
     * @return {void} This function does not return anything
     */
    ngOnInit(): void {
        this.meetingId = this.route.snapshot.params['rencontreId'];
        this.isLoading = true;
        forkJoin([this.getMeeting(), this.getVitrineProductsForMeeting(), this.getShowForUser()])
            .pipe(finalize(() => (this.isLoading = false)))
            .subscribe();
        // set initial file types with the complete list since the is creating a new Vitrine product
        this.updateInitialFileTypeFromMaterialList();
        this.updateAddButtonDisabledState();
    }

    /**
     * @summary Updates the disabled state of the "Add" button based on the number of uploaded files
     * @return None
     */
    private updateAddButtonDisabledState = (): void => {
        this.itemUploadRepeaterService.uploadedFiles$.subscribe((files: ItemRowFile[]) => {
            this.additionalMaterialList = [...files];
            const shouldDisableButton = this.additionalMaterialList.length === this.vitrineAdditionalMaterialTypeLength;
            this.itemUploadRepeaterService.setDisableButtonFromUploadFiles(shouldDisableButton);
        });
    };

    /**
     * @summary Retrieves a meeting by ID and sets up the component with relevant data based on the meeting's organization ID.
     * @return {Observable<Meeting>} An observable that emits the retrieved meeting object.
     */
    private getMeeting(): Observable<Meeting> {
        return this.meetingService.getMeetingById(this.meetingId).do((res) => {
            this.meeting = res;
            this.meeting.toSingleMeeting(this.lang);

            this.displayFullForm = this.meeting.organizationId === this.globals.RIDEAU_ORGID || this.meeting.organizationId === this.globals.ROSEQ_ORGID || this.meeting.organizationId === this.globals.QUEBECOFOLIES_ORGID;
            if (this.displayFullForm) {
                this.rideauForm = this.vitrine.getRideauForm(this.fb);
            }

            // Set Custom Breadcrumb item list
            const breadcrumbItems: BreadcrumbItem[] = [];
            const meetingName = this.meeting.getTranslatedProperty(this.lang, 'name');
            const meetingUrl = '/pro-meeting/' + this.meetingId;
            breadcrumbItems.push(new BreadcrumbItem({ title: meetingName, url: meetingUrl }));
            breadcrumbItems.push(new BreadcrumbItem({ title: 'VITRINE' }));

            this.breadcrumbService.addBreadcrumbCascade(breadcrumbItems, true);
        });
    }

    /**
     * @summary Retrieves vitrine products for the current meeting, based on specified filters, including the meeting ID, the product type, and a check for valid date products
     * @return {Observable<Product[]>} An Observable containing an array of products that meet the specified filters
     */
    private getVitrineProductsForMeeting(): Observable<Product[]> {
        const meetingFilters = [
            {
                field: 'meetingId',
                value: this.meetingId
            },
            {
                field: 'productTypeId',
                value: ProductTypes.DEPOT_VITRINE
            },
            {
                field: 'checkValidDateProduct',
                value: 1
            }
        ];

        return this.productService.getProducts(meetingFilters).do((res) => (this.products = res));
    }

    /**
     * @summary Retrieves shows for the user based on specified filters, including the organization ID, the show status, and the payment status
     * @return {Observable<Show[]>} An Observable containing an array of shows that meet the specified filters
     */
    private getShowForUser(): Observable<Show[]> {
        const showFilters = [
            {
                field: 'statusId',
                value: ShowStatus.APPROUVE
            },
            {
                field: 'isPaid',
                value: 1
            },
            {
                field: 'organizationId',
                value: this.accountService.getCurrentCtxOrganizationId()
            }
        ];

        return this.showService.getShows(showFilters).do((res) => (this.shows = res));
    }

    /**
     * @summary Saves the vitrine by creating a new Vitrine object, and setting its properties based on the selected product, show, meeting, and organization
     * @return {Observable<any>} An Observable containing the result of the operation, and setting the ID of the vitrine
     */
    private saveVitrine(): Observable<any> {
        if (!this.selectedProduct) {
            this.notification.error(this.translate.instant('FORM.MISSING-SELECTED-PRODUIT'));
            return;
        }
        this.vitrine = new Vitrine({
            showId: this.selectedShow.id,
            meetingId: this.meeting.id,
            productId: this.selectedProduct.id,
            organizationId: this.accountService.getCurrentCtxOrganizationId()
        });

        if (this.displayFullForm) {
            this.vitrine.isExpress = this.rideauForm.get('isExpress').value ? 1 : 0;
            this.vitrine.isYoungAudience = this.rideauForm.get('isYoungAudience').value ? 1 : 0;
            this.vitrine.demoFile = this.rideauForm.get('demoFile').value;
            this.vitrine.mediaUrl = this.rideauForm.get('mediaUrl').value;
            this.vitrine.mediaPassword = this.rideauForm.get('mediaPassword').value;
            this.vitrine.additionalUrl = this.rideauForm.get('additionalUrl').value;
            this.vitrine.additionalMediaPassword = this.rideauForm.get('additionalMediaPassword').value;

            this.vitrine.media = this.additionalMaterialList
                .map(
                    (item: ItemRowFile): UploadedItemFile => ({
                        url: item.file,
                        label: item.desc,
                        contentTypeId: item.file.includes(this.vitrineFilesName) ? ContentType.VITRINE : +item.id
                    })
                )
                .filter((item: UploadedItemFile) => item.url !== '');
        }

        return this.vitrineService.createVitrine(this.vitrine).do((res) => {
            this.vitrine.id = res['id'];
        });
    }

    /**
     * @summary Resets the form by clearing the selected show and product, resetting the rideauForm and the materielSupList
     * @return {void}
     */
    private resetForm(): void {
        this.selectedShow = null;
        this.selectedProduct = null;
        if (this.rideauForm) {
            this.reloading = true;
            this.rideauForm.reset();
            this.additionalMaterialList = [];

            setTimeout(() => {
                this.reloading = false;
                this.resetAdditionalMaterialSelect(true);
            }, 500);
        }
    }

    /**
     * @summary Adds the selected product to the shopping cart
     * @param {number} vitrineId - The ID of the vitrine where the product will be added
     * @return {Observable<any>} An Observable containing the result of the operation, and showing a success message on the notification bar
     */
    private addToCart(vitrineId: number): Observable<any> {
        if (!this.selectedProduct) {
            this.notification.error(this.translate.instant('FORM.MISSING-SELECTED-PRODUIT'));
            return;
        }
        this.cartProduct = {
            productTypeId: ProductTypes.DEPOT_VITRINE,
            productId: this.selectedProduct.id,
            organizationId: this.accountService.getCurrentCtxOrganizationId(),
            userId: parseInt(this.authService.User.id),
            quantity: 1,
            itemId: vitrineId
        };
        return this.shoppingCartService.addToCart(this.cartProduct).do(() => {
            this.notification.success(this.translate.instant('FORM.AJOUTE-AU-PANIER'));
        });
    }

    /**
     * @summary Sends the current vitrine to the cart,
     ** by adding its id to the cart's list of items, and resetting the form
     ** and item upload repeater service if the operation succeeds.
     * @return {void} This function does not return anything.
     */
    public sendToCart(): void {
        const savedVitrine = this.saveVitrine();
        if (!savedVitrine) {
            return;
        }
        savedVitrine
            .flatMap((vitrine: Vitrine) => this.addToCart(vitrine.id))
            .subscribe(() => {
                this.resetForm();
            });
    }

    /**
     * @summary Marks the current vitrine as purchased, by adding its id to the
     ** cart's list of purchased items and navigating to the shopping,
     ** if the operation succeeds.
     * @param none
     * @return {void} This function does not return anything.
     */
    payNow(): void {
        this.disabled = true;
        const savedVitrine = this.saveVitrine();
        if (!savedVitrine) {
            return;
        }
        savedVitrine
            .flatMap((vitrine: Vitrine) => this.addToCart(vitrine.id))
            .subscribe(() => {
                const url = this.localizeRouter.translateRoute(`/shopping-cart`);
                this.router.navigate([url]);
            });
    }

    /**
     * @summary Navigates to the previous route
     * @return {void}
     */
    cancel(): void {
        this.router.navigate(['../'], { relativeTo: this.route });
    }

    /**
     * @summary Calculates and returns the price of a given product, based on its tariffs, gratuities, or single price.
     * If the product is free, returns 0.
     * @param {Product} product - the product to calculate the price for.
     * @return {number} - the calculated price of the product.
     */
    public getPrice(product: Product): number {
        if (!product || (product && product.isFree)) {
            return 0;
        }
        if (product.gratuities && product.gratuities.length) {
            return !product.gratuities[0].price ? 0 : product.gratuities[0].price;
        }
        if (product.tariffs && product.tariffs.length) {
            return !product.tariffs[0].price ? 0 : product.tariffs[0].price;
        }
        if (product.singlePrice) {
            return Number(product.singlePrice);
        }
        return 0;
    }
    /**
     * @summary Resets the form item select service and the item upload repeater service when the component is destroyed
     * @return {void} This function does not return anything
     */
    ngOnDestroy(): void {
        this.resetAdditionalMaterialSelect(true);
    }

    /**
     * @summary Gets the additional material type by ID and returns its translated string value
     * @param {string} id - The ID of the additional material type
     * @return {string} The translated string value of the additional material type, or the ID if it cannot be translated
     */
    public getAdditionalMaterialType(id: string): string {
        if (!isNaN(Number(id))) {
            return this.translate.instant('VITRINE_ADDITIONAL_MATERIAL_TYPE.' + getAdditionalMaterialTypeById(Number(id)));
        }

        return id;
    }

    /**
     * @summary Updates the initial file type options for the file uploader based on the available additional material types for the Vitrine product
     * @return {void} This function does not return a value
     */
    private updateInitialFileTypeFromMaterialList = (): void => {
        const payload = {
            list: this.additionalMaterialList || [],
            fileTypesLength: this.vitrineAdditionalMaterialTypeLength,
            options: {
                param: this.vitrineFilesName,
                action: this.presentationFileEndpoint
            }
        };
        this.itemUploadRepeaterService.setFileTypesFromMaterialList(payload);
    };

    /**
     * @summary Resets the additional material select
     * @param {boolean} resetInitialTypes - a boolean indicating whether to reset the initial types
     * @return {void} This function does not return anything
     */
    private resetAdditionalMaterialSelect = (resetInitialTypes = true): void => {
        this.formItemSelectService.reset();
        this.itemUploadRepeaterService.reset({ resetInitialTypes });
        this.updateInitialFileTypeFromMaterialList();
    };
}
