import { Component, OnInit } from '@angular/core';
import { Show } from '../../../show/model/show.model';
import { Venue } from '../../../venue/model/venue.model';
import { Calendar } from '../../model/calendar.model';
import { Organization } from '../../../organization/model/organization.model';
import { IProgramCreatedResponse, Program } from '../../model/program.model';
import { Tour } from '../../../tours/model/tour.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Notification } from '../program-single/notifications/notifications.component';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { VenueService } from '../../../venue/services/venue.service';
import { AccountService } from '../../../account/services/account.service';
import { TranslateService } from '@ngx-translate/core';
import { ProgramService } from '../../services/program.services';
import { RideauNotificationService } from '../../../../shared/services/rideau-notification.service';
import { OrganizationService } from '../../../organization/services/organization.service';
import { ToursService } from '../../../tours/services/tours.service';
import { ProgramStatus } from '../../../program/model/program-status.model';
import { RowTypes } from 'src/app/shared/enums/row-types.enum';
import { getTimestampFromDate } from '@app/shared/utils/time-utils';
import { map, tap, shareReplay, filter, finalize } from 'rxjs/operators';
import { TourDiffuseur } from '@app/concepts/tours/model/tour-diffuseur.model';
import { TourShow } from '@app/concepts/tours/model/tour-show.model';

@Component({
    selector: 'app-program-admin',
    templateUrl: './program-admin.component.html',
    styleUrls: ['./program-admin.component.scss']
})
export class ProgramAdminComponent implements OnInit {
    ProgramStatus = ProgramStatus;
    RowTypes = RowTypes;
    loading: boolean;
    program: Program;
    tour: Tour;
    venues$: Observable<Venue[]> = new Observable();
    tour$: Observable<Tour> = new Observable();
    diffusers$: Observable<Organization[]>;
    calendars$: Observable<Calendar[]> = this.programService.getCalendars().pipe(map((calendars: Calendar[]) => calendars.filter((x) => x.id !== 1)));
    shows$: Observable<Show[]>;
    programForm: UntypedFormGroup;
    selectedVenue: Venue;
    notification: Notification;
    isDateUpdatePending = false;
    isHourUpdatePending = false;
    isStatusUpdatePending = false;
    isVenueIdUpdatePending = false;
    isAdminTour = false;
    isDiffuser: boolean;
    isProducer: boolean;
    isDisabled = false;

    isCustomVenueEnable = false;
    isCustomShowEnable = false;
    subscription = new Subscription();

    private readonly destroy$ = new Subject<void>();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private venueService: VenueService,
        private accountService: AccountService,
        public translate: TranslateService,
        private programService: ProgramService,
        private notificationService: RideauNotificationService,
        private readonly organizationService: OrganizationService,
        private toursService: ToursService,
    ) {
        this.programForm = new UntypedFormGroup({
            date: new UntypedFormControl('', [Validators.required]),
            note: new UntypedFormControl(''),
            showId: new UntypedFormControl('', [Validators.required]),
            diffuserId: new UntypedFormControl('', [Validators.required]),
            statusId: new UntypedFormControl(ProgramStatus.Confirmed, [Validators.required]),
            venueId: new UntypedFormControl(''),
            calendarId: new UntypedFormControl(''),
            hour: new UntypedFormControl(''),
            tourId: new UntypedFormControl(undefined)
        });
    }

    ngOnInit(): void {
        this.loadData();
        this.diffusers$ = this.tour$.pipe(map((tour: Tour) => tour.diffusers.map((x: TourDiffuseur) => new Organization(x.organization))));
        this.diffuserOnChange();
    }

    doSubmit() {
        if (this.programForm.valid) {
            this.loading = true;
            if (this.programForm.value.date) {
                this.programForm.patchValue({
                    date: new Date(this.programForm.value.date.setHours(0, 0, 0, 0))
                });
            }
            const program: Program = new Program({
                ...this.program,
                ...this.programForm.value,
                allCalendars: [this.programForm.value.calendarId]
            });
            program.organizationId = this.programForm.value.diffuserId;
            this.organizationService.getOrganization(this.programForm.value.diffuserId).subscribe((org) => (program.organization = org));

            program.userId = this.accountService.getUserId();
            if (this.programForm.value.calendarId) {
                program.allCalendars.push(this.programForm.value.calendarId);
            }

            if (this.programForm.value.diffuserId) {
                program.venueCustom = null;
            }
            if (!this.programForm.value.venueId) {
                program.venueId = null;
            }
            if (this.programForm.value.showId) {
                program.showCustom = null;
            }
            if (this.programForm.value.showCustom) {
                program.showId = null;
            }

            if (program.hour) {
                this.setProgramHour(program);
            }
            // https://logient.atlassian.net/browse/RIDEAUM-629
            this.programService
                .createProgram(program)
                .pipe(
                    tap(({ isCreated }: IProgramCreatedResponse) => {
                        if (!isCreated) {
                            this.notificationService.error(this.translate.instant('PROGRAMME-NOTIFICATIONS.MESSAGE-ERROR-ADDED-TOUR'));
                        } else {
                            this.notificationService.success(this.translate.instant('PROGRAMME-NOTIFICATIONS.MESSAGE-SUCCESS-ADDED-TOUR'));
                        }
                    }),
                    filter(({ isCreated }: IProgramCreatedResponse) => !!isCreated),
                    map(({ program }: IProgramCreatedResponse) => {
                        this.redirectToCalendar(program.tourId);
                    }),
                    finalize(() => (this.loading = false))
                )
                .subscribe();
        }
    }

    public onDateChange($event: any): void {
        this.loading = false;
    }

    public showsOnChange($event: any): void {
        this.loading = false;
    }

    public venuesOnChange($event: any): void {
        this.loading = false;
    }

    private loadData() {
        this.tour$ = this.toursService.getTourById(this.route.snapshot.params.tourId).pipe(
            tap((tour: Tour) => {
                this.programForm.patchValue({
                    date: tour.startDate,
                    hour: tour.startDate,
                    tourId: tour.id,
                    calendarId: tour.calendarId,
                    allCalendars: [tour.calendarId]
                });
            }),
            tap((tour: Tour) => this.getShowByTourId(tour.id)),
            shareReplay(1)
        );
    }

    private getShowByTourId = (tourId: number): void => {
        this.shows$ = this.toursService
            .getTourShowsByTourId(tourId, this.accountService.getCurrentCtxOrganizationId(), 3)
            .pipe(map((tourShows: TourShow[]) => tourShows.map((x) => new Show(x.show))));
    };

    private diffuserOnChange(): void {
        this.programForm
            .get('diffuserId')
            .valueChanges.pipe(
                tap(() => (this.loading = false)),
                map((id: number) => {
                    if (!id) {
                        this.venues$ = of([]);
                        return;
                    }
                    this.venues$ = this.venueService.getVenues(id);
                })
            )
            .subscribe();
    }

    private redirectToCalendar = (tourId: number): void => {
        // https://logient.atlassian.net/browse/RIDEAU-1819
        const programRouteTrans = `${this.translate.instant('ROUTES.program')}/`;
        const tourRouteTrans = tourId ? `${this.translate.instant('ROUTES.tour')}/${tourId}` : '';
        const tourProgramLink = `/${this.translate.currentLang}/${programRouteTrans}${tourRouteTrans}`;
        // /fr/programmations/tournée/68
        this.router.navigate([tourProgramLink], {
            queryParams: {
                date: getTimestampFromDate(this.programForm.get('date').value)
            },
            queryParamsHandling: 'merge'
        });
    };

    private setProgramHour(program: Program) {
        program.hour = new Date(new Date(program.hour).setFullYear(program.date.getFullYear(), program.date.getMonth(), program.date.getDate()));
    }
}
